@import "ui/styles/2-tools/tools.typography-mixins.scss";
@import "ui/styles/2-tools/tools.behavior.scss";

// DownloadBar
.DownloadBar{
	color: var(--color-primary);
	background-color: var(--color-tertiary-lighter);
	width: 100%;
	margin-bottom: 1rem;	
}

.DownloadBar_wrapper{
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;	
	padding: var(--spacing--xl);
	@include transition {
		transition: flex-direction var(--trans-time--standard) ease;
	}	
	@include mq("md") {
		flex-direction: row;
		max-width: 80%;
		justify-content: space-between;
		margin: auto;
	}
}
.DownloadBar_icon{
	color: transparent;
	margin-left: var(--spacing--sm);
	min-width: 2rem;
}
.DownloadBar_link{	
	display: flex;
	font-size: var(--font-size--sm);
	@include mq("md") {
		margin-left: var(--spacing--lg);
	}
}
.DownloadBar_text{
	font-size: var(--font-size--lg);
	text-align: center;
	margin-bottom: var(--spacing--sm);
	@include mq("md") {
		font-size: var(--font-size--2xl);
		margin-bottom: 0;
	}
}
