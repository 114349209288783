@import "ui/styles/2-tools/tools.display-mixins";

.SoMeLink {
	display: flex;
	align-items: center;
	text-decoration: none;

	&___primary {
		color: var(--some-primary);
		
		circle {
			fill: var(--some-primary);
			stroke: none;
			}

		path {
			fill: var(--some-secondary);
		}

		.SoMeLink_text {
			color: var(--some-text-color);
		}
	}

	&___secondary {
		color: var(--some-secondary);

		circle {
		fill: transparent;
    	stroke: var(--some-secondary);
		}

		path {
			fill: var(--some-secondary);
		}

		.SoMeLink_text {
			color: currentColor;
		}
	}

	&:hover .SoMeLink_text {
		border-bottom: 1px solid currentColor;
	}
}

.SoMeLink_icon {
	margin: var(--spacing--sm) 0;
}

.SoMeLink_text {
	margin-left: var(--spacing--md);
	font-weight: var(--font-weight--light);
	color: var(--some-text-color);
	border-bottom: 1px solid transparent;

	&___sm {
		font-size: var(--font-size--sm);
	}

	&___md {
		font-size: var(--font-size--md);
	}

	&___lg {
		font-size: var(--font-size--lg);
	}

	&___xl {
		font-size: var(--font-size--xl);
	}

	&___2xl {
		font-size: var(--font-size--2xl);
	}
}
