@import "ui/styles/2-tools/tools.typography-mixins.scss";
@import "ui/styles/2-tools/tools.behavior.scss";

// FilterContacts
.FilterContacts {
	display: flex;
	justify-content: space-between;
	flex-direction: column-reverse;
	width: 100%;
	z-index: 1;

	@include mq("md") {
		flex-direction: row;
	}
}

.FilterContacts_fieldset {
	border: 0;
	border-bottom: var(--border--sm) solid var(--color-tertiary);
	padding-bottom: 2rem;
	margin-bottom: 2rem;
	animation: fadeIn 1s;
	&:first-of-type {
		border: 0;
	}
	@include mq("md") {
		border-bottom: 0;
		margin-left: 1rem;
		width: 50%;
	}
}
@keyframes fadeIn {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}
.FilterContacts_legend {
	font-size: var(--font-size--xl);
	font-weight: var(--font-weight--bold);
	margin-bottom: var(--spacing--xs);
}
.FilterContacts_checkbox {
	color: var(--color-primary);
	font-weight: var(--font-weight--semibold);
	margin-bottom: 1rem;

	@include mq("md") {
		margin-bottom: 0.5rem;
	}
	&:last-of-type {
		margin-bottom: 0;
	}
}
