@import "ui/styles/2-tools/tools.typography-mixins.scss";
@import "ui/styles/2-tools/tools.behavior.scss";

.FormfieldSelect {
	position: relative;
	display: flex;
	flex-flow: column;

	&.hasError {
		color: var(--color-error);
	}

	&.isDisabled {
		color: var(--color-gradient--5);
	}
}
.FormfieldSelect_label {
	@include formfield-label;	
}

.FormfieldSelect_wrapper {
	position: relative;
	margin: var(--spacing--2xs) 0;
	@include paragraph;

	&::placeholder {
		color: currentColor;
	}

	&:after {
		content: "";
		display: block;
		position: absolute;
		right: 0;
		top: 0;	
		border-left: var(--border--md) solid var(--color-light);
		background: transparent;
		pointer-events: none;
	}

	&:before {
		content: "";
		display: block;
		position: absolute;
		right: var(--spacing--base);
		top: 50%;
		height: 1rem;
		width: 1rem;
		border: solid var(--color-primary);
		border-width: 0 0 var(--border--md) var(--border--md);
		background: transparent;
		pointer-events: none;
		transform: translateY(-66.7%) rotate(-45deg);
	}

	.hasError & {
		color: var(--color-error);

		.FormfieldSelect_element {
			border-color: var(--color-error);
		}
	}

	&.isMultiple {
		&:after,
		&:before {
			display: none;
		}

		.FormfieldSelect_element {
			padding-right: var(--spacing--base);
		}
	}
}

.FormfieldSelect_element {
	@include paragraph();
	font-weight: var(--font-weight--bold);
	color: var(--color-primary);
	width: 100%;
	padding: var(--spacing--sm) var(--spacing--2xl) var(--spacing--sm) var(--spacing--base);
	border-radius: var(--corner-size--base);
	border: var(--border--sm) solid var(--color-primary);
	background: var(--color-light);
	appearance: none;
	cursor: pointer;
	outline: none;
	@include transition {
		transition: border-color var(--trans-time--standard) ease;
	}

	.isDisabled & {
		cursor: not-allowed;
	}

	&::-ms-expand {
		display: none;
	}

	&::placeholder {
		color: currentColor;
	}	
}
.FormfieldSelect_label:focus,
.FormfieldSelect_label:hover {
	cursor: auto;	
	& ~ .FormfieldSelect_wrapper .FormfieldSelect_element {
		border-color: transparent;
	}
}

.FormfieldSelect_element:focus,
.FormfieldSelect_element:not([disabled]):hover {
	border-color: var(--color-tertiary);
}
