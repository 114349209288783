@import "ui/styles/2-tools/tools.media-query.scss";
@import "ui/styles/2-tools/tools.display-mixins.scss";
@import "ui/styles/2-tools/tools.typography-mixins.scss";

.Countdown{		
    margin: 0 var(--grid-gutter);
	padding: var(--spacing--lg);	
	border: 1px solid var(--color-light);
	border-radius: 0 0 var(--corner-size--md) 0;	
	@include mq("md") {
		max-width: 50rem;
		margin: auto;
	}
}
.Countdown_Wrapper {  
	display: flex;
	justify-content: space-between;    
}
.Countdown_Headline {
	@include heading--md();
	text-align: center;
	color: var(--color-light);
	margin-bottom: var(--spacing--sm);
	@include mq("md") {
		text-align: left; 
	}
}
.Countdown_Time{
	text-align: center;
}
.Countdown_Digits{
	font-size: var(--font-size--4xl);
	color: var(--color-secondary);
}
.Countdown_Text{
	@include paragraph();
	color: var(--color-light);
}
