
@import "ui/styles/2-tools/tools.media-query.scss";

.ArticleTextNews{
	margin-top: var(--spacing--md);
    margin-bottom: var( --spacing--xl);
}

.ArticleTextNews_pattern{
	display: none;
	
	@include mq("lg") {
		display: block;
		position: absolute;		
		width: 12%;
		height: 40rem;
		left: 0;	
	}
}
.ArticleTextNews_socialTitle{
	color: var(--color-primary);
	font-size: var(--font-size--sm);	
	font-weight: var(--font-weight--bold);
	margin-bottom: var(--spacing--sm);
}

// theme-tillidsportal custome style
:global(.theme-tillidsportal){
	.ArticleTextNews_pattern{
		display: none;
	}
}