@import "ui/styles/2-tools/tools.action";
@import "ui/styles/2-tools/tools.typography-mixins.scss";

.FormFieldCheckbox {
	position: relative;
	display: flex;
	flex-flow: column;
	color: var(--color-gradient--80);

	&.hasError {
		color: var(--color-error);
	}

	&.isDisabled {
		color: var(--color-gradient--5);
	}
}

.FormFieldCheckbox_label {
	display: flex;
	cursor: pointer;
	position: relative;
	align-items: center;
	font-size: var(--font-size--sm);
	.isDisabled & {
		cursor: not-allowed;
	}

	.FormFieldCheckbox___multiline & {
		align-items: flex-start;
	}

	&:before {
		content: "";
		display: block;
		width: 2.5rem;
		height: 2.5rem;
		border: var(--border--sm) solid var(--color-primary);
		margin-right: var(--spacing--xs);
		flex-shrink: 0;
		border-radius: var(--corner-size--sm);
		.hasError & {
			border-color: var(--color-error);
		}
		.FormFieldCheckbox_input:checked + & {
			background-image: url("../../../../../../public/media/icon-check.png");
			background-repeat: no-repeat;
			background-position: center;
			background-size: 12px;
			border: var(--border--md) solid var(--color-primary);
		}
	}

	&:after {
		content: "";
		display: block;
		width: 3rem/3.2;
		height: 3rem/2;
		transform: rotate(45deg) translate(50%, -50%);
		border: solid transparent;
		border-width: 0 var(--border--md) var(--border--md) 0;
		position: absolute;
		left: var(--spacing--xs) / 10;
	}
}

.FormFieldCheckbox_input {
	@include input;
	width: 3rem;
	height: 3rem;
	margin: var(--spacing--2xs) 0;
	position: absolute;
	opacity: 0;
	&.hasError {
		color: var(--color-error);
	}
}
.FormFieldCheckbox_input:focus ~ .FormFieldCheckbox_label:before,
.FormFieldCheckbox_input:not([disabled]) ~ .FormFieldCheckbox_label:hover:before {
	border: var(--border--md) solid var(--color-primary);
}

