@import "ui/styles/2-tools/tools.display-mixins.scss";
@import "ui/styles/2-tools/tools.media-query.scss";
@import "ui/styles/2-tools/tools.typography-mixins.scss";
@import "ui/styles/2-tools/tools.behavior.scss";

.ContactList {
	color: var(--color-primary);
	position: relative;

	&_filter {
		position: relative;
		margin-bottom: 1.5rem;

		@include mq("sm") {
			margin-right: -2.5rem;
		}
	}

	&_list {
		margin: 0 var(--spacing-md);
		padding-top: 4rem;
		display: grid;
		grid-gap: var(--spacing--md);
		animation: fadeInDown 500ms ease-in-out;

		@include mq("sm") {
			grid-gap: var(--spacing--xl) var(--spacing--md);
			grid-template-columns: 1fr 1fr;
		}
		@include mq("lg") {
			// grid-template-columns: 1fr 1fr 1fr;
		}
	}

	&_heading {
		@include heading--lg();
		padding-right: 7rem;

		@include mq("md-lg") {
			padding-right: 22rem;
		}
	}
}

@keyframes fadeInDown {
	0% {
		opacity: 0;
		transform: translateY(-5px);
	}
	100% {
		opacity: 1;
		transform: translateY(0);
	}
}

.ContactList_wrapper {
	position: relative;
	padding-left: var(--grid-gutter);
	padding-right: var(--grid-gutter);
	margin-bottom: var(--spacing--3xl);

	margin-top: 8rem;
	@include mq("md") {
		margin-top: 12rem;
	}
}
