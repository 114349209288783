@import "ui/styles/2-tools/tools.media-query.scss";
@import "ui/styles/2-tools/tools.aspect-ratio.scss";
@import "ui/styles/2-tools/tools.display-mixins.scss";

.ProductCard {
	margin-bottom: calc(var(--grid-gutter) * 2);
}

.ProductCard_link {
	display: block;
	text-decoration: none;
}

.ProductCard_image {
	position: relative;
}
.ProductCard_imageBox {
	@include aspect-ratio(4, 3);
}

.ProductCard_imageElement {
	transition: all 0.2s ease-in-out;
	.ProductCard_link:hover &,
	.ProductCard_link:focus & {
		transform: scale(1.1);
	}
}

.ProductCard_price {
	position: absolute;
	top: 0;
	right: 0;
	padding: var(--spacing--xs);
	background-color: var(--color-primary);
	color: var(--color-light);

	@include mq("lg") {
		padding: var(--spacing--sm);
		font-size: var(--font-size--lg);
	}
}

.ProductCard_priceCurrency {
	margin-left: var(--spacing--2xs);
}

.ProductCard_details {
	padding-top: var(--spacing--xs);
	color: var(--color-black);
}

.ProductCard_category {
	font-size: var(--font-size--sm);
	font-style: italic;
}

.ProductCard_name {
	font-size: var(--font-size--lg);
	font-weight: var(--font-weight--bold);
}

.ProductCard_variantsWrapper {
	padding-top: var(--spacing--xs);
}

.ProductCard_variantList {
	margin-bottom: var(--spacing--xs);
	list-style: none;
	display: flex;
}

.ProductCard_variantListItem {
	margin-right: var(--spacing--sm);
	color: var(--color-dark);
}

.ProductCard_soldOut {
	margin-top: var(--spacing--xs);
	color: var(--color-error);
}

.ProductCard_variant {
	@include visually-hidden;
}
