@import "ui/styles/2-tools/tools.display-mixins.scss";
@import "ui/styles/2-tools/tools.media-query.scss";
@import "ui/styles/2-tools/tools.behavior.scss";
@import "ui/styles/2-tools/tools.typography-mixins-tillidsportal.scss";

.SituationBanner {
	width: 100%;
	padding: 5rem 0;
	color: var(--color-primary);
	@include mq("md-lg") {
		padding: 12rem 0 12rem 0;
	}
}
.SituationBanner_top {
	display: flex;

	@include mq("md-lg") {
		max-width: 75% !important;
	}
}
.SituationBanner_heading {
	@include t-h2;
	margin-bottom: var(--spacing--sm);

	@include mq("md") {
		margin-bottom: var(--spacing--md);
	}
	@include mq("md-lg") {
		margin-bottom: var(--spacing--xl);
	}
}

.SituationBanner_bottom {
	animation: fadeInDown 500ms ease-in-out;
	display: grid;
	grid-template-columns: 1fr;
	@include mq("md-lg") {
		grid-gap: var(--spacing--sm);
		grid-template-columns: 1fr 1fr 1fr;
	}
}

.SituationBanner_link {
	margin-left: 3rem;
	line-height: 2.2em;
	font-weight: 700;
	text-decoration: underline;
	color: var(--color-primary);

	&:hover {
		opacity: 0.75;
	}
	@include mq("md") {
		margin-left: 4rem;
		line-height: 4em;
	}
}
