@import "ui/styles/2-tools/tools.media-query.scss";
@import "ui/styles/2-tools/tools.typography-mixins.scss";
@import "ui/styles/2-tools/tools.behavior.scss";

.Links{
	display: inline-flex;
	justify-content: space-between;
	text-align: left;
}
 
.Links_icon{
	min-width: 20px;
	min-height: 20px;
	
}
