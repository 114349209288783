@import "ui/styles/2-tools/_tools.typography-mixins-tillidsportal.scss";

.container {
	height: 45px;
	width: 100%;
	
	position: relative;
}

.select {
	height: 100%;
	width: 100%;

	border: 1px solid #01013c;
	border-radius: 5px;

	padding: 0;
	padding-left: 24px;
	padding-right: (24px + 14px + 24px);

	appearance: none;

	@include t-body-s;
	font-weight: 600;
	color: #01013c;
	overflow-x: hidden;
	text-overflow: ellipsis;
}

.icon {
	position: absolute;

	width: 14px;
	height: 8px;

	top: 50%;
	margin-top: -4px;
	right: 24px;

	pointer-events: none;
}
