.Spinner {
	width: var(--icon-size--lg);
	height: var(--icon-size--lg);
	border-top-color: var(--color-primary);
	border-left-color: var(--color-primary);
	animation: spinner 400ms linear infinite;
	border-bottom-color: transparent;
	border-right-color: transparent;
	border-style: solid;
	border-width: var(--border--md);
	border-radius: 50%;
	box-sizing: border-box;
	display: table;
	margin: 0 auto;
	vertical-align: middle;
	margin-bottom: 3rem;
	margin-top: 3rem;
}

@keyframes spinner {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(359deg);
	}
}
.Spinner___largeThin {
	margin: 12rem auto;
	padding: 2.5rem;
}

.Spinner___large {
	width: var(--icon-size--2xl);
	height: var(--icon-size--2xl);
	border-width: var(--border--2xl);
}

.Spinner___slow {
	animation: spinner 1s linear infinite;
}

.Spinner___error {
	border-top-color: var(--color-error);
	border-left-color: var(--color-error);
}

.Spinner___white {
	border-top-color: var(--color-white);
	border-left-color: var(--color-white);
}

.Spinner_screenReader {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	white-space: nowrap;
	border: 0;
}

@media screen and (prefers-reduced-motion: reduce), (update: slow) {
	.Spinner {
		display: none;
	}

	.Spinner_loadingMsg {
		position: relative;
		width: auto;
		height: auto;
		padding: var(--spacing--xs);
		overflow: hidden;
		clip: auto;
		white-space: nowrap;
	}
}
