@import "ui/styles/2-tools/tools.media-query.scss";
@import "ui/styles/2-tools/tools.behavior.scss";

.Footer {
	padding: calc(var(--grid-gutter) * 8) calc(var(--grid-gutter) * 2);
	line-height: var(--line-height--2xl);
	color: rgba(255, 255, 255, 0.75);
	background-color: var(--color-primary);
}

.Footer_container {
	display: flex;
}
.Footer_inner{
	width: 100%;
}
.Footer_logoWrapper {    
	min-width: 7rem;
	margin-bottom: 1rem;
	@include mq("md") {
		position: relative;
		top: -4rem;
		width: 10%;
		flex: 0 0 10%;
		order: -1;
		margin-top: 0;
	}
}

.Footer_corp{
		display: block;		
		margin-right: 3rem;
		width: 100%;
		height: 7rem;
		margin-bottom: 1rem;
		@include mq("md") {
			width: 7rem;		
		}
}
.Footer_containerSoMe{	
	@include mq("md") {
		justify-content: center;	
	}
}
.Footer_SoMe{
	margin: 1rem 0;		
	display: flex;	
}

.Footer_SoMeLink {
	display: flex;
	align-items: center;
	text-decoration: none;	
	color: var(--some-primary);
	margin-bottom: 1rem;
	margin-right: 1rem;
	circle {
		fill: var(--some-primary);
		stroke: none;
		@include transition {
			transition: fill var(--trans-time--standard) ease;
		}
		&:hover{
			fill: var(--color-white);
		}
	}

	path {
		fill: var(--some-secondary);
	}

	&:hover{
		.Footer_SoMeLinkText {
			border-bottom: 1px solid currentColor;
		}
		circle {			
			fill: var(--color-white);		
		}
	} 
}

.Footer_SoMeLinkIcon{
	width: var(--icon-size--xl);
	height: var(--icon-size--xl);
}

.Footer_SoMeLink {
	color: var(--some-text-color);
}
.Footer_SoMeLinkText{
	margin-left: 1rem;
}
