@import "ui/styles/2-tools/tools.media-query.scss";
@import "ui/styles/2-tools/tools.behavior.scss";

.Header {
	position: sticky;
	top: 0;
	font-size: var(--font-size--sm);
	color: var(--color-light);
	padding: 1.5em var(--spacing--base);
	z-index: var(--z-index--header);
	background-color: var(--color-primary);
}

.Header_logo {
	height: auto;
	max-height: 70px;
	min-width: fit-content;
	padding-left: 1rem;
	@include mq("lg") {
		padding-left: 0;
	}
}

.Header_navigation {
	text-align: right;
	flex: 1;
	margin-left: auto;
	overflow: hidden;
	@include mq("lg", max) {
		order: 2;
		flex: initial;
	}

	display: flex;
	justify-content: space-between;
}

.Header_navWrapperContainer {
	justify-self: center;
}
.Header_navContainer {
	position: relative;
}
.Header_nav {
	display: block;
	position: absolute;
	width: 100%;
	height: calc(100vh - #{100px});
	top: 93px;
	left: -100%;
	margin: auto;
	padding: var(--spacing--base);
	background-color: var(--color-primary);
	text-align: start;

	@include transition {
		transition: left var(--trans-time--standard) ease;
	}

	@include mq("lg") {
		padding: 0;
	}

	@include mq("lg") {
		display: grid;
		grid-template-columns: 1fr 150px;
		text-align: end;

		background-color: transparent;
		height: auto;
		position: static;
		align-items: center;
	}

	&___active {
		left: 0;
	}
}

.Header_navToggle {
	@include mq("lg") {
		display: none;
	}
}

.Header_navController {
	display: none;
}

.Header_navToggle {
	&[aria-expanded="true"] {
		.Header_iconBar {
			&:first-child {
				opacity: 0;
			}
			&:last-child {
				opacity: 0;
			}
			&:nth-child(2) {
				transform: rotate(45deg) translateY(-50%);
				opacity: 1;
			}
			&:nth-child(3) {
				transform: rotate(-45deg) translateY(-50%);
				opacity: 1;
			}
		}
	}
}
.Header_icon {
	width: 2em;
	height: 2em;
	display: inline-block;
	position: relative;
}

.Header_iconBar {
	display: block;
	width: 100%;
	height: 0px;
	position: absolute;
	background: currentColor;
	border: 0.8px solid currentColor;
	opacity: 0;
	top: 50%;
	transform: rotate(0) translateY(-50%);

	&:first-child {
		margin-top: -0.5em;
		opacity: 1;
	}

	&:last-child {
		margin-top: 0.5em;
		opacity: 1;
	}
}

.Header_someContainer {
	display: block;
	margin-top: var(--spacing--2xl);
	@include mq("lg") {
		display: none;
	}
}

.Header_someItem {
	margin: var(--spacing--sm) 0;
}

.Header_someLink {
	display: flex;
	align-items: center;
}

.Header_someLinkText {
	padding-left: var(--spacing--md);
	font-size: var(--font-size--lg);
	font-weight: var(--font-weight--light);
}

.Header_cart {
	@include mq("lg", max) {
		order: 1;
		margin-right: var(--spacing--sm);
	}
}

.Header_memberLink {
	margin-block-start: 3rem;
	margin-inline-start: 5rem;
	color: var(--button-text-secondary);
	background-color: var(--button-bg-secondary);
	background: transparent;
	font-weight: var(--font-weight--semibold);
	cursor: pointer;
	display: inline-block;
	padding: var(--spacing--sm) var(--spacing--md);
	text-decoration: none;
	line-height: var(--line-height--lg);
	border-radius: var(--corner-size--base);
	border: var(--border--md) solid var(--color-border--light);
	text-align: center;
	width: 150px;
	transition: all var(--trans-time--standard);
	line-height: var(--line-height--2xl);
	&:hover {
		box-shadow: inset 0 -4.5em 0 0 var(--button-bg-secondary-hover);
		color: var(--button-text-primary);
	}
	@include mq("lg", min) {
		margin: 0;
	}
}
