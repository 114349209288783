@import "ui/styles/2-tools/tools.display-mixins.scss";
@import "ui/styles/2-tools/tools.media-query.scss";

.CardLabel_container {
	width: fit-content;
	border-radius: var(--corner-size--sm) var(--corner-size--sm) 0 0;
	border: none;
	padding: var(--spacing--2xs) var(--spacing--xs);	
	@include mq("md") {
		margin-top: -24px;
	}		
}

.CardLabel_title {
	color: var(--text-primary);
	font-weight: var(--font-weight--semibold);
}

