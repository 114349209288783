@import "ui/styles/2-tools/tools.media-query.scss";
@import "ui/styles/2-tools/tools.display-mixins.scss";
@import "ui/styles/2-tools/tools.typography-mixins.scss";
@import "ui/styles/2-tools/_tools.typography-mixins-tillidsportal.scss";
@import "ui/styles/2-tools/tools.behavior.scss";
@import "ui/styles/2-tools/tools.media-query.scss";
@import "ui/styles/2-tools/tools.mixins.scss";
@import "ui/styles/2-tools/tools.action";
@import "ui/styles/2-tools/tools.typography-mixins.scss";
@import "ui/styles/2-tools/tools.typography-mixins-tillidsportal.scss";
@import "ui/styles/2-tools/tools.grid-mixins.scss";
@import "ui/styles/2-tools/tools.space.scss";

.Payment {
	display: grid;
	width: 100%;
	animation: fadeInDown 500ms ease-in-out;
	overflow: hidden;
	padding-top: 2em;
	padding-bottom: 2em;
	padding-right: 10px;
	border-right: 0;

	h2 {
		margin-bottom: 1em;
	}

	form {
		padding-top: 2em;
		padding-bottom: 2em;

		button {
			margin-top: 2em;
		}
	}

	@include mq("md") {
		width: 50%;
	}
}

.Payment_wrapper {
	background-color: rgba(0, 0, 100, .7);
	max-width: unset !important;
	z-index: 1;
	color: var(--color-light);
	text-align: center;
	padding: 12rem 0;

	@include mq("md") {
		height: auto;
	}
}

.Payment_primary {
	display: flex;
	position: relative;
}

.Payment_secondary {
	display: flex;
	position: relative;
	background-color: rgba(0, 0, 100, 1);
}

.Payment_contentWrapper {
	@include mq("md") {
		max-width: 75% !important;
	}
}

.Payment_heading {
	display: flex;
	align-items: center;
	text-align: left;
	font-size: 3.2rem;
	color: var(--color-light);
	margin-bottom: var(--spacing--lg);
	@include text-shadow;
}

.Payment_text {
	@include t-body;
	color: var(--color-light);
	margin-bottom: var(--spacing--lg);
	@include text-shadow;
}

.Payment_cta {
	display: flex;
	align-self: center;
	gap: 19px;
	justify-content: flex-start;
	width: 100%;
	margin-bottom: var(--spacing--lg);

	&___primary {
		display: grid;
		grid-auto-columns: minmax(0, 1fr);

		@include mq("md") {
			display: flex;
		}
	}
}

.Payment_content {
	display: flex;
	text-align: left;
	margin: auto;
	align-items: center;

	@include mq("lg") {
		padding-left: calc((var(--window-size) / 2 * 0.1rem) - (115.8rem / 2) - var(--spacing--xl));
		padding-right: var(--spacing--xl);

		.Payment_secondary & {
			padding-right: calc(var(--window-size)/2 * 0.1rem - (115.8rem / 2) - var(--spacing--xl));
			padding-left: 8rem;
			padding-left: var(--spacing--3xl);
		}
	}
}
.FormField_input {
	padding-bottom: 1em;
}
.Payment_link {
	width: 100%;

	@include mq("md") {
		width: auto;
	}
}

.Payment_linkPrimary {
	min-width: initial;
}

.Payment_linkSecondary {
	color: var(--color-light);
	min-width: initial;
	background: transparent;
	border: 1px solid var(--color-light)
}

.Payment_mediabox {
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: calc(100% + 20rem);
	transform: translateY(-10rem);
	object-fit: cover;
}

.Payment_mediabox_wrapper {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}

.Payment_icon {
	width: 4rem;
	height: 4rem;
	margin-right: 2rem;
}

.Payment_bottomLinkText {
	margin-right: .8rem;
}

.Payment_bottomLink {
	@include t-body;
	display: inline-block;
	color: currentColor;
	position: relative;
	text-decoration: none;
	border-bottom: 1px solid var(--color-light);
	align-items: center;
	max-width: fit-content;
	cursor: pointer;

	&:after {
		@include transition {
			transition: width var(--trans-time--standard) ease;
		}

		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		margin: auto;
		width: 0%;
		content: ".";
		color: transparent;
		background: var(--color-light);
		height: 1px;
	}

	&:hover:after {
		width: 100%;
	}
}


.Payment__messageIcon {
	margin-right: .5rem
}

.Payment_failText {
	display: inline-flex;
	width: 100%;
	color: var(--color-error);
	top: -2rem;
}

.Payment_failstar {
	color: red;
}

.payment__secondary {
	background-color: var(--color-secondary);
	width: 100%;
	justify-content: center;
	align-items: center;
	display: flex;
	padding: 2em;

}
