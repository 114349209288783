@import "ui/styles/2-tools/tools.display-mixins.scss";
@import "ui/styles/2-tools/tools.media-query.scss";
@import "ui/styles/2-tools/tools.behavior.scss";

.ContactCard {
	background-color: var(--color-tertiary-lighter);
	border-radius: 5px;
	padding: 2rem;

	@include mq("md") {
		display: flex;
		justify-content: space-between;
		gap: 2rem;
		height: 100%;
		padding: 2rem;
	}
	@include mq("md-lg") {
		padding: 4rem;
	}
}

.ContactCard_header {
	color: var(--text-secondary);
	padding: 0 0 var(--spacing--xs) 0;
}

.ContactCard_body {
	@include display-flex;
	flex-direction: column;
	justify-content: space-between;
	margin-top: 1rem;
	padding-top: 1rem;
	border-top: 1px solid var(--color-tertiary);
	svg {
		fill: transparent;
	}
}

.ContactCard_row {
	display: flex;
	margin: var(--spacing--xs) 0;
	gap: 0.5rem;
	flex-wrap: wrap;
}
.ContactCard_link {
	color: var(--text-secondary);
	font-size: var(--font-size--sm);
	padding-right: var(--spacing--xs);
	text-decoration: none;

	& + .ContactCard_link {
		margin-left: var(--spacing--md);
	}
}
.ContactCard_icon {
	margin-right: var(--spacing--xs);
	min-width: 2rem;
}
.ContactCard_mediabox {
	max-width: 80px;
	@include mq("md") {
		max-width: 180px;
	}
}
.ContactCard_mediabox,
.ContactCard_placeholder {
	display: block;
	width: 100%;
	height: auto;
	margin-bottom: 2rem;
	overflow: hidden;
	border-radius: 0 var(--corner-size--lg) 0 0;
	@include mq("md") {
		min-width: 14rem;
		margin-bottom: 0em;
	}
}
.ContactCard_placeholder {
	max-width: 80px;
	height: auto;
	background-color: #f1f1f3;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 1rem;

	@include mq("md") {
		max-width: 180px;
		margin-left: auto;
		margin-right: auto;
		padding: 2rem;
	}

	svg {
		width: 100%;
		height: auto;
	}
}
.ContactCard_details {
	@include mq("md") {
		width: 100%;
	}
}
