@import "ui/styles/2-tools/tools.typography-mixins.scss";

.NewsLetter {
	margin: 2rem 0;

	@include mq("md") {
		display: flex;
		align-items: flex-start;
		margin: 0;
	}
}

.Newsletter_label {
	min-width: 20rem;
	margin-bottom: var(--spacing--sm);
}

.Newsletter_link {
	min-width: 20rem;
	margin-bottom: 1rem;
	margin-top: 2rem;

	@include mq("md") {
		margin-top: 1rem;
	}
}

.NewsLetter_corp {
	display: block;
	margin-right: 3rem;
	width: 100%;
	height: 7rem;
	margin-bottom: 1.5rem;

	@include mq("md") {
		min-width: 7rem;
	}
}
