.Container {
	margin-left: auto;
	margin-right: auto;
	width: 100%;
	&___standard {
		max-width: 124.8rem;
	}

	&___narrow {
		max-width: 96rem;
	}

	&___slim {
		max-width: 72rem;
	}

	&___skinny {
		max-width: 65rem;
	}
}

// theme-tillidsportal custom style
:global(.theme-tillidsportal) {
	.Container {
		&___standard {
			//Due to the 10rem padding on .Main_article element:
			// Main.module.scss
			// to avoid underlap the navigation, max-width will be bigger by 10rem
			max-width: 133.8rem;
		}
	}
}

// ? This is a temporary removal for the theme-medlem, not sure if it is needed.
// // theme-medlemsPortal custom style
// :global(.theme-medlem) {
// 	.Container {
// 		&___standard {
// 			//Due to the 10rem padding on .Main_article element:
// 			// Main.module.scss
// 			// to avoid underlap the navigation, max-width will be bigger by 10rem
// 			max-width: calc(110rem + (var(--grid-gutter) * 2));
// 		}
// 	}
// }
