@import "ui/styles/2-tools/tools.display-mixins.scss";
@import "ui/styles/2-tools/tools.media-query.scss";
@import "ui/styles/2-tools/tools.behavior.scss";

.ListMember {
	position: relative;
	margin-bottom: var(--spacing--3xl);
	animation: fadeInDown 500ms ease-in-out;
}
@keyframes fadeInDown {
	0% {
		opacity: 0;
		transform: translateY(-5px);
	}
	100% {
		opacity: 1;
		transform: translateY(0);
	}
}

.ListMember_container {
	display: grid;
	grid-gap: var(--spacing--md);
	&___isDownloadable {
		margin: var(--spacing--xl) auto;
	}

	@include mq("md") {
		grid-gap: var(--spacing--xl) var(--spacing--md);
		grid-template-columns: 1fr 1fr 1fr;
	}
	:nth-of-type(1n + 13) {
		display: none;
	}
	&___isShowMore {
		animation: fadeIn 1.5s;
		:nth-of-type(1n + 13) {
			display: block;
			animation: fadeIn 1.3s;
		}
	}
	&.isListView {
		@include mq("md") {
			grid-template-columns: 1fr;
			grid-gap: var(--spacing--sm);
		}
	}
}
@keyframes fadeIn {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}
.ListMember_download {
	margin: var(--spacing--sm) auto var(--spacing--sm) 0;
	flex: 0 0 auto;

	.ListMember_button_label {
		@include display-flex;
		flex-wrap: wrap;
		flex-direction: row;
		justify-content: space-between;
	}

	&_icon {
		margin-left: var(--spacing--sm);
		svg {
			fill: none;
		}
	}
}

.ListMember_link {
	display: inline-flex;
	text-align: center;
	align-items: center;
	color: var(--color-primary);
	text-decoration: none;
	background: none;
	border: 0;
}
.ListMember_linkIconPlus {
	width: auto;
	height: auto;
	margin-left: 1rem;
	@include transition {
		transition: transform var(--trans-time--standard) ease;
	}
	.ListMember_link:hover & {
		transform: rotate(90deg); /* Equal to rotateZ(45deg) */
	}
}
.ListMember_linkIconMinus {
	width: auto;
	height: auto;
	margin-left: 1rem;
	@include transition {
		transition: transform var(--trans-time--standard) ease;
	}
	.ListMember_link:hover & {
		transform: rotate(180deg); /* Equal to rotateZ(45deg) */
	}
}
.ListMember_linkWrapper {
	text-align: center;
	margin-top: 7rem;
	font-weight: var(--font-weight--semibold);
}

.ListMember_actionsMenu {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}
.ListMember_actionsMenu_right {
	display: flex;
	justify-content: space-between;
	flex-wrap: nowrap;
	align-items: center;
}
