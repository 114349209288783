@import "ui/styles/2-tools/tools.typography-mixins.scss";
@import "ui/styles/2-tools/tools.behavior.scss";

.ListDownload {
	text-align: center;
	margin-top: var(--spacing--xl);	
	margin-bottom: 5rem;
}

.ListDownload_heading{
	@include heading--xl();
	color: var(--color-primary);
	margin-bottom: var(--spacing--2xl);
}

.List_pattern{
	display: none;
	@include mq("md") {
		display: block;
		position: absolute;
		bottom: -3rem;
		width: 13rem;
		height: 40rem;
		right: 0;
		top: 6.5rem;
	}
}
.List_rows{
	position: relative;
	background: var(--color-light);
	padding-top: var(--spacing--xs);
}
.ListDownload_form{
	display: flex;
	flex: 0 0 100%;
	flex-direction: column;		
	max-height: 500px;
	overflow: hidden;
	@include transition {
		transition: max-height var(--trans-time--standard) ease;
	}	
	@include mq("sm") {
		flex-direction: row;
	}
}

.ListDownload_filter{
	margin-bottom: var(--spacing--2xl);
	width: 100%;
    text-align: left;
}
.ListDownload_select{	
	margin-bottom: var(--spacing--md);
	@include mq("sm") {
		margin-right: var(--spacing--md);
	}
}
.ListDownload_filter{
	color: var(--color-primary);
}


.ListDownload_filterTabs {
	display: flex;
	flex-wrap: wrap;
	position: relative;
	
}
.ListDownload_filterInput[type="radio"] {
	display: none;
}

.ListDownload_filterTab {
	display: flex;
	align-items: center;
	margin-bottom: 2rem;
	justify-content: center;
	height: 5rem;
    width: 10rem;
    font-size: var(--font-size--xl);
	font-weight: var(--font-weight--bold);	
	cursor: pointer;	
	@include transition {
		transition: color var(--trans-time--standard) ease;
	}
	&:first-of-type{
		margin-right: 2rem;
	}
}

.ListDownload_filterInput[type="radio"] {
	&:checked {
		& + label {
			color: var(--primary-color);			
		}
		& ~ .FormfieldSelect_wrapper {
			display: none;
		}
	}
}

.ListDownload_filterInput[id="radio-1"] {
	&:checked {
		& ~ .ListDownload_filterGlider {
			transform: translateX(0);
		}		
	}
}

.ListDownload_filterInput[id="radio-2"] {
	&:checked {
		& ~ .ListDownload_filterGlider {
			transform: translateX(120%);
		}
		& ~ .ListDownload_filterGlider + .ListDownload_form {
			@include transition {
				transition: max-height var(--trans-time--standard) ease;
			}	
			max-height: 0;			
		}
		
	}
}

.ListDownload_filterGlider {
	position: absolute;
	top: 5rem;
	display: flex;
	height: .4rem;
	width: 10rem;
	background-color: var(--color-secondary);
	z-index: 1;	
	@include transition {
		transition: all var(--trans-time--standard) ease;
	}	
}

