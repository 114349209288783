@import "ui/styles/2-tools/tools.media-query.scss";
@import "ui/styles/2-tools/tools.aspect-ratio.scss";
@import "ui/styles/2-tools/tools.behavior.scss";
@import "ui/styles/2-tools/_tools.typography-mixins-tillidsportal.scss";

.ShortcutCard {
	display: inline-flex;
	flex-direction: column;
	min-height: 25rem;
	position: relative;
	height: auto;
	padding: 2.5rem;
	border-radius: 0 var(--corner-size--lg) 0 0;
	color: var(--color-primary);
	background-color: var(--color-light);
	text-decoration: none;
	text-align: left;
	@include transition {
		transition: background-color var(--trans-time--standard) ease;
	}
	&:hover,
	&:focus {
		background-color: var(--color-tertiary-lighter);
		box-shadow: unset;
	}
	@include mq("md-lg") {
		height: 100%;
	}
}
.ShortcutCard_heading {
	font-size: 2rem;
	line-height: 2.4rem;
	margin-bottom: 2.4rem;

	@include mq("md-lg") {
		font-size: 2.4rem;
		line-height: 2.6rem;
	}
}
.ShortcutCard_text {
	margin-bottom: var(--spacing--xl);
	@include t-body;
}

.ShortcutCard_icon {
	display: flex;
	justify-content: flex-end;
	position: relative;
	flex-grow: 1;
	align-items: flex-end;
	width: 100%;
	color: transparent;
	transform: translate(0);
	@include transition {
		transition: transform var(--trans-time--standard) ease;
	}
	svg {
		width: auto;
		height: auto;
	}
	.ShortcutCard:hover & {
		transform: translate(5px);
	}
}
// Adding style to toggle class implemented on the Register component
:global(.Register_overlayLinkItems) {
	.ShortcutCard_text {
		margin-bottom: var(--spacing--lg);
	}
}
