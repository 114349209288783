@import "ui/styles/2-tools/tools.display-mixins.scss";
@import "ui/styles/2-tools/tools.media-query.scss";
@import "ui/styles/2-tools/tools.behavior.scss";

.MemberCard_container {
	@include display-block;
	position: relative;
}
.MemberCard {
	background-color: var(--color-tertiary-lighter);
	border-radius: 5px;
	padding: var(--spacing--md);

	&_header {
		@include display-flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: flex-start;

		color: var(--text-secondary);
		padding: 0 0 var(--spacing--xs) 0;

		.chairedTitle,
		.jobTitle {
			font-weight: var(--font-weight--normal);
		}
		.chairedTitle {
			font-size: var(--font-size--sm);
			margin-bottom: var(--spacing--2xs);
		}
		.jobTitle {
			font-size: var(--font-size--md);
		}
	}
	&_body {
		transition: height var(--trans-time--shortest) ease-in-out;
		height: 0;
		overflow: hidden;

		@include mq("md") {
			height: fit-content;
			transition: none;
		}

		&.isExpand {
			margin-top: var(--spacing--xs);
			height: 15.5rem;
		}

		hr {
			border: 0.5px solid var(--color-tertiary);
			margin: var(--spacing--xs) 0;
		}
	}

	@include mq("md") {
		height: 100%;
	}
}
.MemberCard_expandable {
	all: initial;
	order: 2;
	cursor: pointer;

	&_icon {
		svg {
			fill: transparent;
		}

		// Hide icon  on desktop version
		@include mq("md") {
			@include display-none;
		}
	}
}
.MemberCard_contact_info {
	@include display-flex;
	flex-direction: column;
	justify-content: space-between;

	margin-top: 1rem;
	padding-top: 1rem;
	border-top: 1px solid var(--color-tertiary);
	overflow: hidden;
	flex-wrap: wrap;

	svg {
		fill: transparent;
	}
	opacity: 0;
	transition: opacity 140ms 100ms ease-in;

	.isExpand & {
		opacity: 1;
	}

	@include mq("md") {
		opacity: 1;
	}

	&_row {
		margin: var(--spacing--xs) 0;
		@include display-flex;
		flex-direction: row;
		flex-wrap: wrap;
		align-items: center;

		&_top_align {
			align-items: flex-start;
		}

		// Child items
		&_icon {
			margin-right: var(--spacing--xs);
		}

		&_data {
			color: var(--text-secondary);
			font-size: var(--font-size--sm);
			font-weight: var(--font-weight--normal);
			font-family: var(--font-primary);

			padding-right: var(--spacing--xs);

			&_email_link {
				text-decoration: none;
			}
			&_wrapper {
				@include display-flex;
				flex-direction: column;
			}
		}
	}
}

.isListView {
	@include mq("md") {
		.MemberCard {
			display: flex;
			padding: var(--spacing--md);
		}

		.MemberCard_body {
			width: 70%;
			margin-left: auto;
		}
		.MemberCard_header {
			@include display-flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: flex-start;
			padding: 0;
		}
		.MemberCard_contact_info {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			font-size: var(--font-size--md);
			border: none;
			margin: 0;
			padding: 0 0 0 10%;
			border-left: 1px solid var(--color-tertiary);
		}
		.MemberCard_contact_info_row_data {
			font-size: var(--font-size--md);
		}
		.MemberCard_contact_info_row_icon {
			margin-left: -2.5rem;
		}
	}
}
.MemberCard_container.isListView {
	@include mq("md") {
		margin-bottom: 2rem;
	}
}
