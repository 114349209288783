@import "ui/styles/2-tools/tools.display-mixins.scss";
@import "ui/styles/2-tools/tools.typography-mixins.scss";
@import "ui/styles/2-tools/tools.grid-mixins.scss";
@import "ui/styles/2-tools/tools.behavior.scss";


.AgreementLinkCard {
	padding: var(--spacing--md) var(--spacing--sm);
}

.AgreementLinkCard_link_container {
	text-decoration: none;
	display: inline-flex;
    align-items: center;
	color: var(--color-primary);
	font-size: var(--font-size--md);
	@include transition {
		transition: color var(--trans-time--standard) ease;
	}
	&:hover{
		color: var(--color-secondary);
	}
}

.AgreementLinkCard_link_text {
	font-family: var(--font-primary);
	font-weight: var(--font-weight--light);
	font-size: var(--font-size--xxs);	
}

.AgreementLinkCard_link_icon {
		fill: var(--color-primary);	
		width: var(--icon-size--md);
		height: var(--icon-size--md);
		position: relative;
		top: -0.2rem;
		left: 0;		
		@include transition {
			transition: left var(--trans-time--standard) ease;
		}
		.AgreementLinkCard_link_container:hover &{			
			left: .3rem;
		}
}
