@import "ui/styles/2-tools/tools.media-query.scss";
@import "ui/styles/2-tools/tools.typography-mixins.scss";
@import "ui/styles/2-tools/tools.behavior.scss";
@import "ui/styles/2-tools/_tools.typography-mixins-tillidsportal.scss";

.Timeline {
	display: block;
	color: var(--color-primary);
	@include mq("md") {
		display: inline-flex;
		align-items: flex-start;
	}
}

.Timeline_time {
	display: block;
	font-size: 3.2rem;
	font-weight: var(--font-weight--bold);
	line-height: 3.2rem;
	margin-bottom: 1.6rem;
	margin-right: 4.8rem;
	@include mq("md") {
		font-size: 5.4rem;
		line-height: 5rem;
	}
}

.Timeline_content {
	font-size: 1.8rem;
	line-height: 2.6rem;
	@include mq("md") {
		max-width: 50rem;
	}
}
