@import "ui/styles/2-tools/tools.typography-mixins.scss";
@import "ui/styles/2-tools/tools.behavior.scss";

// LanguageBar

.LanguageBar_iconGlobe{
	margin-right: 5px;
	svg{
		fill: transparent;		
	}	
	path{
		stroke: var(--color-white);	
		
		@include mq('lg') {
			[aria-selected="true"] &{	
				stroke: var(--color-primary)
			}
		}
	}
}
.LanguageBar_iconChevron{
	margin-left: 5px;
	position: relative;
    top: 7px;
	width: 15px;
    height: 10px;
	@include transition {
		transition: transform var(--trans-time--standard) ease;
	}	
	[aria-selected="true"] &{	
		transform: rotate(180deg);
	}
	svg{
		fill: transparent;
	}
	path{	
		stroke: var(--color-white);	
		
		@include mq('lg') {
			[aria-selected="true"] &{	
				stroke: var(--color-primary)
			}
		}
	}
}
.LanguageBar_languageLink{
	display: inline-flex;
	font-weight: normal;
	cursor: pointer;
	z-index: 9;
    width: 100%;
    font-size: 15px;
	padding-left: 0;
    padding-right: 0;
	margin-bottom: 2rem;
	@include mq('lg') {
		margin-bottom: 0;
		justify-content: space-evenly;
		&[aria-selected="true"]{		
			color: var(--color-primary);
		}
	}
	
}

.LanguageBar_languageContainer{
	animation: fadeInDown 500ms ease-in-out;
	color: var(--color--light);    
    border-radius:1rem;
    text-align: left;
    width: 100%;	
	@include mq('lg') {
		position: absolute;
		top: 2rem;
		left: 0;
		padding: 4rem 1.5rem 1rem 1.5rem;
		background: var(--color-light);
		color: var(--color-primary);
		box-shadow: 0px 1px 6px 1px rgb(0 0 0 / 48%);
	}
}
@keyframes fadeInDown {
    0% {
        opacity: 0;
		transform: translateY(-10px);
    }
    100% {
        opacity: 1;
		transform: translateY(0);
       
    }
}
.LanguageBar_languageItemContainer{	
	margin-bottom: 2rem;
	@include mq('lg') {
		margin-top: 2rem;
		margin-bottom: 0;
	}
}
.LanguageBar_languageItems{
	list-style-type: none;	
	width: 100%;
    padding: 0.6rem 0;
    border-radius: 0.4rem;    
	font-weight: var(--font-weight--bold);	
	margin-bottom: 1rem;
	cursor: pointer;
	@include mq('lg') {
		position: relative;
    	right: 5%;
		padding: 0.6rem 2rem;
		width: 110%;
		&:hover{
			background-color: var(--color-tertiary-lighter);
		}
	}
	@include transition {
		transition: background-color var(--trans-time--standard) ease;
	}		
}
.LanguageBar_link{
	text-decoration: none;		
	color: var(--color-light);
	font-weight: normal;
	@include mq('lg') {
		color: var(--color-primary)
	}
}


