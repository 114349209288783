@import "ui/styles/2-tools/tools.display-mixins.scss";
@import "ui/styles/2-tools/tools.typography-mixins.scss";
@import "ui/styles/2-tools/tools.grid-mixins.scss";

.FileStackCard_container {
	background-color: var(--color-tertiary-lighter);
	border-radius: var(--corner-size--md);
	padding: 0 var(--spacing--md);

	@include display-flex;
	flex-direction: column;
	justify-content: space-around;

	&_separator {
		border-bottom: 1px solid var(--color-tertiary);
	}
}

.FileStackCard {
	@include display-flex;
	padding: var(--spacing--sm) 0;
}

.FileStackCard_heading_container {
	align-self: center;
	padding: 0;
}

.FileStackCard_heading {
	color: var(--color-header-secondary);
	font-weight: var(--font-weight--normal);
}

.FileStackCard_link_container {
	text-decoration: none;
}

.FileStackCard_link_text {
	font-family: var(--font-primary);
	font-weight: var(--font-weight--light);
	font-size: var(--font-size--xxs);
	color: var(--text-secondary);
}

.FileStackCard_icon_container {
	@include display-flex;
	flex-direction: column;
	align-items: flex-end;
}

.FileStackCard_link_icon {
	.FileStackCard:hover & {
		animation: mover 0.5s infinite alternate;
		position: relative;
	}
	svg {
		fill: none;
	}
}
@keyframes mover {
	0% {
		transform: translateY(0);
	}
	100% {
		transform: translateY(-5px);
	}
}
