@import "ui/styles/2-tools/tools.mixins";
@import "ui/styles/2-tools/tools.behavior.scss";

.AccordionCalendarItem_date{
	@include mq('md') {
		display: inline-block;
		font-size: var(--font-size--md);
		font-weight: var(--font-weight--normal);
		width: 100%;  
	}
}

.AccordionCalendarItem_content{
	//New
	display: block;
    position: absolute;
    top: 7rem;
	left: 0;
    width: 100%;
	background-color: var(--color-light);
	@include mq('md') {
		background-color: transparent;
		left: 1%;
		width: 98%;
		top: 9.5rem;
	}
}
.AccordionCalendarItem_wrapper{
	width: 100%;
	&:first-of-type{		
		.AccordionCalendarItem_title{
			border-radius: .5rem 0 0 0;
		}
	}	
	&:last-of-type
	{		
		.AccordionCalendarItem_title{
			border-radius: 0 .5rem 0 0;
		}
	}
	// Reseting the height before it's adjusted again in the tsx file
	&[aria-selected="false"]{
		height: 0!important
	}
}
.AccordionCalendarItem_text{
	display: none;
	@include mq('md') {
		display: inline-block;		
	}
}
.AccordionCalendarItem_title{	
	//New
	display: block;
	padding: 2.7rem 0.7rem;
	background: var(--color-tertiary-blue);	
    width: auto;
    height: fit-content;   
	text-align: center;
	font-size: var(--font-size--xs);
	font-weight: var(--font-weight--bold);	
	line-height: var(--line-height--md);	
	@include mq('md') {
		font-size: var(--font-size--xl);
		padding: 1.7rem 0.7rem;
	}
	@include transition {
		transition: background var(--trans-time--standard) ease;
	}
	&:hover{
		cursor: pointer;
		background: var(--color-secondary);
	}
	&[aria-selected="true"]{
		background: var(--color-secondary);
	}
}


