@import "ui/styles/2-tools/tools.media-query.scss";
@import "ui/styles/2-tools/tools.aspect-ratio.scss";
@import "ui/styles/2-tools/tools.behavior.scss";
@import "ui/styles/2-tools/_tools.typography-mixins-tillidsportal.scss";

.SituationCard {
	display: inline-flex;
	width: 100%;
	background-color: var(--color-tertiary-lighter);
	flex-direction: column;
	position: relative;
	padding: 2.5rem;
	border-radius: 1rem;
	color: var(--color-primary);
	text-decoration: none;
	text-align: left;
	margin-bottom: 0.8rem;

	@include mq("md-lg") {
		margin-bottom: 1.6rem;
	}

	@include mq("sm") {
		min-height: 15rem;
	}

	@include mq("md-lg") {
		margin-bottom: 0;
		padding: 6.4rem 2.5rem 3.2rem 2.5rem;
	}

	@include transition {
		transition: background-color var(--trans-time--standard) ease;
	}

	&:hover {
		box-shadow: unset;
		background-color: var(--button-bg-tertiary-hover);
	}

	&.SituationCard_parentIsBanner {
		@include mq("sm") {
			padding-bottom: 5.2rem;
		}
	}
	&:nth-child(5) {
		grid-column-start: 2;
		grid-column-end: 4;
		grid-row-start: 2;
		grid-row-end: 4;
	}

	&:nth-child(21) {
		grid-column-start: 2;
		grid-column-end: 4;
		grid-row-start: 10;
		grid-row-end: 12;
	}

	&:nth-child(37) {
		grid-column-start: 2;
		grid-column-end: 4;
		grid-row-start: 18;
		grid-row-end: 20;
	}

	&:nth-child(13) {
		grid-column-start: 1;
		grid-column-end: 3;
		grid-row-start: 6;
		grid-row-end: 8;
	}
	&:nth-child(29) {
		grid-column-start: 1;
		grid-column-end: 3;
		grid-row-start: 14;
		grid-row-end: 16;
	}
	&:nth-child(45) {
		grid-column-start: 1;
		grid-column-end: 3;
		grid-row-start: 22;
		grid-row-end: 24;
	}

	&:nth-child(5),
	&:nth-child(13),
	&:nth-child(21),
	&:nth-child(29),
	&:nth-child(37),
	&:nth-child(45) {
		background: var(--color-primary);
		color: var(--color-light);
		padding: 3rem;

		@include mq("sm") {
			padding: 4rem 7rem 6rem;
		}
		@include mq("lg") {
			padding: 7rem 8rem 4.8rem; // plus marginbottom (2.2rem)
		}
		@include mq("xl") {
			padding: 7rem 12rem 4.8rem; // plus marginbottom (2.2rem)
		}
		.SituationCard_text {
			display: block;
			margin-bottom: 2.2rem;
		}
		.SituationCard_heading {
			margin-bottom: 2.4rem;
		}

		.SituationCard_chevron {
			fill: white;
			top: 100%;
			transform: translateY(-5.2rem);
		}
	}
}

.SituationCard_heading {
	@include t-h4;
	margin-right: 2rem;
	@include mq("sm") {
		margin-bottom: 2.4rem;
		margin-right: 0;
	}
}

.SituationCard_text {
	@include t-body;
	display: none;
	margin-bottom: 2.4rem;

	@include mq("sm") {
		display: block;
	}
}

.SituationCard_icon {
	display: flex;
	justify-content: flex-end;
	position: relative;
	flex-grow: 1;
	align-items: flex-end;
	width: 100%;
	color: transparent;
	transform: translate(0);

	@include transition {
		transition: transform var(--trans-time--standard) ease;
	}
	svg {
		width: auto;
		height: auto;
	}

	.SituationCard:hover & {
		transform: translate(5px);
	}
}

.SituationCard_media {
	display: block;
	margin-bottom: var(--spacing--xl);
}

.SituationCard_mediabox {
	@include aspect-ratio(4, 3);
	border-radius: 0 var(--corner-size--lg) 0 0;
}

.SituationCard_picture {
	@include transition {
		transition: transform var(--trans-time--standard) ease;
	}

	.SituationCard:hover &,
	.SituationCard:focus & {
		transform: scale(1.05);
	}
}

.SituationCard_readMore {
	font-size: 1.6rem;
	font-weight: 600;
	margin-top: auto;
	line-height: 2.6rem;
	display: none;

	@include mq("sm") {
		display: block;
	}
}

.SituationCard_chevron {
	position: absolute;
	right: 2rem;
	top: 50%;
	transform: translateY(-50%);
	max-width: 2.6rem;
	fill: var(--color-primary);

	@include mq("sm") {
		display: none;

		&.SituationCard_parentIsBanner {
			display: block;
			right: initial;
			left: 2rem;
			bottom: 2rem;
			top: initial;
			transform: translate(0);
		}
	}
}
