@import "ui/styles/2-tools/tools.aspect-ratio.scss";

.ArticleMedia {
	color: var(--color-primary);	
	margin-top: var(--spacing--md);
	margin-bottom: var(--spacing--md);
	width: 100%;
}

.ArticleMedia_caption {
	font-style: italic;
	font-size: var(--font-size--sm);
	margin-top: var(--spacing--xs);
}

.ArticleMedia_mediabox {
	@include aspect-ratio(5, 2);
}
