.ArticleByline {	
	display: inline-flex;	
	color: var(--color-primary);
	padding-right: 1rem;
    border-right: 1px solid var(--border-color);
}

.ArticleByline_name{
	font-size: var(--font-size--sm);	
	font-weight: var(--font-weight--bold);
}
.ArticleByline_title {
	position: absolute;
	left: -9999px;
}
