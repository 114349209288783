@import "ui/styles/2-tools/tools.display-mixins.scss";
@import "ui/styles/2-tools/tools.media-query.scss";
@import "ui/styles/2-tools/tools.behavior.scss";
@import "ui/styles/2-tools/tools.typography-mixins-tillidsportal.scss";

.ListSituation {
	width: 100%;
	padding: 5rem 0;
	color: var(--color-primary);
	@include mq("md-lg") {
		padding: 12rem 0 5rem 0;
	}
}
.ListSituation_top {
	@include mq("md") {
		max-width: 75% !important;
	}
}
.ListSituation_heading {
	@include t-h2;
	margin-bottom: var(--spacing--sm);

	@include mq("md") {
		margin-bottom: var(--spacing--md);
	}
	@include mq("md-lg") {
		margin-bottom: var(--spacing--xl);
	}
}
.ListSituation_bottom {
	animation: fadeInDown 500ms ease-in-out;

	@include mq("md-lg") {
		display: grid;
		grid-gap: var(--spacing--sm);
		grid-template-columns: 1fr 1fr 1fr;
		grid-auto-rows: 1fr;
	}
}
