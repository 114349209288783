@import "ui/styles/2-tools/tools.media-query.scss";
@import "ui/styles/2-tools/tools.behavior.scss";

.FooterDef {
	padding: 12rem 0;
	line-height: var(--line-height--2xl);
	color: #fff;
	background-color: var(--color-primary);
	@include mq("lg") {
		padding: 12rem calc(var(--grid-gutter) * 2) 2.4rem;
	}
	&_container {
		display: grid;
		grid-template-columns: 1fr;
		flex-wrap: wrap;
		flex-direction: column;
		justify-content: space-between;
		gap: 4rem;
		max-width: 124.8rem;
		padding-left: var(--grid-gutter);
		padding-right: var(--grid-gutter);
		margin: 0 auto;

		@include mq("md") {
			flex-direction: row;
			gap: 2rem;
		}

		@include mq("md-lg") {
			grid-template-columns: 1fr 2fr;
			gap: 2rem 6rem;
		}
	}

	&_inner {
		width: 100%;
	}

	&_textBox {
		font-size: var(--font-size--sm);
		line-height: 2.4rem;
		margin: 4.8rem 0 1rem 0;
	}

	&_logoWrapper {
		display: flex;
		justify-content: space-between;
		gap: 1.5rem;

		@include mq("md") {
			padding-right: 1.5rem;
		}
	}

	&_containerLogo {
		display: flex;
		flex-direction: column;
		max-width: 35rem;
		flex: 0 1 30%;
	}

	&_containerNav {
		display: flex;
		flex-direction: column;
		gap: 4rem;

		@include mq("md") {
			flex-direction: row;
		}
		@include mq("md-lg") {
			justify-self: flex-end;
			gap: 6rem;
		}
	}

	&_containerNewsletter {
		min-width: 25rem;
		margin-top: 4rem;
		flex-basis: 30%;
	}

	&_newsletter {
		margin-top: -2rem;
		@include mq("md") {
			margin-top: initial;
		}
	}

	&_fullHeight {
		height: 100%;
		display: flex;
		flex-direction: column;
	}

	&_navList {
		list-style: none;
	}

	&_navListItem {
		line-height: 2.6rem;
		margin-bottom: 0.8rem;

		& a {
			color: var(--color-white);
			text-decoration: none;
			@include transition {
				transition: color var(--trans-time--standard) ease;
			}

			&:hover,
			&:focus {
				color: var(--color-secondary);
			}
		}
	}

	&_heading {
		font-size: var(--font-size--lg);
		font-weight: var(--font-weight--bold);
		margin-bottom: 2.4rem;
	}

	&_contactInfoItem {
		display: flex;
		margin-left: 0.2rem;
		margin-bottom: 1.5rem;

		& a {
			color: var(--color-white);
			text-decoration: none;
			@include transition {
				transition: color var(--trans-time--standard) ease;
			}

			&:hover,
			&:focus {
				color: var(--color-secondary);
			}
		}
	}

	&_contactInfoItemIcon {
		width: 1.8rem;
		margin-right: 1rem;

		& svg path {
			stroke: var(--color-white);
		}
	}

	&_contactInfoItemText {
		font-size: var(--font-size--sm);
		line-height: 2rem;
	}

	&_contactInfoLink {
		color: var(--color-white);
		text-decoration: none;
		color: var(--color-white);
		text-decoration: none;
		@include transition {
			transition: color var(--trans-time--standard) ease;
		}

		&:hover,
		&:focus {
			color: var(--color-secondary);
		}
	}

	&_contactInfoButtons {
		display: flex;
		flex-direction: column;
		gap: 1rem;
		margin-bottom: 7rem;

		@include mq("md") {
			margin-bottom: 0;
			align-self: flex-end;
		}
	}

	&_containerSoMe {
		display: flex;
		flex-direction: column;
		gap: 1.5rem;
		margin-top: 2rem;

		@include mq("md") {
			margin-top: initial;
		}
		@include mq("md-lg") {
			justify-self: flex-end;
		}
	}

	&_containerLegal {
		width: 100%;
		border-top: 1px solid rgba(255, 255, 255, 0.3);
		padding-top: 2rem;
		margin-top: 6rem;

		@include mq("md") {
			margin-top: 12rem;
		}

		& a {
			@include transition {
				transition: color var(--trans-time--standard) ease;
			}

			&:hover,
			&:focus {
				color: var(--color-secondary);
			}
		}
	}

	&_legal {
		display: flex;
		flex-direction: column;
		gap: 1.5rem;
		justify-content: center;
		margin: 0 auto;

		@include mq("sm") {
			flex-direction: row;
		}
	}

	&_legalItem a {
		color: var(--color-white);
		font-size: var(--font-size--sm);
		line-height: 2.4rem;
	}
}

.FooterDef_containerSoMe {
	@include mq("md") {
		justify-content: center;
	}
}

.FooterDef_SoMe {
	margin: 1rem 0;
	display: flex;
}
.FooterDef_SoMeHeading {
	margin-top: 0.5rem;
	margin-right: 1.2rem;
	font-weight: 700;
	font-size: var(--font-size--lg);
}

.FooterDef_SoMeLink {
	display: flex;
	align-items: center;
	text-decoration: none;
	color: var(--some-primary);
	margin-bottom: 1rem;
	margin-right: 1rem;

	circle {
		fill: var(--some-primary);
		stroke: none;
		@include transition {
			transition: fill var(--trans-time--standard) ease;
		}
		&:hover {
			fill: var(--color-white);
		}
	}

	path {
		fill: var(--some-secondary);
	}

	&:hover {
		.FooterDef_SoMeLinkText {
			border-bottom: 1px solid currentColor;
		}
		circle {
			fill: var(--color-white);
		}
	}
}

.FooterDef_SoMeLinkIcon {
	width: var(--icon-size--xl);
	height: var(--icon-size--xl);

	svg {
		width: 100%;
		height: 100%;
		overflow: visible;
	}
	path {
		fill: #fff;
		@include transition {
			transition: fill var(--trans-time--standard) ease;
		}
		.FooterDef_SoMeLink:hover & {
			fill: var(--color-primary);
		}
	}
	circle {
		fill: transparent;
		stroke: #fff;
		stroke-width: 0.2rem;
	}
}

.FooterDef_SoMeLink {
	color: var(--some-text-color);
}

.FooterDef_SoMeLinkText {
	margin-left: 1rem;
}

.FooterDef {
	:global(.RichText) {
		color: var(--color-white);

		a {
			color: white;
		}
	}
}
.FooterDef_contactInfoLink {
	font-size: var(--font-size--md);
}
.FooterDef_containerLegal {
	padding-left: var(--grid-gutter);
	padding-right: var(--grid-gutter);
}

// theme-tillidsportal custom style
:global(.theme-tillidsportal) {
	.FooterDef {
		@include mq("md") {
			padding-left: 9rem;
		}
	}
}

// theme-medlem custom style
:global(.theme-medlem) {
	.FooterDef {
		@include mq("lg") {
			padding: 12rem 0 2.4rem 18rem;
		}
	}
}
