@import "ui/styles/2-tools/tools.mixins.scss";

.CludoArrangementsResults {
	width: 100%;
	padding: 4rem 0;

	@include mq("md") {
		padding: 8rem 0;
	}
}
