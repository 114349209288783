/* ------------------------------------ *\
		settings.corners
\* ------------------------------------ */

:root {
	--corner-size--base: 0.5rem;

	--corner-size--xs: calc(var(--corner-size--base) * 0.6);
	--corner-size--sm: var(--corner-size--base);
	--corner-size--md: calc(var(--corner-size--base) * 3);
	--corner-size--lg: calc(var(--corner-size--base) * 5);

	--corner-circle: 50%;
}
