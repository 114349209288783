@import "ui/styles/2-tools/tools.media-query.scss";

.FooterCard {
	margin-bottom: 1rem;
	@include mq("md") {
		margin-bottom: 0;
	}
}

.FooterCard_heading {
	text-transform: uppercase;
	margin-bottom: 0.5em;
}

.FooterCard_text {
	font-size: var(--font-size--md);
	line-height: 24px;
	&___email {
		width: fit-content;
		color: var(--Email-primary);
		border-bottom: var(--border--sm) solid transparent;
		cursor: pointer;
		&:hover {
			border-bottom: var(--border--sm) solid currentColor;
		}
	}
}

.FooterCard_textbox {
	font-size: var(--font-size--sm);
	text-align: left;
}

.FooterCard_textbox a {
	text-decoration: none;
	color: var(--color-light);
}
