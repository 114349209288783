@import "ui/styles/2-tools/tools.media-query.scss";
@import "ui/styles/2-tools/tools.display-mixins.scss";
@import "ui/styles/2-tools/tools.behavior.scss";

.NavigationMember {
	&___primary {
	}
}

.NavigationMember_heading {
	@include hidden-absolute;
}

.NavigationMember_list {
	list-style: none;
	flex-direction: column;
	text-align: center;
	display: flex;

	@include mq("lg", max) {
		@media (max-height: 780px) {
			padding-bottom: 14rem;
		}
	}
}

.NavigationMember_item___service {
	position: static;
	right: 0;
	a {
		display: flex;
	}
	@include mq("lg") {
		flex-direction: row;
		position: absolute;
		right: var(--spacing--base);
	}
}

.NavigationMember_item {
	flex: 1 1;
	text-align: left;
	padding: 0;
	margin-bottom: 1rem;

	&___secondary {
		display: none;
		@include mq("lg") {
			display: block;
		}
	}
}
.NavigationMember_title {
	margin-left: 3rem;
	font-size: var(--font-size--lg);
	@include mq("lg") {
		font-size: 1.5rem;
		color: var(--color-primary);
		@include transition {
			transition: color var(--trans-time--standard) ease;
		}
		.NavigationMember_link:hover & {
			color: var(--color-secondary);
		}
		.NavigationMember_link.isActive & {
			color: var(--color-secondary);
		}
	}
}
.NavigationMember_link {
	color: currentColor;
	position: relative;
	text-decoration: none;
	align-items: center;
	font-size: var(--font-size--2xl);
	font-weight: var(--font-weight--semi-bold);
	display: inline-flex;
	padding: var(--spacing--xs) 0;
	max-width: fit-content;
	background: none;
	cursor: pointer;
	min-width: unset;
	background: unset;
	box-shadow: unset;
	@include transition {
		transition: color var(--trans-time--standard) ease;
	}
	&:after {
		@include transition {
			transition: width var(--trans-time--standard) ease;
		}
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		margin: auto;
		width: 0%;
		content: ".";
		color: transparent;
		background: var(--color-secondary);
		height: 1px;
	}
	&:focus {
		box-shadow: unset;
	}
	&.isActive {
		border-bottom: var(--border--sm) solid var(--color-secondary);
		pointer-events: none;
		color: var(--color-secondary);
		&:after {
			display: none;
		}
	}
	&:hover {
		box-shadow: unset;
		color: var(--color-secondary);
		path {
			@include transition {
				transition: stroke var(--trans-time--standard) ease;
			}
			stroke: var(--color-secondary);
		}
	}
	&:hover:after {
		width: 100%;
		background: var(--color-secondary);
	}

	@include mq("xs") {
		font-size: var(--font-size--xl);
	}

	@include mq("lg") {
		margin: 0;
		font-size: var(--font-size--lg);
		font-weight: var(--font-weight--bold);
		white-space: nowrap;
	}
	& > span {
		display: inline-flex;
	}
}

.NavigationMember_icon {
	position: absolute;
	left: 0;
	width: 18px;
	filter: brightness(0) invert(1);
	@include mq("lg") {
		width: var(--icon-size--md);
		height: var(--icon-size--md);
		filter: unset;
		@include transition {
			transition: opacity var(--trans-time--standard) ease;
		}
		.NavigationMember_link:hover & {
			opacity: 0;
		}
	}
}
.NavigationMember_iconHover {
	position: absolute;
	left: 0;
	width: 18px;
	filter: brightness(0) invert(1);
	@include mq("lg") {
		opacity: 0;
		width: var(--icon-size--md);
		height: var(--icon-size--md);
		filter: unset;
		@include transition {
			transition: opacity var(--trans-time--standard) ease;
		}
		.NavigationMember_link:hover & {
			opacity: 1;
		}
	}
	.NavigationMember_link.isActive & {
		opacity: 1;
	}
}

.Navigation_iconLoop {
	transform: rotate(90deg);
	width: var(--icon-size--xl);
	height: var(--icon-size--xl);
	position: relative;
	left: 0;
	margin-left: 1rem;
	@include transition {
		transition: left var(--trans-time--standard) ease;
	}
	:first-child {
		fill: transparent;
	}

	@include mq("lg") {
		width: var(--icon-size--3xl);
		height: var(--icon-size--3xl);
	}

	.ServiceNavigation_link:hover & {
		left: 0.3rem;
	}
}

.Navigation_iconLock {
	width: var(--icon-size--xl);
	height: var(--icon-size--xl);
	position: relative;
	left: 0;
	margin-left: 1rem;
	@include transition {
		transition: left var(--trans-time--standard) ease;
	}
	:first-child {
		fill: transparent;
	}

	@include mq("lg") {
		width: 2.5rem;
		height: 2.5rem;
	}

	.ServiceNavigation_link:hover & {
		left: 0.3rem;
	}
}

.NavigationMember_corpBrand {
	width: 5.6rem;
	height: 5.6rem;
	position: relative;
	top: 3rem;
	left: 1rem;
}
