@import "ui/styles/2-tools/tools.media-query.scss";
@import "ui/styles/2-tools/tools.display-mixins.scss";
@import "ui/styles/2-tools/tools.behavior.scss";

.Breadcrumb {
	list-style: none;
	color: var(--color-primary);
	margin: 1rem 0;
	padding-top: 2rem;
	padding-left: var(--grid-gutter);
	padding-right: var(--grid-gutter);

}
:global(.theme-tillidsportal) {
	.Breadcrumb {
		padding-top: 10rem;
		padding-left: 1rem;
		@include mq("md") {
			padding-top: 1rem;
			padding-left: 5rem;
		}
		
	}
}

:global(.theme-kongres) {
	.Breadcrumb {
		padding-top: 1rem;

		
	}
}
.Breadcrumb_dots {
	display: inline-block;

	&__bg {
		background-color: var(--color-gradient--8);
		padding: 0 5px;
	}

	&::after {
		content: "";
		vertical-align: middle;
		margin: 0 1rem;
		width: var(--icon-size--xs);
		height: var(--icon-size--xs);
		border-color: currentColor;
		border-style: solid;
		border-width: var(--border--sm) var(--border--sm) 0 0;
		transform: rotate(45deg);

		display: inline-block;
	}

	@include mq("md") {
		display: none;

	}
}

.Breadcrumb_item {
	display: inline-block;
	text-decoration: none;

	// Breadcrumb divider
	&:not(:last-child)::after {
		display: inline-block;
		content: "";
		vertical-align: middle;
		margin: 0 1rem;
		width: var(--icon-size--xs);
		height: var(--icon-size--xs);
		border-color: currentColor;
		border-style: solid;
		border-width: var(--border--sm) var(--border--sm) 0 0;
		transform: rotate(45deg);
	}

	&:not(:last-child) {
		display: none;

		@include mq("md") {
			display: inline-block;
		}
	}

	&:first-child {
		//margin-left: var(--spacing--xs);
		display: inline-block;
		.Breadcrumb_url {
			padding-left:0;
		}
	}

	&:last-child {
		margin-right: var(--spacing--xs);

		a {
			font-weight: var(--font-weight--bold);
		}
	}
}

.Breadcrumb_url {
	display: inline-block;
	font-weight: var(--font-weight--normal);
	color: var(--color-primary);
	padding: 0 var(--spacing--xs);
	text-decoration: none;

	&:hover,
	&:focus {
		color: var(--color-secondary);
		text-decoration: underline;
	}

	&___current {
		text-decoration: none;
		font-weight: var(--font-weight--semibold);
		padding: 0 var(--spacing--xs);

		&:hover,
		&:focus {
			color: var(--color-secondary);
		}
	}
}

.Breadcrumb_iconHome {
	position: relative;
	left: 0;
	margin-left: 1rem;
	float: left;

	@include transition {
		transition: left var(--trans-time--standard) ease;
	}

	:first-child {
		fill: transparent;
	}

	@include mq('lg') {
		width: 2.5rem;
		height: 2.5rem;
	}
}

.Breadcrumb[data-hidden="no"] .Breadcrumb_item {
	display: inline-block !important;
}

.Breadcrumb[data-hidden="no"] .Breadcrumb_dots {
	display: none !important;
}