@import "ui/styles/2-tools/tools.media-query.scss";
@import "ui/styles/2-tools/tools.typography-mixins.scss";
@import "ui/styles/2-tools/tools.grid-mixins.scss";
@import "ui/styles/2-tools/tools.mixins.scss";

.Contact {
	width: 100%;
	color: var(--text-primary);

	@include mq("md") {
		min-width: 40rem;
	}
}

.Contact_result {
	color: var(--color-primary);
}

.Contact_formTitle {
	color: var(--color-primary);
	font-weight: var(--font-weight--bold);
	font-size: var(--font-size--2xl);
	margin-bottom: var(--spacing--sm);
}

.Contact_form {
	position: relative;
	display: flex;
	flex-flow: column;
}

.Contact_selectWrapper {
	position: relative;
	margin: var(--spacing--2xs) 0;
	font-family: var(--font-secondary);
	font-weight: var(--font-weight--normal);
	font-size: var(--font-size--md);
	line-height: var(--line-height--xl);
	&:before {
		content: "";
		display: block;
		position: absolute;
		right: var(--spacing--base);
		top: 2.8rem;
		height: 1rem;
		width: 1rem;
		border: solid var(--color-primary);
		border-width: 0 0 var(--border--md) var(--border--md);
		background: transparent;
		pointer-events: none;
		transform: translateY(-66.7%) rotate(-45deg);
	}
}

.Contact_select {
	font-family: var(--font-secondary);
	font-weight: var(--font-weight--normal);
	font-size: var(--font-size--md);
	line-height: var(--line-height--xl);
	font-weight: var(--font-weight--bold);
	color: var(--color-primary);
	width: 100%;
	padding: var(--spacing--sm) var(--spacing--2xl) var(--spacing--sm) var(--spacing--base);
	border-radius: var(--corner-size--base);
	border: var(--border--sm) solid var(--color-primary);
	background: var(--color-light);
	appearance: none;
	cursor: pointer;
	outline: none;
	transition: border-color var(--trans-time--standard) ease;
	margin-bottom: var(--spacing--lg);
}

:global(.FooterDef) {
	.Contact {
		width: auto;
		min-width: auto;

		:global(.RichText) {
			color: #fff;
			a {
				color: #fff;
			}
		}
	}
}
