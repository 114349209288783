@import "ui/styles/2-tools/tools.behavior.scss";

.Pagination {
	display: flex;
	align-items: center;
	justify-content: center;
    margin: 4rem 1rem;
}

.Pagination__text {
	color: var(--color-primary);
	font-size: var(--font-size--sm);
	margin: 0 2rem;
	font-weight: var(--font-weight--bold);
}

.Pagination__buttonPrev,
.Pagination__buttonNext {
	color: var(--color-primary);
	margin: 0 3px;
	width: 2.8rem;
	height: 2.8rem;
	border: 0;
	border-radius: 50%;
	padding: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: transparent;	
	@include transition {
		transition: background-color var(--trans-time--standard) ease;
	}
	&:disabled {
		opacity: 0.15;
		cursor: default;
	}

	&:not(:disabled) {
		&:hover,
		&:focus {
			background-color: rgba(0,0,100, 0.07);
		}
	}
}

.m-simple-pagination--dark {
	.m-simple-pagination__icon {
		color: white;
	}
	.m-simple-pagination__text {
		color: white;
	}
	
}
