@import "ui/styles/2-tools/tools.media-query.scss";
@import "ui/styles/2-tools/tools.display-mixins.scss";
@import "ui/styles/2-tools/tools.behavior.scss";


.NavigationPortal{
	height: auto;
    overflow-y: auto;
	overflow-x: hidden;
}

.NavigationPortal_heading {
	@include hidden-absolute;
}
.NavigationPortal_listTitle{
	display: flex;
	color: var(--color-tertiary-lighter);
	font-size: var(--font-size--xs);
}
.NavigationPortal_list {
	list-style: none;
	padding-left: 0;
	padding-top: var(--spacing--lg);
}

.NavigationPortal_item___service{
		position: static;
		right: 0;
		a {
			display: flex;
		}
	@include mq("md") {
		flex-direction: row;		
		position: absolute;
		right: var(--spacing--base);
		
	}
}

.NavigationPortal_item {
	display: flex;
    align-items: center;
	text-align: left;	
	padding-left: 0!important;
	margin-bottom: var(--spacing--md);
}

.NavigationPortal_link {
	display: flex;
	color: currentColor;
	position: relative;
	text-decoration: none;
	align-items: center;
	white-space: nowrap; 
	font-size: var(--font-size--lg);		
	font-weight: var(--font-weight--normal);	
	max-width: fit-content;	
	cursor: pointer;	
	@include transition {
		transition: color var(--trans-time--standard) ease;
	}	

	&.isActive {
		color: var(--color-secondary);		
		pointer-events: none;
		&:after{			
			display: none;
		}
	}
	&:hover{
		color: var(--color-secondary)
	}
	&:hover:after{
		width: 100%;
	}

	
}
.NavigationPortal_title{
	margin-left: var(--spacing--md);
	font-weight: 500;
	@include mq('md') {
		
		header[aria-expanded="false"] &{
			display: none;	
		}
	}	
}



.NavigationPortal_chevron {
	width: var(--icon-size--md);
	height: var(--icon-size--md);
	position: relative;
	left: 1rem;
	
	@include transition {
		transition: left var(--trans-time--standard) ease;
	}	

	.NavigationPortal_link:hover &{
		left: 1.3rem;
		
	}
	@include mq('md') {
		header[aria-expanded="false"] &{
			display: none;	
		}
	}	
}

.NavigationPortal_icon{
	width: 3rem;
	height: 3rem;	
	position: relative;
	left: 0;
	color: transparent;
	filter: brightness(0) invert(1);
	@include transition {
		transition: left var(--trans-time--standard) ease;
	}	
	@include transition {
		transition: filter var(--trans-time--standard) ease;
	}	
	&:hover{
		filter: brightness(0) invert(1);
	}
	.NavigationPortal_link.isActive &{
		filter: unset;
	}
	.NavigationPortal_link:hover &{
		filter: unset;		
	}
		
}