@import "ui/styles/2-tools/tools.aspect-ratio.scss";
@import "ui/styles/2-tools/tools.media-query.scss";
@import "ui/styles/2-tools/tools.typography-mixins.scss";
@import "ui/styles/2-tools/tools.space.scss";
.FooterModule_inner{
    margin: auto;	
	height: 100%;	
	@include module-seperator;
}
.FooterModule {	
	position: relative;
	background-color: var(--module-bg-footer);
	width: 100%;	
	border-bottom: 15px solid var(--color-secondary);
	position: relative;
    overflow: hidden;


	/**
	* Show the card using flexbox rows
	* AKA the Flag Object or Media Object
	* https://philipwalton.github.io/solved-by-flexbox/demos/media-object/
	*/
	@include mq("sm") {		
		&___row {
			display: flex;
			flex-direction: row;
		}

		&___rowReverse {
			display: flex;
			flex-direction: row-reverse;
		}
	}

	&___bordered {
		border: var(--border--sm) solid var(--color-gradient--10);
	}

	&___padded {
		padding: var(--spacing--md);
	}

	&___padded-x2 {
		padding: var(--spacing--xl);
	}
}
.FooterModule_pattern{
	display: none;
	@include mq("md") {
		display: block;		
		position: absolute;
		bottom: -3rem;
		width: auto;
		height: 40rem;
		left: 1rem;
	}
}
.FooterModule_pattern_secondary{
	display: none;
	@include mq("md") {
		display: block;
		position: absolute;
		width: 18.8rem;
		bottom: 0;
		height: 87rem;
		right: 0;
	}
}
.FooterModule_textbox {
	text-align: center;
	/**
	* Show the card using flexbox rows
	* AKA the Flag Object or Media Object
	* https://philipwalton.github.io/solved-by-flexbox/demos/media-object/
	*/
	@include mq("sm") {	
		width: 40%;	
    	margin: auto;
		.FooterModule___row &,
		.FooterModule___rowReverse & {
			width: 66.6666666666%;
		}
		.FooterModule___row & {
			padding-left: calc(var(--grid-gutter) * 2);
		}

		.FooterModule___rowReverse & {
			padding-right: calc(var(--grid-gutter) * 2);
		}
	}
}

.FooterModule_kicker {
	font-size: var(--font-size--sm);
	text-transform: uppercase;
	margin-bottom: 0.5em;
	color: var(--color-primary);
	font-weight: var(--font-weight--bold);
}

.FooterModule_heading {
	margin-bottom: 0.5em;
}
.FooterModule_title{
	@include heading--lg();
	margin-bottom: var(--spacing--sm);
	color: var(--color-primary);
}
.FooterModule_text{
	@include paragraph();
	margin-bottom: var(--spacing--lg);
	color: var(--color-primary);
}
.FooterModule_text p {
	margin-bottom: 1em;

	&:last-of-type {
		margin-bottom: 0;
	}
}

.FooterModule_action {
	margin-top: 1em;
}

.FooterModule_link {
	display: block;
	color: inherit;
	text-decoration: none;
	margin: auto;
	max-width: 500px!important;
}

.FooterModule_media {
	display: block;
	margin-bottom: 0.5em;

	/**
	* Show the card using flexbox rows
	* AKA the Flag Object or Media Object
	* https://philipwalton.github.io/solved-by-flexbox/demos/media-object/
	*/
	@include mq("sm") {
		width: 50%;  
		margin: auto;
		.FooterModule___row &,
		.FooterModule___rowReverse & {
			width: 33.3333333333%;
		}
	}
}

.FooterModule_mediabox {	
	@include aspect-ratio(16, 9);
}

.FooterModule_date {
	font-size: var(--font-size--sm);
	margin-bottom: 0.5em;
}
