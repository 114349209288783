@import "ui/styles/2-tools/tools.media-query.scss";
@import "ui/styles/2-tools/tools.behavior.scss";

.HeaderPortal {
	position: fixed;
	z-index: 2;
	width: 100%;
	@include transition {
		transition: max-width var(--trans-time--standard) ease;
	}
	@include mq("md") {
		max-width: 51rem;
		// collapsed nav
		&[aria-expanded="false"] {
			max-width: 9rem;
			// Hide the text on SVG logo
			svg g:nth-child(2) {
				display: none;
			}
		}
	}
}
.HeaderPortal_container {
	position: relative;
	top: 0;
	font-size: var(--font-size--sm);
	color: var(--color-light);
	padding: var(--spacing--sm) var(--spacing--xs);
	z-index: var(--z-index--HeaderPortal);
	background-color: var(--color-light);
	margin: 0 !important;
	@include mq("md") {
		padding: var(--spacing--lg);
		background-color: var(--color-primary);
		max-width: 51rem;
		height: 100vh;
		overflow: hidden;
	}
	@include transition {
		transition: max-width var(--trans-time--standard) ease;
	}
	&[aria-expanded="true"] {
		background-color: var(--color-primary);
	}
}

.HeaderPortal_grid {
	display: flex;
	justify-content: space-between;
	@include mq("md") {
		display: block;
		width: 45rem;
	}
}

.HeaderPortal_logo {
	min-width: fit-content;
	padding-left: 1rem;
	@include mq("md") {
		padding-left: 0;
		svg g {
			fill: var(--color-light) !important;
		}
	}
	svg g {
		fill: var(--color-primary);
		[aria-expanded="true"] & {
			fill: var(--color-light);
		}
	}
}
.HeaderPortal_overlay {
	@include mq("md") {
		// expanded nav
		&[aria-expanded="true"] {
			position: absolute;
			width: 100vw;
			height: 100vh;
			background: var(--color-primary);
			opacity: 0.1;
		}
	}
}
.HeaderPortal_navButton {
	display: none;
	@include mq("md") {
		display: block;
		position: absolute;
		right: -1.5rem;
		top: 5.5rem;
		width: var(--icon-size--lg);
		height: var(--icon-size--lg);
		background: var(--color-light);
		box-shadow: 0px 0px 10px var(--transparent-color--primary);
		border-radius: var(--corner-circle);
		padding: 0;
		min-width: unset;
		z-index: 2;
		transform: rotate(0.5turn);
		cursor: pointer;

		@include transition {
			transition: transform var(--trans-time--longer) ease;
		}
		// collapsed nav
		header[aria-expanded="false"] & {
			transform: rotate(1turn);
		}
	}
}

.HeaderPortal_expandIcon {
	width: var(--icon-size--xl);
	height: var(--icon-size--xl);
	svg {
		fill: var(--color-dark);
		@include transition {
			transition: left var(--trans-time--standard) ease;
		}
		&:hover {
			fill: var(--color-primary);
		}
	}
	@include transition {
		transition: left var(--trans-time--standard) ease;
	}

	@include mq("md") {
		position: relative;
		left: 0.5rem;
		width: var(--icon-size--md);
		height: var(--icon-size--md);
	}

	.HeaderPortal_link:hover & {
		left: 1.3rem;
		fill: var(--color-secondary);
	}
}

.HeaderPortal_navigation {
	text-align: right;
	flex: 1;
	overflow: hidden;
	@include mq("md", max) {
		order: 2;
		flex: initial;
	}
}

.HeaderPortal_navToggle {
	position: absolute;
	top: 4rem;
	right: 1.5rem;
	font-weight: var(--font-weight--bold);
	color: var(--color-light);
}
.HeaderPortal_nav {
	display: block;
	position: absolute;
	width: 100%;
	height: calc(100vh - #{87px});
	top: 8.7rem;
	left: -100%;
	margin: auto;
	padding: var(--spacing--base);
	background-color: var(--color-primary);
	z-index: 2;
	@include transition {
		transition: left var(--trans-time--standard) ease;
	}
	@include mq("md") {
		padding: 0;
	}

	@include mq("md") {
		background-color: transparent;
		height: auto;
		position: static;
	}
	&___active {
		left: 0;
	}
}
.HeaderPortal_navToggleDesktop {
	display: block;
	cursor: pointer;
	width: 2rem;
	height: 2rem;
}
.HeaderPortal_navToggle {
	@include mq("md") {
		display: none;
	}
}
.HeaderPortal_navControllerDesktop {
	display: none;

	&:checked ~ .HeaderPortal_nav {
		display: block;
	}

	&:checked ~ .HeaderPortal_navToggle {
		.HeaderPortal_iconBar {
			&:first-child {
				opacity: 0;
			}
			&:last-child {
				opacity: 0;
			}
			&:nth-child(2) {
				transform: rotate(45deg) translateY(-50%);
				opacity: 1;
			}
			&:nth-child(3) {
				transform: rotate(-45deg) translateY(-50%);
				opacity: 1;
			}
		}
	}
}

.HeaderPortal_navContainer {
	background-color: var(--color-primary);
	padding: 0 !important;
	height: 77vh;
	display: flex;
	flex-direction: column;

	@include mq("md") {
		height: 70vh;
	}
	@media (orientation: landscape) and (max-width: 998px) {
		height: 60vh;
	}
}
.HeaderPortal_navController {
	display: none;

	&:checked ~ .HeaderPortal_nav {
		display: block;
	}

	&:checked ~ .HeaderPortal_navToggle {
		.HeaderPortal_iconBar {
			[aria-expanded="true"] & {
				&:first-child {
					opacity: 0;
				}
				&:last-child {
					opacity: 0;
				}
				&:nth-child(2) {
					transform: rotate(45deg) translateY(-50%);
					opacity: 1;
				}
				&:nth-child(3) {
					transform: rotate(-45deg) translateY(-50%);
					opacity: 1;
				}
			}
		}
	}
}

.HeaderPortal_icon {
	width: 2em;
	height: 2em;
	display: inline-block;
	position: relative;
	&[aria-expanded="false"] {
		color: var(--color-primary);
	}
}

.HeaderPortal_iconBar {
	display: block;
	width: 100%;
	height: 0px;
	position: absolute;
	background: currentColor;
	border: 0.8px solid currentColor;
	opacity: 0;
	top: 50%;
	transform: rotate(0) translateY(-50%);

	&:first-child {
		margin-top: -0.5em;
		opacity: 1;
	}

	&:last-child {
		margin-top: 0.2em;
		opacity: 1;
	}
}

.HeaderPortal_cart {
	@include mq("md", max) {
		order: 1;
		margin-right: var(--spacing--sm);
	}
}

.HeaderPortal_corpBrand {
	width: 3.2rem;
	height: 3.2rem;
	position: relative;
	left: -5.3rem;
	@include mq("md") {
		position: absolute;
		bottom: 9.5rem;
		left: 2.6rem;
		z-index: 1;
	}
	@media (orientation: landscape) and (max-width: 998px) {
		display: none;
	}
}

.HeaderPortal_service {
	display: flex;
	align-items: center;
	padding: 0;
	min-width: unset;
	width: 90%;
	// padding-top: 1.5rem;
	padding-top: 8rem;
	border-top: var(--border--sm) solid var(--border-color);
	background-color: var(--color-primary);
	margin-top: auto;
	@include mq("md") {
		position: absolute;
		bottom: 3rem;
		width: 45rem;
		// expanded nav
		header[aria-expanded="false"] & {
			border-top: 0;
		}
	}
	@media (orientation: landscape) and (max-width: 998px) {
		position: static;
		padding-bottom: 2.5rem;
	}
}
.HeaderPortal_serviceList {
	padding: 1rem 0 2rem 0;
}
.HeaderPortal_serviceLink {
	border: none;
	padding: 0;
	display: flex;
	gap: var(--spacing--md);
	&:hover {
		color: var(--color-secondary);
		text-decoration: none;
	}
	background: none;
}
.HeaderPortal_serviceIcon {
	color: transparent;
	width: var(--icon-size--lg);
	height: var(--icon-size--lg);
	@include mq("md") {
		width: 2.6rem;
		height: 2.6rem;
	}
	svg path {
		@include transition {
			transition: stroke var(--trans-time--standard) ease;
		}
		.HeaderPortal_serviceLink:hover & {
			stroke: var(--color-secondary);
		}
	}
}
.HeaderPortal_serviceList {
	list-style-type: none;
	padding-left: 0;
}
.HeaderPortal_serviceItem {
	padding: 0 !important;
}
.HeaderPortal_search {
	display: flex; // When search is ready then change this property to display: flex
	background: transparent;
	cursor: pointer;
	border: 0;
	padding: 0 !important;
	position: fixed;
	right: 6.5rem;
	top: 3.5rem;
	z-index: 9;
	width: auto;
	height: auto;
	color: white;
	@include mq("md") {
		background-color: var(--color-primary);
		width: 200px;
		height: 55px;
		border: var(--border--md) solid var(--color-border--light);
		border-radius: 0.7rem;
		padding: 1rem !important;
	}
	@include transition {
		transition: background-color var(--trans-time--standard) ease;
	}
	&:hover {
		@include mq("md") {
			border: var(--border--md) solid var(--color-primary);
			background-color: var(--button-bg-secondary-hover);
			color: var(--button-text-primary);
		}
	}
}
.HeaderPortal_searchLink {
	display: flex;
	width: 100%;
	justify-content: center;
	align-items: center;
}
.HeaderPortal_iconLoop {
	transform: rotate(90deg);
	width: var(--icon-size--3xl);
	height: var(--icon-size--3xl);
	position: relative;
	left: 0;
	padding-bottom: 0.7rem;
	@include transition {
		transition: stroke var(--trans-time--standard) ease;
	}
	svg {
		fill: transparent;

		path {
			stroke: var(--color-primary);
			@include mq("md") {
				fill: transparent;
				stroke: white;
			}
			.HeaderPortal_search:hover & {
				stroke: var(--color-primary);
			}
		}
	}
}
.HeaderPortal_title {
	display: none;
	@include mq("md") {
		display: block;
		padding-right: 0.7rem;
	}
}
.HeaderPortal_search {
	display: flex; // When search is ready then change this property to display: flex
	background: transparent;
	cursor: pointer;
	border: 0;
	padding: 0 !important;
	position: fixed;
	right: 6.5rem;
	top: 3.5rem;
	z-index: 9;
	width: auto;
	height: auto;
	color: white;
	@include mq("md") {
		background-color: var(--color-primary);
		width: 200px;
		height: 55px;
		border: var(--border--md) solid var(--color-border--light);
		border-radius: 0.7rem;
		padding: 1rem !important;
	}
	@include transition {
		transition: background-color var(--trans-time--standard) ease;
	}
	&:hover {
		@include mq("md") {
			border: var(--border--md) solid var(--color-primary);
			background-color: var(--button-bg-secondary-hover);
			color: var(--button-text-primary);
		}
	}
}
.HeaderPortal_searchLink {
	display: flex;
	width: 100%;
	justify-content: center;
	align-items: center;
}
.HeaderPortal_iconLoop {
	transform: rotate(90deg);
	width: var(--icon-size--3xl);
	height: var(--icon-size--3xl);
	position: relative;
	left: 0;
	padding-bottom: 0.7rem;
	@include transition {
		transition: stroke var(--trans-time--standard) ease;
	}
	svg {
		fill: transparent;

		path {
			stroke: var(--color-primary);
			@include mq("md") {
				fill: transparent;
				stroke: white;
			}
			.HeaderPortal_search:hover & {
				stroke: var(--color-primary);
			}
		}
	}
}
.HeaderPortal_title {
	display: none;
	@include mq("md") {
		display: block;
		padding-right: 0.7rem;
	}
}
.HeaderPortal_serviceText {
	color: var(--color-light);
	font-size: var(--font-size--lg);
	font-weight: var(--font-weight--normal);

	@include transition {
		transition: color var(--trans-time--standard) ease;
	}
	.HeaderPortal_serviceLink:hover & {
		color: var(--color-secondary);
	}
	@include mq("md") {
		// expanded nav
		header[aria-expanded="false"] & {
			display: none;
		}
	}
}

.HeaderPortal_profileCardWrapper {
	height: 196px;
	@media (orientation: landscape) and (max-width: 998px) {
		display: none;
	}
}
