@import "ui/styles/2-tools/tools.media-query.scss";
@import "ui/styles/2-tools/tools.typography-mixins.scss";
@import "ui/styles/2-tools/tools.mixins.scss";
@import "ui/styles/2-tools/tools.behavior.scss";

.SearchCludo {
	opacity: 0;
	[aria-expanded="true"] & {
		align-items: flex-end;
		background: var(--color-primary);
		opacity: 1;
		min-height: 30rem;
		width: 100%;
		padding: 0 5px 80px 30px !important;
		box-shadow: 0px 0px 20px 2px rgb(255 255 255 / 50%);
		animation: fadeInDown 500ms ease-in-out;
		z-index: 10;
		@include mq("lg") {
			min-height: 35rem;
		}
		@keyframes fadeInDown {
			0% {
				opacity: 0;
				transform: translateY(-10px);
			}
			100% {
				opacity: 1;
				transform: translateY(0);
			}
		}
	}
	&.def {
		display: flex;
		position: absolute;
		top: 0;
		left: 0;
	}

	&.member {
		display: flex;
		margin: auto;
		width: 50%;

		[aria-expanded="true"] & {
			opacity: 1;
			position: absolute;
			top: 0;
			width: 100%;
		}
		@include mq("lg") {
			justify-content: center;
			position: relative;
			top: 7rem;
			right: -10rem;
			opacity: 1;
			padding-right: 0;
			padding-left: 0;
		}
		.SearchCludo_logo {
			@include mq("lg") {
				display: none;
			}
		}
	}
	&.portal {
		color: white;
		display: flex;
		position: fixed;
		top: 0;
		left: 0;
	}
}
.SearchCludo_logo {
	display: inline-block;
	position: absolute;
	top: 30px;
	left: 30px;
}
.SearchCludo_inputWrapper {
	display: flex;
	width: 100%;
	max-width: 1240px;
	margin: 0 auto;
}
.SearchCludo_input {
	width: 100%;
	min-height: 50px;
	padding-bottom: 1rem;
	background: var(--color-primary);
	border: 0;
	border-bottom: 1px solid #cccce0;
	font-size: var(--font-size--lg);

	&:focus-visible {
		outline: none;
	}

	[aria-expanded="true"] & {
		width: 100vw;
	}
}
.SearchCludo_input::placeholder {
	font-style: italic;
	color: #cccce0;
}

.SearchCludo_iconLoop {
	width: 30px;
	height: 30px;
	position: relative;
	// right: 3rem;
	top: 0.5rem;
	svg {
		width: auto;
		height: auto;
		fill: transparent;
		transform: rotate(90deg);
	}
	path {
		stroke: #cccce0;
	}
}
.SearchCludo_iconBar {
	display: block;
	width: 100%;
	height: 0px;
	position: absolute;
	background: currentColor;
	border: 0.8px solid currentColor;
	opacity: 0;
	top: 50%;
	transform: rotate(0) translateY(-50%);

	&:first-child {
		margin-top: -0.5em;
		opacity: 1;
	}

	&:last-child {
		margin-top: 0.5em;
		opacity: 1;
	}
}
.SearchCludo_iconClose {
	display: none;
	cursor: pointer;
	.def & {
		@include mq("lg") {
			display: block;
		}
	}
	.member & {
		@include mq("lg") {
			display: none;
		}
	}
	.portal & {
		@include mq("lg") {
			display: block;
		}
	}
	[aria-expanded="true"] & {
		width: 28px;
		height: 28px;
		display: inline-block;
		position: absolute;
		top: 30px;
		right: 30px;
		.SearchCludo_iconBar {
			&:first-child {
				opacity: 0;
			}
			&:last-child {
				opacity: 0;
			}
			&:nth-child(2) {
				transform: rotate(45deg) translateY(-50%);
				opacity: 1;
			}
			&:nth-child(3) {
				transform: rotate(-45deg) translateY(-50%);
				opacity: 1;
			}
		}
	}
}
.SearchCludo_iconCloseText {
	display: none;
	[aria-expanded="true"] & {
		display: block;
		font-size: 1.4rem;
		position: absolute;
		top: 58px;
		right: 33px;
	}
}
.SearchCludo_button {
	position: relative;
	right: 3rem;
	background: transparent;
    border: 0;
}
