@import "ui/styles/2-tools/tools.typography-mixins.scss";
@import "ui/styles/2-tools/tools.behavior.scss";
@import "ui/styles/2-tools/tools.space.scss";

.NewsList {
	@include module-seperator;
	padding-top: 2rem;

	@include mq("md") {
		margin-top: 4rem;
		padding-top: 4rem;
	}
}

.NewsList_pattern {
	display: none;

	@include mq("md") {
		display: block;
		position: absolute;
		width: 13rem;
		height: 40rem;
		right: 0;
		top: 8rem;
	}
}

.NewsList_linkButton {
	margin-top: 3rem;

	@include mq("md") {
		margin-top: 6rem;
	}
}

.NewsList_linkButtonWide {
	width: 100%;

	@include mq("sm") {
		width: auto;
	}
}

.NewsList_newsList {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
}

.NewsList_heading {
	@include heading--xl;
	color: var(--color-primary);
}

.NewsList_hits {
	display: inline-block;
	color: var(--color-primary);
	font-size: var(--font-size--xl);
	margin-top: 3.2rem;
	margin-bottom: 2.4rem;

	@include mq("md") {
		margin-top: 8rem;
		margin-bottom: 3.2rem;
	}
}
