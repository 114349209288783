@import "ui/styles/2-tools/tools.media-query.scss";
@import "ui/styles/2-tools/tools.display-mixins.scss";
@import "ui/styles/2-tools/tools.behavior.scss";

.ServiceNavigation {	
	@include mq("md") {
		display: inline-block;	
		position: absolute;
		top: 0;
		right: 0;	
	}
}

.ServiceNavigation_heading {
	@include hidden-absolute;
}

.ServiceNavigation_list {
	list-style: none;
	flex-direction: column;
	text-align: center;
	display: flex;


	@include mq("md") {
		flex-direction: row;	
	}
}

.ServiceNavigation_item___service{
		position: static;
		right: 0;
		a {
			display: flex;
		}
	@include mq("md") {
		flex-direction: row;		
		position: absolute;
		right: var(--spacing--base);
		
	}
}

.ServiceNavigation_item {
	text-align: left;
	@include mq("md") {
		text-align: center;
	}
}

.ServiceNavigation_link {
	display: flex;
	color: currentColor;
	position: relative;
	text-decoration: none;
	align-items: center;
	white-space: nowrap; 
	font-size: var(--font-size--2xl);
	font-weight: var(--font-weight--bold);	
	padding: var(--spacing--xs) 0;	
	max-width: fit-content;
	@include mq("md") {
		height: 4rem;
	}
	@include transition {
		transition: color var(--trans-time--standard) ease;
	}
	cursor: pointer;	
	&:after{
		@include transition {
			transition: width var(--trans-time--standard) ease;
		}
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		margin: auto;
		width: 0%;
		content: '.';
		color: transparent;
		background: var(--color-light);
		height: 1px;
	}

	&.isActive {
		color: var(--color-light);
		border-bottom: var(--border--sm) solid var(--color-border--light);
		pointer-events: none;
		&:after{			
			display: none;
		}
	}
	&:hover{
		color: var(--color-secondary);
	}	
	&:hover:after{
		width: 100%;
		background-color: var(--color-secondary);;
	}	
	

	@include mq("xs") {
		font-size: var(--font-size--3xl);
	}

	@include mq("md") {
		margin: 0;
		font-size: var(--font-size--xs);
		font-weight: var(--font-weight--semibold);
		padding: 1em 0;
	}
}

.ServiceNavigation_icon {
	width: var(--icon-size--xl);
	height: var(--icon-size--xl);	
	position: relative;
	left: 0;
	@include transition {
		transition: left var(--trans-time--standard) ease;
	}
	:first-child {
		fill: var(--navigation-chevron);
	}

	@include mq('md') {
		width: var(--icon-size--md);
		height: var(--icon-size--md);
	}

	.ServiceNavigation_link:hover &{
		left: .3rem;
	}
}

.ServiceNavigation_iconMember{
	
}
// theme-tillidsportal custome style
:global(.theme-def){
	.ServiceNavigation{		
		position: static;	
		@include mq('lg') {
			margin-bottom: 3rem;	
		}		
	}	
	.ServiceNavigation_icon{
		display: none;
	}
	.ServiceNavigation_item{
		padding: 0;
		@include mq('lg') {
			padding-right: 2rem;
			padding-left: 2rem;
		}
	}
	.ServiceNavigation_link{
		font-size: 1.6rem;
		font-weight: normal;
		@include mq('lg') {			
			font-size: 1.5rem;
		}
	}
	.ServiceNavigation_list{
		flex-direction: column;
		@include mq('lg') {
			flex-direction: row;
		}
	}
}

// theme-tillidsportal custome style
:global(.theme-medlem){
	.ServiceNavigation{		
		position: static;		
		border-top: 1px solid #8281b1;
		padding-top: 2rem;
		
		@include mq('lg') {
			padding-top: 0;
			border-top: 0;
			margin-bottom: 2rem;	
		}		
	}	
	.ServiceNavigation_icon{
		display: none;
	}
	.ServiceNavigation_item{
		padding: 0;
		flex: none;
		&:first-child{
			.ServiceNavigation_link{
				text-align: left;
			}
			
		}
		&:nth-child(1){
			@include mq('lg') {
				padding-right: 6rem;
			}
			
		}
		&:nth-of-type(2) {
			justify-content: space-between;
			width: 174px;
			align-items: flex-start;
			display: flex;			
			a{
				width: auto;
				text-align: left;
			}
		}
		@include mq('lg') {
			padding-right: 2rem;
			padding-left: 2rem;
		}
	}
	.ServiceNavigation_list{
		justify-content: space-between;
	}
	.ServiceNavigation_link{
		display: inline-flex;		
		max-width: unset;
		width: 100%;
		text-align: right;
		font-size: 1.6rem;
		padding: 0.2em 0;
		font-weight: normal;
		height: 2.5rem;
		@include mq('lg') {
			font-size: 1.5rem;
		}
		&:hover{
			color: var(--color-light)
		}
		&:hover:after{
			width: 100%;
			background-color: var(--color-light);;
		}	
		img{
			margin-right: 1.5rem;
		}
	}
	.ServiceNavigation_list{
		flex-direction: row;		
	}
}