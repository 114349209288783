@import "ui/styles/2-tools/tools.media-query.scss";
@import "ui/styles/2-tools/tools.display-mixins.scss";
@import "ui/styles/2-tools/tools.typography-mixins.scss";

.ProfileCard {
	background-color: var(--color-primary);
	color: var(--color-light);
	text-align: center;
    padding: var(--spacing--sm) var(--spacing--lg) var(--spacing--lg) var(--spacing--lg);
	border-bottom: var(--border--sm) solid var(--border-color);
	margin-bottom: 4rem;
	@include mq('md') {
		padding: var(--spacing--2xl) var(--spacing--lg) var(--spacing--lg) 0;
		header[aria-expanded="false"] &{
			opacity: 0;	
			visibility: hidden;		
		}
	}		

}
.ProfileCard_name {
	font-size:  var(--font-size--lg);	
	line-height: var(--line-height--md);
	text-transform: uppercase;
	margin-bottom: var(--spacing--xs);
}
.ProfileCard_title {
	font-size:  var(--font-size--md);	
	color: var(--color-tertiary-lighter);
	line-height: var(--line-height--md);
	margin-bottom: var(--spacing--xs);
	opacity: 0.75;	
}
.ProfileCard_position {
	font-size:  var(--font-size--md);
}
