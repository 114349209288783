@import "ui/styles/2-tools/tools.media-query.scss";
@import "ui/styles/2-tools/tools.typography-mixins.scss";
@import "ui/styles/2-tools/tools.grid-mixins.scss";
@import "ui/styles/2-tools/tools.space.scss";
@import "ui/styles/2-tools/tools.mixins.scss";

.LatestNewsModule {
	width: 100%;
	color: var(--text-primary);
	background-color: var(--module-bg-primary);
	@include full-width-bg(#000064);
	@include module-seperator;
}

.LatestNewsModule_inner {
	width: 100%;
	height: 100%;
}

.LatestNewsModule_container {
	display: block;
	@include mq("md") {
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
	}
}

.LatestNewsModule_contentWrapper {
	// margin-left: auto;
	// margin-right: auto;
	// max-width: none;
	width: 100%;
	text-align: start;
}

.LatestNewsModule_heading {
	@include heading--lg();
	width: 100%;
	margin-bottom: 2rem;
	@include mq("sm") {
		margin-bottom: 3rem;
	}
}

.LatestNewsModule_buttonWrapper {
	margin: 0 auto;
	flex-basis: 100%;
	width: 100%;
	display: inline-block;
	margin-bottom: var(--spacing--base);
	margin-top: 7rem;
	display: flex;
	justify-content: center;
	.LatestNewsModule_button {
		width: 100%;
	}

	@include mq("md") {
		width: auto;
	}
}
