.SearchField {
	&.hasError {
		color: var(--color-error);
	}

	&.isDisabled {
		color: var(--color-gradient--5);
	}
}

.SearchField_wrapper {
	display: flex;
	margin-bottom: 0.5em;
}

.SearchField_input {
	margin: 0 var(--spacing--xs) 0 0;
	background: var(--color-gradient--5);
	-webkit-appearance: none;

	input {
		margin: 0;
	}

	&::placeholder {
		color: currentColor;
	}
}
