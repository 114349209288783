@import "ui/styles/2-tools/tools.media-query.scss";
@import "ui/styles/2-tools/tools.aspect-ratio.scss";
@import "ui/styles/2-tools/tools.behavior.scss";

.ContentCard_link {
	display: block;
	color: var(--color-primary);
	text-decoration: none;
}

.ContentCard_media {
	display: block;
	margin-bottom: var(--spacing--sm);

	/**
	* Show the card using flexbox rows
	* AKA the Flag Object or Media Object
	* https://philipwalton.github.io/solved-by-flexbox/demos/media-object/
	*/
	@include mq("sm") {
		.ContentCard___row &,
		.ContentCard___rowReverse & {
			width: 50%;
		}
	}
}

.ContentCard_mediabox {
	@include aspect-ratio(4, 3);
	border-radius: 0 var(--corner-size--md) 0 0;
}

.ContentCard_picture {
	@include transition {
		transition: transform var(--trans-time--standard) ease;
	}
	.ContentCard_link:hover &,
	.ContentCard_link:focus & {
		transform: scale(1.05);
	}
}
.ContentCard_text {
	margin-bottom: var(--spacing--xs);
	font-weight: normal;
}

// Adding style to toggle class implemented on the Slider component
:global(.Slider_wrapper___revert) {
	.ContentCard_text {
		color: var(--color-light);
		display: -webkit-box;
		-webkit-line-clamp: 4;
		-webkit-box-orient: vertical;  
		overflow: hidden;
	}
	.ContentCard_textbox {
		border: 1px solid var(--color-primary);
		border-radius: 0 var(--corner-size--md) 0 0;
		background-color: var(--color-primary);
		color: var(--color-light);
		display: flex;
		flex-direction: column;
		height: 150px;
		justify-content: flex-end;
		padding: 2rem;
		@include transition {
			transition: background-color var(--trans-time--standard) ease;
		}
		&:hover {
			background-color: var(--color-light);
			.ContentCard_text {
				color: var(--color-primary);
			}
			.ContentCard_heading {
				color: var(--color-primary);
			}
		}
	}
}
