@import "ui/styles/2-tools/tools.mixins";
@import "ui/styles/2-tools/tools.behavior.scss";

.AccordionItem_triggerWrapper:not(:first-child),
.AccordionItem_panel:not([hidden]) {
	border-top: 0;
}

.AccordionItem_trigger {
	background: none;
	display: block;
	font-size: var(--font-size--xl);
	margin: 0;
	position: relative;
	text-align: left;
	width: 100%;
	outline: none;
	border-style: none;
	padding: 0;

	@include transition {
		transition: background var(--trans-time--standard) ease;
	}

	&:focus:not([aria-expanded="true"]),
	&:hover:not([aria-expanded="true"]) {
		background: var(--accordian-hover);
	}
}

.AccordionItem_trigger:focus .AccordionItem_icon,
.AccordionItem_trigger:hover .AccordionItem_icon {
	border-color: var(--color-primary);
	[aria-expanded="true"] & {
		fill: var(--color-light);
	}
}

.AccordionItem button::-moz-focus-inner {
	border: 0;
}
.AccordionItem_contentWrapper {
	display: block;

	@include mq("md") {
		display: flex;
		justify-content: space-between;
	}
}
.AccordionItem_textWrapperSingle {
	width: 100%;
}
.AccordionItem_textWrapper {
	@include mq("md") {
		padding-right: 2rem;
		width: 65%;
	}
}
.AccordionItem_imageWrapper {
	margin-top: 2rem;
	@include mq("md") {
		width: 35%;
	}
}
.AccordionItem_mediabox {
	@include aspect-ratio(4, 3);
	border-radius: var(--corner-size--md) 0 0 0;
	margin-bottom: 2rem;
}
.AccordionItem_imageName {
	display: inline-block;
	font-weight: var(--font-weight--bold);
	width: 100%;
}
.AccordionItem_imageTitle {
	display: inline-block;
	width: 100%;
}
.AccordionCalendarItem_date {
	display: inline-block;
	font-size: var(--font-size--md);
	font-weight: var(--font-weight--normal);
	width: 100%;
	margin-bottom: 1rem;
}
.AccordionItem_title {
	display: block;
	pointer-events: none;
	padding: calc(var(--spacing--lg) + 5px) 1rem;
	line-height: var(--line-height--md);
	color: currentColor;
	max-width: 85%;
	font-size: var(--font-size--xs);
	@include mq("md") {
		font-size: var(--font-size--xl);
		padding: calc(var(--spacing--lg) + 5px);
	}
}

#accordianIconHorizontal {
	fill: green;
}
.AccordionItem_icon {
	pointer-events: none;
	position: absolute;
	right: 1rem;
	top: 50%;
	transform: translateY(-60%);
	@include mq("md") {
		right: 2em;
	}
}
.AccordionItem_triggerWrapper {
	background-color: var(--color-light);
	border-bottom: 1px solid var(--color-tertiary-blue);
	&:not(:first-child) {
		border: 1px solid var(--color-tertiary-blue);
	}
	&[aria-expanded="true"] {
		color: var(--color-light);
		background-color: var(--color-primary);
	}
	@include mq("md") {
		margin-top: 0.6rem;
	}
}
.AccordionItem_panel {
	margin: 0;
	color: currentColor;
	text-align: left;
	margin: 0;
	padding: 4.5rem 4rem;
	background: var(--color-tertiary-blue);

	@include mq("md") {
		padding: 4.5rem 4rem;
	}
	&[hidden] {
		display: none;
	}
}

.AccordionItem_content {
	background: transparent;

	h4,
	h5,
	h6 {
		padding-bottom: var(--spacing--base);
		font-size: var(--font-size--sm);
		font-weight: var(--font-weight--semibold);
		@include mq("md") {
			font-size: var(--font-size--lg);
		}
	}
	p,
	ul {
		font-size: var(--font-size--xs);
		font-weight: var(--font-weight--normal);
		line-height: var(--line-height--2xl);
		@include mq("md") {
			font-size: var(--font-size--lg);
		}
	}
	ol {
		padding-inline-start: 2rem;
	}
}

/* For Edge bug https://developer.microsoft.com/en-us/microsoft-edge/platform/issues/4806035/ */
.AccordionItem_panel[hidden] {
	display: none;
}

:global(.theme-medlem) {
	.AccordionItem_triggerWrapper {
		border: 0;
		margin-top: 0;
		border-bottom: 1px solid var(--border-color);
		&:first-child {
			background: tomato;
			border-top: 1px solid var(--border-color) !important;
		}
		//border: 1px solid var(--border-color);
	}
}

:global(.theme-def) {
	.AccordionItem_triggerWrapper {
		//border: 1px solid var(--border-color);
		border: 0;
		margin-top: 0;
		border-top: 1px solid var(--border-color);
		&:last-of-type {
			border-bottom: 1px solid var(--border-color) !important;
		}
	}
}

// theme-tillidsportal custom style
:global(.theme-tillidsportal) {
	.AccordionItem_triggerWrapper {
		//border: 1px solid var(--border-color);
		border: 0;
		margin-top: 0;
		border-top: 1px solid var(--border-color);
		&:last-of-type {
			border-bottom: 1px solid var(--border-color) !important;
		}
	}
}
