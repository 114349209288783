@import "ui/styles/2-tools/tools.action";

.LinkButton {
	@include button;
	line-height: var(--line-height--2xl);

	&___sm {
		font-size: var(--font-size--sm);
	}
}
