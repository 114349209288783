@import "ui/styles/2-tools/tools.media-query.scss";
@import "ui/styles/2-tools/tools.display-mixins.scss";
@import "ui/styles/2-tools/tools.behavior.scss";

.Navigation {
	&___primary {
		margin-top: var(--spacing--2xl);
		@include mq("lg") {
			max-width: 60%;
			margin: auto 10%;
		}
	}
}

.Navigation_heading {
	@include hidden-absolute;
}

.Navigation_list {
	list-style: none;
	flex-direction: column;
	text-align: center;
	display: flex;
	flex-wrap: wrap;
	gap: 0.5rem;
	margin-inline: 1rem;

	@include mq("lg") {
		flex-direction: row;
	}
}

.Navigation_item___service {
	position: static;
	right: 0;
	a {
		display: flex;
	}
	@include mq("lg") {
		flex-direction: row;
		position: absolute;
		right: var(--spacing--base);
	}
}

.Navigation_item {
	text-align: left;
	@include mq("lg") {
		text-align: center;
	}
}

.Navigation_link {
	color: currentColor;
	position: relative;
	text-decoration: none;
	align-items: center;
	font-size: var(--font-size--2xl);
	font-weight: var(--font-weight--bold);
	display: inline-block;
	padding: var(--spacing--xs) 0;
	max-width: fit-content;
	cursor: pointer;
	&:after {
		@include transition {
			transition: width var(--trans-time--standard) ease;
		}
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		margin: auto;
		width: 0%;
		content: ".";
		color: transparent;
		background: var(--color-light);
		height: 1px;
	}

	&.isActive {
		color: var(--color-light);
		border-bottom: var(--border--sm) solid var(--color-border--light);
		pointer-events: none;
		&:after {
			display: none;
		}
	}
	&:hover:after {
		width: 100%;
	}

	@include mq("xs") {
		font-size: var(--font-size--3xl);
	}

	@include mq("lg") {
		margin: 0;
		font-size: var(--font-size--md);
		font-weight: var(--font-weight--semibold);
		padding: 1em 0;
		white-space: nowrap;
	}
}

.Navigation_icon {
	width: var(--icon-size--xl);
	height: var(--icon-size--xl);
	margin-left: var(--spacing--xs);
	:first-child {
		fill: var(--navigation-chevron);
	}

	@include mq("md") {
		width: var(--icon-size--md);
		height: var(--icon-size--md);
	}
}
