@import "ui/styles/2-tools/tools.mixins.scss";
@import "ui/styles/2-tools/tools.typography-mixins-tillidsportal.scss";

.ArrangementsBanner {
	width: 100%;
	background-color: var(--color-tertiary-blue);
	@include full-width-bg(#eaeef2);
	padding: 8rem 0;

	@include mq("md") {
		padding: 10rem 0;
	}

	@include mq("md-lg") {
		padding: 12rem 0;
	}

	&_heading {
		@include t-h2;
		color: var(--color-primary);
		margin-bottom: 2.4rem;

		@include mq("md") {
			margin-bottom: 4rem;
		}
	}

	&_container {
		justify-content: center;
		margin: 0 auto;
		padding-right: var(--grid-gutter);
		padding-left: var(--grid-gutter);
	}

	&_cardContainer {
		animation: fadeInDown 500ms ease-in-out;

		@include mq("md") {
			display: grid;
			grid-gap: 4.2rem 1.6rem;
			grid-template-columns: 1fr;
			grid-template-columns: 1fr 1fr 1fr;
		}
	}

	&_buttonContainer {
		display: flex;
		justify-content: center;
		margin-top: 4rem;
		gap: 2rem;
		flex-wrap: wrap;

		@include mq("md") {
			margin-top: 8rem;
		}
	}
}
