@import "ui/styles/2-tools/tools.media-query.scss";
@import "ui/styles/2-tools/tools.display-mixins.scss";
@import "ui/styles/2-tools/tools.typography-mixins-tillidsportal.scss";
@import "ui/styles/2-tools/tools.behavior.scss";

.WelcomeCard{
	animation: fadeInDown 500ms ease-in-out;
	width: 100%;
	margin-bottom: var(--spacing--3xl);
}
@keyframes fadeInDown {
    0% {
        opacity: 0;
        transform: translateY(-10px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.WelcomeCard_heading {
	@include  t-h3;
	text-align: center;
	color: var(--color-primary);
	margin-top: var(--spacing--xl);
	margin-bottom: var(--spacing--md);
	
	@include mq("lg") {	
		text-align: left;
		margin-bottom: var(--spacing--xl);
	}
}
.WelcomeCard_greeting{
	margin-right: .8rem;;
}
.WelcomeCard_sectionWrapper{	
	@include mq("lg") {	
		display: flex;
    	justify-content: space-between;
	}
}
.WelcomeCard_linkWrapper{
	margin-top: auto;
}
.WelcomeCard_link{		
	color: var(--color-primary);
	text-decoration: none;
	position: relative;
	border-bottom: 1px solid var(--color-tertiary-darker);
	@include transition {
		transition: color var(--trans-time--standard) ease;
	}
		
	&:hover{
		color: var(--color-secondary);
		text-decoration: none;
		border-bottom: 1px solid var(--color-secondary);
	}
}
.WelcomeCard_section{
	display: flex;
    flex-flow: column;
	background-color: var(--color-tertiary-lighter);	
	padding: var(--spacing--md);
	width: 100%;
	border-bottom: var(--border--sm) solid var(--color-tertiary);	
	color: var(--color-primary);
	text-align: center;
	&:last-of-type{
		border-bottom: 0
	}
	@include mq("lg") {	
		padding: var(--spacing--xl) var(--spacing--md);
		border-bottom: 0;
		border-right: var(--border--sm) solid var(--color-tertiary);
		&:last-of-type{
			border-right: 0
		}
	}
}
.WelcomeCard_sectionTitle{	
	@include t-body;
	text-align: center;
	text-transform: uppercase;
	font-weight: var(--font-weight--bold);
	margin-bottom: var(--spacing--xs);
	@include mq("lg") {	
		margin-bottom: var(--spacing--lg);
	}
}
.WelcomeCard_dateWrapper{
	margin-top: auto;
}
.WelcomeCard_memberOverview{
	margin: 4rem 0;
}
.WelcomeCard_representPrimary{
	display: flex;
	flex-direction: column;
	height: 100%;
	
	&:nth-of-type(2){
		margin-top: var(--spacing--xl);
		border-top: var(--border--sm) solid var(--color-tertiary);
		padding-top: var(--spacing--xl);
	}
}
.WelcomeCard_representDepartment{
	display: inline-block;
	width: 100%;	
	font-size: var(--font-size--lg);
	margin-bottom: 2rem;
}
.WelcomeCard_agreementPrimary{
	display: flex;
	flex-direction: column;
	height: 100%;
	@include mq("lg") {	
		// height: 14.5rem;
	}
}
.WelcomeCard_primaryAgreementLink,
.WelcomeCard_secondaryAgreementLink,
.WelcomeCard_memberLink
{
	margin-top: auto;
	color: var(--color-primary);
}
.WelcomeCard_representPrimaryDateLabel
{
	display: inline-block;
	width: 100%;
	font-size: var(--font-size--md);
	margin-bottom: var(--spacing--xs);
}
.WelcomeCard_representPrimaryTitle{
	margin-bottom: var(--spacing--sm);
	font-size: var(--font-size--xl);
	font-weight: var(--font-weight--bold);
}
.WelcomeCard_memberNumber{
	display: inline-block;
    width: 100%;
	font-size: 6rem;
	line-height: 6rem;
	font-weight: var(--font-weight--bold);
	color: var(--color-secondary);	
}
.WelcomeCard_memberNumberLabel{
	display: inline-block;
    width: 100%;
	font-size: var(--font-size--md);
	opacity: .75;	
}

.WelcomeCard_agreementSecondary{
	display: flex;
    flex-flow: column;
	border-top: var(--border--sm) solid var(--color-tertiary);
	padding-top: 3rem;
	margin-top: 3rem;
	height: 14rem;
}

.WelcomeCard_primaryAgreementDepartment,
.WelcomeCard_secondaryAgreementDepartment
{
	display: inline-block;
	width: 100%;	
	font-size: var(--font-size--lg);	
}

.WelcomeCard_primaryAgreementDate,
.WelcomeCard_secondaryAgreementDate
{
	display: inline-block;
	width: 100%;	
	font-size: var(--font-size--lg);	
	margin-bottom: 2rem;
}

.WelcomeCard_representPrimaryDate
{		
	font-size: var(--font-size--sm);
	font-weight: var(--font-weight--bold);
	@include mq("lg") {	
		font-size: var(--font-size--md);
	}
}
.WelcomeCard_memberRow{	
	padding: 1.5rem 0;
	border-bottom: var(--border--sm) solid var(--color-tertiary);
	text-align: left;
	&:last-of-type{
		border-bottom: 0;
	}
}
.WelcomeCard_memberInfo{
	display: flex;	
    justify-content: space-between;
}
.WelcomeCard_memberName{
	font-size: var(--font-size--sm);
	font-weight: var(--font-weight--bold);
}
.WelcomeCard_memberTitle{
	font-size: var(--font-size--sm);
}
.WelcomeCard_memberDate{
	font-size: var(--font-size--sm);
}
.WelcomeCard_memberCategory{
	display: inline-block;
	padding: 3px 10px 3px 10px;	
	border-radius: .5rem;
	font-size: var(--font-size--xs);
	font-weight: 500;
	color: var(--color-light);
	margin-bottom: 1rem;
}



.WelcomeCard_greeting{
	margin-right: .8rem;;
}

.WelcomeCard_top{
    display: flex;
	flex-direction: column;
    align-items: center;    
	margin-bottom: 4rem;
	@include mq("lg") {			
		flex-direction: row;
		justify-content: space-between;
	}
}

.WelcomeCard_memberNumberTitle{
	color:var(--color-primary);
	font-size: var(--font-size--sm);
	font-weight: bold;
	margin-right: 1rem;
	@include mq("lg") {	
		font-size: var(--font-size--xl);
	}
}
.WelcomeCard_memberNumberValue{
	color:var(--color-primary);
	font-size: var(--font-size--sm);	
	@include mq("lg") {	
		font-size: var(--font-size--xl);
	}
}

