@import "ui/styles/2-tools/tools.behavior.scss";
@import "ui/styles/2-tools/tools.media-query.scss";

.ArrangementCard {
	background: #fff;
	padding: 2.5rem 2rem;
	color: var(--text-secondary);
	position: relative;
	height: 100%;
	border-radius: 5px;

	@include mq("md") {
		padding: 2.5rem 2rem 6.5rem;
	}

	&_marginTop {
		margin-top: 1.6rem;

		@include mq("md") {
			margin-top: 2.7rem;
		}
	}

	&_label {
		padding: 5px 8px;
		font-size: 1.2rem;
		letter-spacing: 0.2px;
		font-weight: 600;
		color: #fff;
		background-color: var(--color-primary);
		border-radius: 5px 5px 0px 0px;
		height: 2.7rem;
		display: inline-block;
		margin-top: 1.6rem;

		@include mq("md") {
			margin-top: 0;
		}

		& + & {
			margin-left: 0.1rem;
		}

		&_green {
			background: #0b8265;
		}
	}

	&_heading_container {
		display: flex;
		min-height: calc(4.8rem + 2.5rem + 2.5rem);
	}

	&_heading {
		padding-bottom: 2.5rem;
		margin-bottom: 2.5rem;
		border-bottom: 1px solid var(--border-color);
		font-size: 2rem;
		line-height: 2.4rem;
		font-weight: 600;
		margin-top: auto;
		width: 100%;
	}

	&_infoLine {
		display: flex;
		flex-wrap: nowrap;
		align-items: center;
		margin-bottom: 1.5rem;
	}

	&_text {
		line-height: 1.8rem;
	}

	&_icon {
		display: flex;
		margin-right: 1.5rem;
	}

	&_infoText {
		margin-top: 3.5rem;
		font-weight: 600;
		line-height: 2.6rem;
	}

	&_linkIcon {
		position: absolute;
		right: 2.5rem;
		bottom: 2.5rem;
		margin-left: auto;

		@include transition {
			transition: transform var(--trans-time--standard) ease;
		}

		svg {
			width: auto;
			height: auto;
		}

		display: none;

		@include mq("md") {
			display: block;
		}
	}

	&_link {
		text-decoration: none;
	}
}

.ArrangementCard:hover {
	.ArrangementCard_linkIcon {
		transform: translate(5px);
	}
}

.ArrangementCard.ArrangementCard_noLinkIcon {
	padding-bottom: 2rem;
}
