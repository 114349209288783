@import "ui/styles/2-tools/tools.media-query.scss";
@import "ui/styles/2-tools/tools.typography-mixins.scss";
@import "ui/styles/2-tools/tools.behavior.scss";
@import "ui/styles/2-tools/_tools.typography-mixins-tillidsportal.scss";

.IframeFak {
	width: 100%;
	border: 0;
	@include mq("md") {
		display: inline-flex;
		align-items: flex-start;
	}
}
