@import "ui/styles/2-tools/tools.media-query.scss";

.ArticleHeader {
	margin-top: var(--spacing--xl);
	color: var(--color-primary);
	margin-bottom: var(--spacing--md);
	@include mq("md") {
		margin-bottom: var(--spacing--2xl);
	}
}

.ArticleHeader_kicker {
	width: 100%;
	font-size: var(--font-size--sm);
	font-weight: var(--font-weight--bold);
	text-transform: uppercase;
	margin-bottom: var(--spacing--2xs);
	color: var(--color-primary);
}

.ArticleHeader_heading {
	width: 100%;
	margin-bottom: var(--spacing--lg);
}

.ArticleHeader_lead {
	font-size: var(--font-size--xl);
	@include mq("md") {
		max-width: 75%;
	}
}

// theme-tillidsportal custome style
:global(.theme-tillidsportal) {
	.ArticleHeader_heading {
		font-size: var(--font-size--2xl);
		@include mq("md") {
			font-size: var(--font-size--4xl);
		}
	}
}


// theme-tillidsportal custome style
:global(.theme-medlem) {
	.ArticleHeader {
		margin-top: 0;
		padding-top: 5rem;
		@include mq("md") {
			padding-top: 12rem;
		}
	}
}

// theme-kongress custome style
:global(.theme-kongres) {
	.ArticleHeader {
		margin-top: 0;
		padding-top: 5rem;
		@include mq("md") {
			padding-top: 12rem;
		}
	}
}


// theme-tillidsportal custome style
:global(.theme-def) {
	.ArticleHeader {
		margin-top: 0;
		padding-top: 5rem;
		@include mq("md") {
			padding-top: 12rem;
		}
	}
}
