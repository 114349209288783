@import "ui/styles/2-tools/tools.action";
@import "ui/styles/2-tools/tools.typography-mixins.scss";

.FormfieldString {
	position: relative;
	display: flex;
	flex-flow: column;

	&.hasError {
		color: var(--color-error);
	}

	&.isDisabled {
		color: var(--color-gradient--5);
	}
}

.FormfieldString_input {
	@include input;
	margin: var(--spacing--2xs) 0;
	width: 100%;
	background-color: transparent;
	border: var(--border--sm) solid transparent;
	border-color: currentColor;
	@include transition {
		transition: border-color, padding var(--trans-time--standard) ease;
	}
	-webkit-appearance: none;

	&::placeholder {
		color: currentColor;
	}

	.isDisabled & {
		cursor: not-allowed;
	}
}

.FormfieldString_label {
	@include formfield-label;
	white-space: nowrap;
}

.FormfieldString_input:not([disabled]):hover,
.FormfieldString_label:hover ~ .FormfieldString_input:not([disabled]),
.FormfieldString_input:focus {
	@include transition {
		transition: border-color, padding var(--trans-time--standard) ease;
	}
	padding-left: 0;
	border-color: transparent;
	border-bottom-color: currentColor;
}
