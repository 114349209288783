@import "ui/styles/2-tools/tools.space.scss";
@import "ui/styles/2-tools/tools.typography-mixins-tillidsportal.scss";

.Accordion {
	margin: 0;
	padding: 0;
	border: var(--border--md) solid var(--color-border--gray);
	border-radius: var(--corner-size--sm);
	width: 100%;
	color: var(--accordian-text);
	@include module-seperator;
}
.Accordion_title{
	@include t-h2;
	margin-bottom: 4rem;
}