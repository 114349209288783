@import "ui/styles/2-tools/tools.display-mixins.scss";
@import "ui/styles/2-tools/tools.mixins.scss";
@import "ui/styles/2-tools/tools.media-query.scss";
@import "ui/styles/2-tools/tools.behavior.scss";
@import "ui/styles/2-tools/tools.space.scss";

.ListShortcut {
	width: 100%;
	background-color: var(--color-tertiary-blue);	
	@include full-width-bg(#eaeef2);
	@include module-seperator;
}
.ListShortcut_gridcell {
	display: grid;
	grid-gap: var(--spacing--md);
	animation: fadeInDown 500ms ease-in-out;
	@include mq("md-lg") {
		grid-gap: var(--spacing--xl) var(--spacing--md);
		grid-template-columns: 1fr 1fr 1fr;
	}
}
.ListShortcut_items{
	padding-top: 5.5rem;
	@include mq("md-lg") {
		padding-top: 6.5rem;
	}
}