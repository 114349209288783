@import "ui/styles/2-tools/_tools.typography-mixins-tillidsportal.scss";
@import "ui/styles/2-tools/tools.behavior.scss";
@import "ui/styles/2-tools/tools.media-query.scss";

.container {
	display: flex;
	flex-direction: column;
	gap: 50px;
}
.contentButton{
	width: 100%;
	@include mq("md") {
		width: auto;
	}
}
.content-container {
	animation: fadeInDown 500ms ease-in-out;
	position: relative;
	overflow: hidden;
	max-height: 400px;

	// UMBRACO RICH TEXT EDITOR CONTENT STYLING
	color: var(--color-primary);

	h3 {
		@include t-h4;
		margin-bottom: pxToRem(16);
	}
	h4 {
		@include t-h5;
		margin-bottom: pxToRem(12);
	}
	p {
		@include t-body;
	}
	table {
		width: auto !important;
		height: auto !important;
		border: 1px solid #000022 !important;
		border-collapse: collapse !important;
		margin-bottom: 25px !important;
		word-break: break-word !important;
	}
	tr {
		width: auto !important;
		height: auto !important;
	}
	td {
		width: auto !important;
		height: auto !important;
		border: 1px solid #000022 !important;
		padding: 5px !important;
	}
	:global(.footnote) {
		@include t-body;
		font-size: pxToRem(11);
		line-height: pxToRem(22);
		font-weight: 300;
		letter-spacing: 0.02em;
	}
	@include transition {
		transition: max-height var(--trans-time--standard) ease;
	}
}
@keyframes fadeInDown {
    0% {
        opacity: 0;
        transform: translateY(-10px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}
.content-container___open {
		max-height: 1000px;
		overflow: auto;
}

.gradient {
	position: absolute;
	bottom: 0;

	width: 100%;
	height: 100px;
	max-height: 100%;

	background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);

	pointer-events: none;
}
