@import "ui/styles/2-tools/tools.mixins";

.AccordionFaq_container {
	background-color: var(--color-light);
	color: var(--accordian-text);
	width: 100%;
}
.Accordion{
	max-width: 124.8rem;
	margin: auto;
}
.AccordionFaq_header {
	width: 100%;
	color: var(--color-header-secondary);
	font-weight: var(--font-weight--bold);
	font-size: var(--font-size--3xl);
	padding: calc(var(--spacing--lg) + 5px) 0;
	text-align: left;	
	@include mq("md") {
		font-size: var(--font-size--4xl);
		padding: var(--spacing--xl) 0;
	}
}