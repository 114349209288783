@import "ui/styles/2-tools/tools.display-mixins.scss";
@import "ui/styles/2-tools/tools.media-query.scss";
@import "ui/styles/2-tools/tools.typography-mixins.scss";

.ResponsibleContact {
	padding-left: var(--grid-gutter);
	padding-right: var(--grid-gutter);
	margin-bottom: var(--spacing--3xl);
	color: var(--color-primary);

	margin-top: 8rem;
	@include mq("md") {
		margin-top: 12rem;
	}

	&_list {
		margin: 0 var(--spacing-md);
		padding-top: 4rem;
		display: grid;
		grid-gap: var(--spacing--md);
		animation: fadeInDown 500ms ease-in-out;

		@include mq("sm") {
			grid-gap: var(--spacing--xl) var(--spacing--md);
			grid-template-columns: 1fr 1fr;
		}
		@include mq("lg") {
			// grid-template-columns: 1fr 1fr 1fr;
		}
	}

	&_heading {
		@include heading--lg();
	}
}
