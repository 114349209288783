.swiper-container{
	.swiper-button-prev{
		border: var(--border--md) solid var(--color-primary);
		border-radius: var(--corner-circle);
		top: 3rem;
		right: 5.5rem;
		left: unset;
		width: 4rem;
    	height: 4rem;
	}
	.swiper-button-next{
		border: var(--border--md) solid var(--color-primary);
		border-radius: var(--corner-circle);
		right: 0;
		top: 3rem;
		width: 4rem;
    	height: 4rem;
	}
	.swiper-wrapper{
		padding-top: 10rem;
	}
	.swiper-button-prev:after, .swiper-button-next:after{
		color: var(--color-primary);
		font-size: 1.5rem;
		font-weight: var(--font-weight--bold);
	}
	.swiper-pagination{
		display: none;
	}
} 