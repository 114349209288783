@import "ui/styles/2-tools/tools.media-query.scss";
@import "ui/styles/2-tools/tools.display-mixins.scss";
@import "ui/styles/2-tools/tools.typography-mixins-tillidsportal.scss";
@import "ui/styles/2-tools/tools.behavior.scss";
@import "ui/styles/2-tools/tools.space.scss";

.Profile{
	animation: fadeInDown 500ms ease-in-out;
	width: 100%;
	margin-bottom: var(--spacing--3xl);
	color: var(--color-primary);
	@include module-seperator;
}
@keyframes fadeInDown {
    0% {
        opacity: 0;
        transform: translateY(-10px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.Profile_top{
	display: flex;
    align-items: baseline;
    justify-content: space-between;
}
.Profile_categories{
	background-color: var(--color-tertiary-lighter);
	padding: 4rem 2rem;
	margin-bottom: 2.4rem;
	@include mq("lg") {			
		padding: 6rem 5rem;
	}
}
.Profile_columnsWrapper{	
	@include mq("lg") {			
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		gap: 5rem;
	}
}
.Profile_columns{
	border-bottom: 1px solid var(--color-tertiary);
	padding: 2.5rem 0;
	&:last-of-type{
		border-right: none;
		border-bottom: none;
	}
	@include mq("lg") {			
		border-right: 1px solid var(--color-tertiary);
		border-bottom: none;
		padding-bottom: 0
	}
}
.Profile_heading {
	@include  t-h1;	
	display: flex;
    align-items: center;
    gap: 1rem;	
	margin-bottom: var(--spacing--md);	
	@include mq("lg") {			
		margin-bottom: var(--spacing--xl);
	}
}

.Profile_memberNumberTitle{
	font-size: var(--font-size--sm);
	font-weight: var(--font-weight--bold);
	margin-right: 1rem;
	@include mq("lg") {			
		font-size: var(--font-size--xl);
	}
}
.Profile_memberNumberValue{
	font-size: var(--font-size--sm);
	@include mq("lg") {			
		font-size: var(--font-size--xl);
	}
}

.Profile_subHeading{
	@include  t-h3;	
	display: flex;
    align-items: center;
    gap: 1rem;
	margin-bottom: .5rem;
	@include mq("lg") {			
		margin-bottom: 3rem;
	}
}

.Profile_section{
	font-size: 1.6rem;
	line-height: 26px;
	margin-bottom: 2.4rem;	
	@include mq("lg") {			
		margin-bottom: 3rem;
	}	
	&___location{
		display: inline-block;
		margin-bottom: 0;
	}
}
.Profile_dataTitle, .Profile_dataValue{
	display: inline-block;
	width: 100%;
}
.Profile_dataTitle{	
	font-weight: bold;
	&___kontakt{
		margin-bottom: 1rem;
		text-transform: uppercase;
		@include mq("lg") {			
			margin-bottom: 2rem;
		}
	}
}
.Profile_dataValue{		
	&___kontakt{		
		display: flex;
		align-items: center;
		gap: 1rem;
		margin-bottom: 1rem;		
	}
}

.Profile_iconCategory{
	width: 60px;
	height: 60px;
	circle, path{
		fill: transparent;
	}
}

.Profile_iconEmail{
	width: 20px;
	height: 15px;
	circle, path{
		fill: transparent;
	}
}
.Profile_iconPhone{
	width: 20px;
	height: 20px;
	circle, path{
		fill: transparent;
	}
}
.Profile_duties{
	&:nth-of-type(1n + 3) {
		display: none;
	}
	[aria-expanded="true"] & {		
		display: block;
		animation: fadeInDown 200ms ease-in-out;				
	}
	@keyframes fadeInDown {
		0% {
			opacity: 0;
			transform: translateY(-10px);
		}
		100% {
			opacity: 1;
			transform: translateY(0);
		}
	}	
}
.Profile_link {
	display: inline-flex;
	text-align: center;
	align-items: center;
	color: var(--color-primary);
	text-decoration: none;
	background: none;
	border: 0;
	padding-left: 0;
    font-weight: bold;
    margin-top: 2rem;
}
.Profile_linkIconPlus {
	width: auto;
	height: auto;
	margin-left: 1rem;
	@include transition {
		transition: transform var(--trans-time--standard) ease;
	}
	.Profile_link:hover & {
		transform: rotate(90deg); /* Equal to rotateZ(45deg) */
	}
}
.Profile_linkIconMinus {
	width: auto;
	height: auto;
	margin-left: 1rem;
	@include transition {
		transition: transform var(--trans-time--standard) ease;
	}
	.Profile_link:hover & {
		transform: rotate(180deg); /* Equal to rotateZ(45deg) */
	}
}