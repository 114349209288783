@import "ui/styles/2-tools/tools.media-query.scss";

.agreementWrapper {
	padding: 40px 0;
	display: flex;
	flex-direction: column;
	gap: 20px;
	min-height: 717px;
}

.chapterContentWrapper {
	display: flex;
	flex-direction: column;
	gap: 20px;

	@include mq("md") {
		flex-direction: row;
		gap: 25px;
	}
}
