@import "ui/styles/2-tools/tools.media-query.scss";
@import "ui/styles/2-tools/tools.display-mixins.scss";
@import "ui/styles/2-tools/tools.behavior.scss";

.NavigationDef {
	&___primary {
		
	}
}

.NavigationDef_heading {
	@include hidden-absolute;
}

.NavigationDef_list {
	list-style: none;
	flex-direction: column;
	text-align: center;
	display: flex;

	@include mq('lg') {
		flex-direction: row;
	}
}

.NavigationDef_item___service {
	position: static;
	right: 0;
	a {
		display: flex;
	}
	@include mq('lg') {
		flex-direction: row;
		position: absolute;
		right: var(--spacing--base);
	}
}

.NavigationDef_item {
	flex: 1 1;
	text-align: left;
	text-transform: uppercase;
	padding: 0;
	@include mq('lg') {
		text-align: center;
		padding-right: 2rem;
		padding-left: 2rem;
	}
	&___secondary{
		display: none;
		@include mq('lg') {
			display: block;
		}
	}
}

.NavigationDef_link {
	color: currentColor;
	position: relative;
	text-decoration: none;
	align-items: center;
	font-size: var(--font-size--2xl);
	font-weight: var(--font-weight--bold);
	display: inline-flex;
	padding: var(--spacing--xs) 0;
	max-width: fit-content;
	background: none;
	cursor: pointer;
	min-width: unset;
	background: unset;
	box-shadow: unset;
	@include transition {
		transition: color var(--trans-time--standard) ease;
	}	
	&:after {
		@include transition {
			transition: width var(--trans-time--standard) ease;
		}
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		margin: auto;
		width: 0%;
		content: ".";
		color: transparent;
		background: var(--color-secondary);
		height: 1px;
	}
	&:focus{
		box-shadow: unset;
	}
	&.isActive {	
		border-bottom: var(--border--sm) solid var(--color-secondary);
		pointer-events: none;
		color: var(--color-secondary);
		&:after {
			display: none;
		}
	}
	&:hover {	
		box-shadow: unset;
		color: var(--color-secondary);
		path{
			@include transition {
				transition: stroke var(--trans-time--standard) ease;
			}
			stroke: var(--color-secondary);
		}
	}
	&:hover:after {
		width: 100%;
		background: var(--color-secondary);
	}

	@include mq("xs") {
		font-size: var(--font-size--xl);
	}

	@include mq('lg') {
		margin: 0;
		font-size: var(--font-size--lg);
		font-weight: var(--font-weight--semibold);		
		white-space: nowrap;
	}
	& > span{
		display: inline-flex
	}
}




.NavigationDef_icon {
	width: var(--icon-size--xl);
	height: var(--icon-size--xl);
	margin-left: var(--spacing--xs);
	:first-child {
		fill: var(--NavigationDef-chevron);
	}

	@include mq('lg') {
		width: var(--icon-size--md);
		height: var(--icon-size--md);
	}
}

.Navigation_iconLoop {
	transform: rotate(90deg);
	width: var(--icon-size--xl);
	height: var(--icon-size--xl);	
	position: relative;
	left: 0;
	margin-left: 1rem;
	@include transition {
		transition: left var(--trans-time--standard) ease;
	}
	:first-child {
		fill: transparent;
	}

	@include mq('lg') {
		width: var(--icon-size--3xl);
		height: var(--icon-size--3xl);	
	}

	.ServiceNavigation_link:hover &{
		left: .3rem;
	}
}

.Navigation_iconLock {
	width: var(--icon-size--xl);
	height: var(--icon-size--xl);	
	position: relative;
	left: 0;
	margin-left: 1rem;
	@include transition {
		transition: left var(--trans-time--standard) ease;
	}
	:first-child {
		fill: transparent;
	}

	@include mq('lg') {
		width: 2.5rem;
		height: 2.5rem;
	}

	.ServiceNavigation_link:hover &{
		left: .3rem;
	}
}