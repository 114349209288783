/* ------------------------------------ *\
		settings.grid-variables
\* ------------------------------------ */
@import "ui/styles/2-tools/tools.media-query.scss";

:root {
	--grid-gutter: 2rem;
	@include mq("lg") {
		--grid-gutter: 1rem;
	}
}

// Grid based on BlazeUI's grid
// More info: http://blazeui.com/objects/grid/

$grid-widths: (
	1: 1 / 100,
	2: 2 / 100,
	3: 3 / 100,
	4: 4 / 100,
	5: 5 / 100,
	6: 6 / 100,
	7: 7 / 100,
	8: 8 / 100,
	9: 9 / 100,
	10: 1 / 10,
	11: 11 / 100,
	12: 12 / 100,
	13: 13 / 100,
	14: 14 / 100,
	15: 15 / 100,
	16: 1 / 6,
	17: 17 / 100,
	18: 18 / 100,
	19: 19 / 100,
	20: 1 / 5,
	21: 21 / 100,
	22: 22 / 100,
	23: 23 / 100,
	24: 24 / 100,
	25: 1 / 4,
	26: 26 / 100,
	27: 27 / 100,
	28: 28 / 100,
	29: 29 / 100,
	30: 3 / 10,
	31: 31 / 100,
	32: 32 / 100,
	33: 1 / 3,
	34: 34 / 100,
	35: 35 / 100,
	36: 36 / 100,
	37: 37 / 100,
	38: 38 / 100,
	39: 39 / 100,
	40: 2 / 5,
	41: 41 / 100,
	42: 42 / 100,
	43: 43 / 100,
	44: 44 / 100,
	45: 45 / 100,
	46: 46 / 100,
	47: 47 / 100,
	48: 48 / 100,
	49: 49 / 100,
	50: 1 / 2,
	51: 51 / 100,
	52: 52 / 100,
	53: 53 / 100,
	54: 54 / 100,
	55: 55 / 100,
	56: 56 / 100,
	57: 57 / 100,
	58: 58 / 100,
	59: 59 / 100,
	60: 3 / 5,
	61: 61 / 100,
	62: 62 / 100,
	63: 63 / 100,
	64: 64 / 100,
	65: 65 / 100,
	66: 2 / 3,
	67: 67 / 100,
	68: 68 / 100,
	69: 69 / 100,
	70: 7 / 10,
	71: 71 / 100,
	72: 72 / 100,
	73: 73 / 100,
	74: 74 / 100,
	75: 3 / 4,
	76: 76 / 100,
	77: 77 / 100,
	78: 78 / 100,
	79: 79 / 100,
	80: 4 / 5,
	81: 81 / 100,
	82: 82 / 100,
	83: 83 / 100,
	84: 84 / 100,
	85: 85 / 100,
	86: 86 / 100,
	87: 87 / 100,
	88: 88 / 100,
	89: 89 / 100,
	90: 9 / 10,
	91: 91 / 100,
	92: 92 / 100,
	93: 93 / 100,
	94: 94 / 100,
	95: 95 / 100,
	96: 96 / 100,
	97: 97 / 100,
	98: 98 / 100,
	99: 99 / 100,
	100: 1,
	"1col": 1 / 12,
	"2col": 2 / 12,
	"3col": 3 / 12,
	"4col": 4 / 12,
	"5col": 5 / 12,
	"6col": 6 / 12,
	"7col": 7 / 12,
	"8col": 8 / 12,
	"9col": 9 / 12,
	"10col": 10 / 12,
	"11col": 11 / 12,
	"12col": 1,
) !default;
