@import "ui/styles/2-tools/tools.media-query.scss";
@import "ui/styles/2-tools/tools.grid-mixins.scss";
@import "ui/styles/2-tools/tools.behavior.scss";
.Slider_wrapper {
	position: relative;
	margin: var(--spacing--3xl) 0;
	margin-inline: 1rem;
	margin-block-start: 1rem;
}
.Slider_slide {
	color: var(--color-primary);
}
.Slider_heading {
	color: var(--color-primary);
	@include mq("md") {
		position: absolute;
		top: 0;
		width: 80%;
		margin-inline-end: 1rem;
	}
}

// bottom border only on theme-kongres
:global(.theme-kongres) {
	.Slider_wrapper {
		@include section-seperator;
	}
}
:global(.GlobalArticlePage) {
	.Slider_wrapper {
		padding-right: var(--grid-gutter);
		padding-left: var(--grid-gutter);	
	}
}
