@import "ui/styles/2-tools/tools.media-query.scss";
@import "ui/styles/2-tools/tools.display-mixins.scss";
@import "ui/styles/2-tools/tools.typography-mixins.scss";
@import "ui/styles/2-tools/tools.space.scss";
@import "ui/styles/2-tools/_tools.typography-mixins-tillidsportal.scss";
@import "ui/styles/2-tools/tools.aspect-ratio.scss";
@import "ui/styles/2-tools/tools.behavior.scss";
@import "ui/styles/2-tools/tools.mixins.scss";

.Campaign_wrapper{
	position: relative;
    width: 100%;
}
.Campaign {	
	color: var(--color-light);
	text-align: center;
	@include full-width-bg(#eaeef2);
	@include module-seperator;	
}

.Campaign_pattern{
	display: none;
	@include mq("lg") {
		display: block;
		position: absolute;
		bottom: -3rem;
		width: 17rem;
		height: 46rem;
		right: 0;
		bottom: 8rem;
		z-index: 1;
	}
}
.Campaign_kicker{
	display: inline-block;
	color: var(--color-primary);
	font-size: 1.4rem;
	margin-bottom: var(--spacing--md);
	@include mq("lg") {
		margin-bottom: var(--spacing--lg);
	}
}
.Campaign_heading {
	@include t-h2();
	color: var(--color-primary);
	margin-bottom: var(--spacing--md);
	text-align: center!important;
	@include mq("lg") {
		margin-bottom: var(--spacing--lg);
	}
}

.Campaign_text{
	@include manchet();
	color: var(--color-primary);
	text-align: center;
	margin-bottom: var(--spacing--lg);	
	line-height: 26px;
	@include mq("lg") {
		margin-bottom: var(--spacing--xl);
	}
}
.Campaign_cta{
	display: flex;
	justify-content: center;
    align-self: center;
    gap: 19px;
 
    width: 100%;
}
.Campaign_contentWrapper {
	background: white;
    width: 80%!important;
    padding: 4rem 0;
	margin: -10rem auto 0;
    position: relative;
	z-index: 1;
	
	@include mq("lg") {
		width: 65%!important;
		min-width: 726px;
		min-height: 500px;
		margin: -25rem auto 0;
		padding: 7rem 8rem;
	}	
}
.Campaign_content{
	display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
}
.Campaign_link{
	min-width: initial;
	width: 100%;
	@include mq("md") {
		width: auto;
	}	
}
.Campaign_some{
	display: none;
	@include mq("md") {
		display: block;
		position: absolute;
		top: 35rem;
		left: 5rem;
	}	
}

.Campaign_media {	
	z-index: 1;
}

.Campaign_mediabox {
	@include aspect-ratio(16, 9);
}

.Campaign__picture{
	height: calc(100% + 20rem);
    transform: translateY(-10rem);
	@include transition {
		transition: transform var(--trans-time--standard) ease;
	}	
}

