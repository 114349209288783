@import "ui/styles/2-tools/tools.media-query.scss";
@import "ui/styles/2-tools/tools.display-mixins.scss";
@import "ui/styles/2-tools/tools.typography-mixins.scss";

.SuperHeroTertiaryCard {
	position: relative;
	height: 100%;
	color: var(--color-light);
	text-align: center;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 2em;
	padding-bottom: 12rem;
	@include mq("md") {
		padding-bottom: 25rem;
	}
}
.SuperHeroTertiaryCard_pattern {
	display: none;

	@include mq("lg") {
		display: block;
		position: absolute;
		width: 12%;
		height: 44rem;
		left: 0;
		bottom: -10.8rem;
	}
}
.SuperHeroTertiaryCard_heading {
	@include heading--xl();
	color: var(--color-light);
	margin-bottom: var(--spacing--xl);
}

.SuperHeroTertiaryCard_textbox {
	display: flex;
	justify-content: center;
	flex-direction: column;
	padding: var(--spacing--xl);
	padding-top: var(--spacing--3xl);
	margin: auto;
	margin-bottom: var(--spacing--sm);
	position: relative;
	top: 3rem;
	@include mq("md") {
		top: 5rem;
	}

	@include mq("lg") {
		margin-bottom: var(--spacing--3xl);
		max-width: 50%;
	}
}

.SuperHeroTertiaryCard_bottomPadding {
	padding-bottom: 25rem;
	background: var(--color-light);
	@include mq("md") {
		padding-bottom: 25rem;
	}
}

.SuperHeroTertiaryCard_text {
	@include manchet();
	@include line-clamp(6);
}

.SuperHeroTertiaryCard_mediaboxTop_wrapper {
	position: fixed;
	z-index: -1;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: var(--transparent-color--primary);
}
.SuperHeroTertiaryCard_mediaboxTop {
	opacity: 0.1;
}

.SuperHeroTertiaryCard_mediabox {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	top: 35rem;
	margin: auto;
	max-width: 124rem;
	max-height: 74%;
	overflow: hidden;
	z-index: 2;
	@include mq("md") {
		top: 45rem;
	}
	img[class*="Picture"] {
		object-fit: contain;
	
		@include mq("md") {
			object-fit: cover;
		}

	}
}

.SuperHeroTertiaryCard_kicker {
	position: relative;
	top: unset;
	left: unset;
	text-transform: uppercase;
	font-size: var(--font-size--sm);
	font-weight: var(--font-weight--semibold);
	margin-bottom: 1em;
	color: var(--color-light);
}

.SuperHeroTertiaryCard_link {
	display: block;
	color: inherit;
	text-decoration: inherit;
}
