@import "ui/styles/2-tools/tools.typography-mixins.scss";
@import "ui/styles/2-tools/tools.behavior.scss";

// FILTER
.Filter{
	color: var(--color-primary);
}

.Filter_form{
	display: flex;
	flex: 0 0 100%;
	flex-direction: column;		
	max-height: 500px;
	overflow: hidden;
	margin-bottom: 2rem;
	@include transition {
		transition: max-height var(--trans-time--standard) ease;
	}	
	@include mq("sm") {
		flex-direction: row;
	}
}

.Filter_filter{
	color: var(--color-primary);
}


.Filter_filterTabs {
	display: flex;
	flex-wrap: wrap;
	position: relative;
	
}
.Filter_filterInput[type="radio"] {
	display: none;
}

.Filter_filterTab {
	display: flex;
	align-items: center;
	margin-bottom: 1rem;
	justify-content: center;
	height: 5rem;
    width: auto;
    font-size: var(--font-size--xl);
	font-weight: var(--font-weight--bold);	
	cursor: pointer;	
	@include transition {
		transition: color var(--trans-time--standard) ease;
	}
	&:first-of-type{
		margin-right: 6rem;
	}
	&:hover{
		opacity: .75;
	}
}

.Filter_filterInput[type="radio"] {
	&:checked {
		& + label {
			color: var(--primary-color);			
		}
		& ~ .FormfieldSelect_wrapper {
			display: none;
		}
	}
}

.Filter_filterInput[id="radio-1"] {
	&:checked {
		& ~ .Filter_filterGlider {
			transform: translateX(0);
		}		
	}
}

.Filter_filterInput[id="radio-2"] {
	&:checked {
		& ~ .Filter_filterGlider {
			transform: translateX(170%);
		}
		& ~ .Filter_filterGlider + .Filter_form {
			@include transition {
				transition: max-height var(--trans-time--standard) ease;
			}	
			max-height: 0;			
		}
		
	}
}

.Filter_filterGlider {
	position: absolute;
	top: 4.5rem;
	display: flex;
	height: .2rem;
	width: 8rem;
	background-color: var(--color-secondary);
	z-index: 1;	
	@include transition {
		transition: all var(--trans-time--standard) ease;
	}	
}

.Filter_select{
	min-width: 20rem;
}