@import "ui/styles/2-tools/tools.display-mixins";

.Logo {
	display: inline-block;
	text-decoration: none;
	color: currentColor;
}

.Logo_heading {
	@include hidden-absolute;
}

.Logo_asset {
	display: block;
	height: 100%;
    width: 100%;
}
