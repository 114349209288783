@import "ui/styles/2-tools/tools.mixins";
@import "ui/styles/2-tools/tools.behavior.scss";

.AccordionCalendar {
	display: flex;
	justify-content: space-between;	
	position: relative;
	border: var(--border--md) solid var(--color-border--gray);
	border-radius: var(--corner-size--sm);
	width: 100%;
	color: var(--accordian-text);
	@include mq('md') {
		background-color: var(--color-tertiary);
		margin: 0;
		padding: 1% 1% 8% 1%;		
	}
}

