@import "ui/styles/2-tools/tools.mixins.scss";

.CludoSearchResults {
	width: 100%;
	padding: 4rem var(--grid-gutter);
	min-height: 40vh;

	@include mq("md") {
		padding: 8rem var(--grid-gutter);
	}

	&_heading {
		margin-bottom: 1rem;
		color: var(--color-primary);
	}
}
