@import "ui/styles/2-tools/tools.typography-mixins.scss";
@import "ui/styles/2-tools/tools.grid-mixins.scss";
@import "ui/styles/2-tools/tools.behavior.scss";

.DownloadBarContainer{
	width: 100%;
	@include section-seperator;
	animation: fadeInDown 500ms ease-in-out;
	margin-bottom: 0;
}
@keyframes fadeInDown {
    0% {
        opacity: 0;
        transform: translateY(-10px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}
.DownloadBarContainer_link{
	display: inline-flex;
	text-align: center;
	align-items: center;
	color: var(--color-primary);
	text-decoration: none;
	font-size: var(--font-size--md);
}
.DownloadBarContainer_linkIcon{
	width: auto;
	height: auto;
	margin-left: 1rem;
	@include transition {
		transition: transform var(--trans-time--standard) ease;
	}	
	.DownloadBarContainer_link:hover &{
		transform: rotate(90deg); /* Equal to rotateZ(45deg) */
	}	
}
.DownloadBarContainer_linkWrapper{
	text-align: center;
	margin-top: 7rem;
}