@import "ui/styles/2-tools/tools.typography-mixins.scss";
@import "ui/styles/2-tools/tools.behavior.scss";


.SocialShare{
	margin-bottom: 2rem;
}

.SocialShare_icon{
	width: auto;
	height: auto;
	color: var(--color-light);
	@include transition {
		transition: color var(--trans-time--standard) ease;
	}
	.SocialShare_links &:hover{
		color: var(--color-secondary);
	}
}

.SocialShare_items{
	list-style-type: none;
}

.SocialShare_container{
	display: flex;
	justify-content: space-evenly; 
	padding: 0;
	@include mq("md") {
		justify-content: space-between;
	}
}
.SocialShare_iframe{
	opacity: 0;
    position: absolute;
    z-index: 9;
}