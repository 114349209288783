@import "ui/styles/2-tools/tools.media-query.scss";
@import "ui/styles/2-tools/tools.display-mixins.scss";
@import "ui/styles/2-tools/tools.typography-mixins.scss";

.SuperHeroCard {
	position: relative;
	min-height: 100vh;	
	padding-bottom: var(--spacing--4xl);
	color: var(--color-light);
	text-align: center;
}

.SuperHeroCard_heading {
	@include heading--xl();
	font-size: 4.2rem;
	color: var(--color-light);
	margin-bottom: var(--spacing--sm);	
	text-align: center;
	@include mq("md") {
		font-size: 7.0rem;
	}
}

.SuperHeroCard_text{
	@include manchet();
	font-size: 2rem;
	color: var(--color-light);
	@include mq("md") {
		font-size: 3rem;
	}
}
.SuperHeroCard_textbox {
	display: flex;
	justify-content: center;
	flex-direction: column;
	text-align: center;
	padding: var(--spacing--xl);	
	padding-top: var(--spacing--3xl);	
	margin: auto;
	margin-bottom: var(--spacing--sm);   
	@include mq("md") {
		margin: 7% auto;
		margin-bottom: var(--spacing--3xl); 
		max-width: 50%; 		
	}
}
.SuperHeroCard_mediabox_wrapper{
	position: fixed;
	z-index: -1;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: var(--transparent-color--primary)
}
.SuperHeroCard_mediabox {
	opacity: .1;
}

.SuperHeroCard_kicker {
	@include manchet();
	font-size: 1.4rem;
	position: relative;
	top: unset;
	left: unset;	
	margin-bottom: 1em;
	color: var(--color-light);
	@include mq("md") {
		font-size: 2.0rem;
	}
}
.SuperHeroCard_stickerboxheading{
	@include heading--lg();
	font-size: 2.8rem;
	margin-bottom: var(--spacing--sm);
	@include mq("md") {
		font-size: 4.0rem;
	}
}
.SuperHeroCard_action{
	font-size: 1.6rem;
	@include mq("md") {
		font-size: 1.8rem;
	}
}
.SuperHeroCard_stickerboxcontent{
	@include paragraph();
	color: var(--color-primary);
	background: var(--color-light);    
	padding: var(--spacing--lg);	 
	margin: var(--spacing--xl) var(--grid-gutter);    
	border-radius: 0 0 0 var(--corner-size--md);	
	text-align: left;
	@include mq("md") {
		max-width: 50rem; 
		margin: 0 auto var(--spacing--xl); 
		transform: translate(30%, 0%);
	}
}

.SuperHeroCard_pattern{
	display: none;
	@include mq("md") {
		display: block;
		width: auto;
		height: auto;
		position: absolute;
		top: 8rem;
		right: 0;
	}
}

.SuperHeroCard_Corp{	
	width: 17rem;
	height: 17rem;
	margin: auto;
	@include mq("md") {
		display: block;	
		position: absolute;		
		left: 6rem;
		top: 8rem;
		width: 17rem;
		height: 17rem;
	}
}
.SuperHeroCard_some{
	display: none;
	@include mq("md") {
		display: block;
		position: absolute;
		top: 35rem;
		left: 5rem;
	}	
}

