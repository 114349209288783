@import "ui/styles/2-tools/tools.media-query.scss";
@import "ui/styles/2-tools/tools.display-mixins.scss";
@import "ui/styles/2-tools/tools.typography-mixins-tillidsportal.scss";
@import "ui/styles/2-tools/tools.behavior.scss";
@import "ui/styles/2-tools/tools.space.scss";

.BranchContactInfo{
	animation: fadeInDown 500ms ease-in-out;
	width: 100%;
	@include module-seperator;
	padding-top: 0!important;
}
@keyframes fadeInDown {
    0% {
        opacity: 0;
        transform: translateY(-10px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}
.BranchContactInfo_kicker{
	font-size: var(--font-size--md);
	font-weight: bold;
	color: var(--color-primary);
	@include mq("lg") {			
		font-size: var(--font-size--xl);
	}
}
.BranchContactInfo_manchet{
	color: var(--color-primary);	
	@include  t-body;
	margin-bottom: 7rem;
	@include mq("lg") {	
		max-width: 60%
	}
}
.BranchContactInfo_heading {
	@include  t-h1;	
	margin-top: var(--spacing--xl);
	margin-bottom: var(--spacing--md);
	color: var(--color-primary);
	@include mq("lg") {			
		margin-top: var(--spacing--md);
		margin-bottom: var(--spacing--xl);
	}
}

.BranchContactInfo_sectionWrapper{	
	@include mq("lg") {	
		display: flex;
    	justify-content: space-between;
	}
}

.BranchContactInfo_section{
	display: flex;
    flex-flow: column;
	background-color: var(--color-tertiary-lighter);	
	padding: var(--spacing--md);
	width: 100%;
	border-bottom: var(--border--sm) solid var(--color-tertiary);	
	color: var(--color-primary);	
	&:last-of-type{
		border-bottom: 0
	}
	
	@include mq("lg") {	
		padding: 4rem 4.1rem;
		border-bottom: 0;
		border-right: var(--border--sm) solid var(--color-tertiary);
		&:last-of-type{
			border-right: 0
		}
	}
}
.BranchContactInfo_weekdays{
	display: inline-block;
	width: 50%;	
	&___phoneNumbers{
		font-weight: bold;
		position: relative;
		top: 2px;
		margin-right: 1rem;
		@include mq("lg") {	
			width: 8rem;
			margin-right: 3rem;
		}
	}
}
.BranchContactInfo_weekdaysHours{
	display: inline-block;
	width: 50%;	
}
.BranchContactInfo_sectionTitle{	
	@include t-body;
	text-transform: uppercase;
	font-weight: var(--font-weight--bold);
	margin-bottom: var(--spacing--md);
	@include mq("lg") {	
		margin-bottom: var(--spacing--xl);
	}
}
.BranchContactInfo_phoneNumbers{
	margin-top: -5px;
	width: 100%;
}
.BranchContactInfo_phoneWrapper{
	display: flex;	
	margin-bottom: var(--spacing--sm);
}
.BranchContactInfo_emailWrapper{
	display: flex;
	align-items: center;
	margin-bottom: var(--spacing--sm);
}
.BranchContactInfo_email,
.BranchContactInfo_phone
{
	color: var(--color-primary);
	@include t-body;
	display: inline-block;		
	position: relative;
	text-decoration: none;	
	align-items: center;	
	max-width: fit-content;
	cursor: pointer;
	&:after {
		@include transition {
			transition: width var(--trans-time--standard) ease;
		}
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		margin: auto;
		width: 0%;
		content: ".";
		color: transparent;
		background: var(--color-primary);
		height: 1px;
	}
	&:hover:after {
		width: 100%;
	}		
}
.BranchContactInfo_addressWrapper{
	display: flex;	
	margin-bottom: var(--spacing--xs);
	align-items: flex-start;
}

.BranchContactInfo_addressDetail{
	margin-top: 5px;	
}
.BranchContactInfo_iconEmail{
	width: 20px;
	height: 15px;
	margin-right: 10px;
	circle, path{
		fill: transparent;
	}
}
.BranchContactInfo_iconPhone{
	width: 20px;
	height: 20px;
	margin-right: 10px;
	circle, path{
		fill: transparent;
	}
}
.BranchContactInfo_iconAddress{
	min-width: 20px;
	width: 20px;
	height: 30px;
	margin-right: 10px;
	circle, path{
		fill: transparent;
	}
}
.BranchContactInfo_cityTitle{
	font-weight: bold;
	margin-right: 10px;
}

.BranchContactInfo_weekdaysItems{
	display: flex;
	margin-bottom: .6rem;	
	&___phoneNumbers{
		justify-content: left;
	}
}

