@import "ui/styles/2-tools/tools.behavior.scss";
@import "ui/styles/2-tools/tools.typography-mixins.scss";

.ViewToggler {
	justify-content: space-between;
	border-right: 1px solid var(--color-primary);
	border-color: #000;
	padding-left: 1rem;
	margin-right: 14rem;
	display: none;

	@include mq("md") {
		display: flex;
		margin-right: 25rem;
	}

	// flex-direction: column-reverse;
}

.ViewToggler_button {
	display: flex;
	align-items: center;
	padding: 0;
	color: var(--color-primary);
	font-size: var(--font-size--md);
	font-weight: 600;
	background: none;
	border: 0;
	z-index: 1;
	border: 1px solid transparent;
	border-radius: 5px;
	margin-right: var(--spacing--sm);
	justify-content: center;
	width: 5.4rem;
	height: 5.4rem;
	opacity: 0.5;

	&_isActive {
		color: var(--color-primary);
		border-color: var(--color-primary);
		opacity: 1;
	}
}

.ViewToggler_icon {
	min-width: 2.1rem;
	min-height: 2.1rem;
	margin-top: -0.2rem;
	cursor: pointer;
}

.ViewToggler_buttonText {
	display: none;
	@include mq("md") {
		display: block;
	}
}
