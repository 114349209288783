.ArticleDate,
.ArticleUpdatedDate {
	color: var(--color-primary);
	font-size: var(--font-size--sm);
	line-height: 2.5rem;
}

.ArticleUpdatedDate {
	text-align: right;
	padding: 0 var(--spacing--sm);
	margin-left: auto;
	padding-left: 1rem;
}
