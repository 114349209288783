@import "ui/styles/2-tools/tools.typography-mixins.scss";
@import "ui/styles/2-tools/tools.behavior.scss";

.NewsThumbnail{
	margin-bottom: var(--spacing--3xl);
}
.NewsThumbnail_pattern{
	display: none;
	@include mq("md") {
		display: block;
		position: absolute;
		bottom: -3rem;
		width: 13rem;
		height: 40rem;
		right: 0;
		top: 6.5rem;
	}
}
.NewsThumbnail_newsLIst{
	width: 100%;
    display: flex;
    flex-wrap: wrap;
}
.NewsThumbnail_hits{
	display: inline-block;
	color: var(--color-primary);
	font-size: var(--font-size--xl);	
	font-weight: var(--font-weight--bold);
	margin-bottom: 5rem;
}
