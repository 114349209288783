@import "ui/styles/2-tools/tools.media-query.scss";
@import "ui/styles/2-tools/tools.typography-mixins.scss";
@import "ui/styles/2-tools/tools.grid-mixins.scss";
@import "ui/styles/2-tools/tools.mixins.scss";
@import "ui/styles/2-tools/tools.space.scss";

.AboutSection {
	width: 100%;
	color: var(--text-primary);	
	background-color: var(--module-bg-primary);
	@include full-width-bg(#000064);
	@include module-seperator;
	&___revert {
		@include full-width-bg(#f6f6f7);
		background-color: var(--color-tertiary-lighter);
		color: var(--color-primary);
	}
}

.AboutSection_container {
	display: block;
	@include grid__container();
	@include mq("md") {
		display: flex;
		justify-content: space-between;
	}
}
.AboutSection_grid {
	.AboutSection___flip & {
		flex-direction: row-reverse;
	}
}
.AboutSection_contentWrapper {
	margin-left: auto;
	margin-right: auto;
	max-width: none;
	text-align: start;

	@include mq("md") {
		margin: 0;
		padding-right: 7rem;
		.AboutSection___flip & {
			padding-right: var(--grid-gutter) !important;
		}
		.AboutSection_header {
			@include heading--lg;
			margin-bottom: var(--spacing--lg);
		}
	}
}

.AboutSection_media {
	display: block;
	margin-bottom: 0.5em;

	/**
	* Show the card using flexbox rows
	* AKA the Flag Object or Media Object
	* https://philipwalton.github.io/solved-by-flexbox/demos/media-object/
	*/
	@include mq("sm") {
		.ThumbnailCard___row &,
		.ThumbnailCard___rowReverse & {
			width: 50%;
		}
	}
}
.AboutSection_imageWrapper {
	width: 100%;
	@include mq("md") {
		padding-left: 10% !important;
		.AboutSection___flip & {
			padding-left: var(--grid-gutter) !important;
			padding-right: 10% !important;
		}
	}
}
.AboutSection_mediabox {
	@include aspect-ratio(4, 3);
	border-radius: 0 0 0 2rem;
}

.AboutSection_heading {
	@include heading--lg();
}

.AboutSection_text {
	color: rgba(255, 255, 255, 0.75) !important;
	margin: var(--spacing--base) 0;
	.AboutSection___revert & {
		color: var(--color-primary) !important;
	}
}

.AboutSection_buttonWrapper {
	margin: 0;
	width: 100%;
	display: inline-block;
	margin-bottom: var(--spacing--base);
	.AboutSection_button {
		width: 100%;
	}

	@include mq("md") {
		width: auto;
	}

	.AboutSection___spacingTop {
		margin-block-start: var(--spacing--base);
	}
}

.AboutSection_link {
	.AboutSection___revert & {
		color: var(--button-text-primary);
		background: transparent;
		border: var(--border--md) solid var(--color-primary);

		&:hover,
		&:focus {
			box-shadow: inset 0 -4.5em 0 0 var(--button-bg-tertiary-hover);
		}
	}
}
