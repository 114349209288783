@import "ui/styles/2-tools/tools.media-query.scss";
@import "ui/styles/2-tools/tools.aspect-ratio.scss";
@import "ui/styles/2-tools/tools.behavior.scss";

.ThumbnailCard {
	position: relative;
	height: 100%;
	margin-bottom: calc(var(--grid-gutter) * 2);

	/**
	* Show the card using flexbox rows
	* AKA the Flag Object or Media Object
	* https://philipwalton.github.io/solved-by-flexbox/demos/media-object/
	*/
	@include mq("sm") {
		&___row {
			display: flex;
			flex-direction: row;
		}

		&___rowReverse {
			display: flex;
			flex-direction: row-reverse;
		}
	}

	&___bordered {
		border: var(--border--sm) solid var(--color-gradient--10);
	}

	&___padded {
		padding: var(--spacing--md);
	}

	&___padded-x2 {
		padding: var(--spacing--xl);
	}
}

.ThumbnailCard___link {
	display: block;
	text-decoration: none;
	color: var(--color-black);
	border-radius: var(--corner-size--xs);
	box-shadow: 0 0 0.2em var(--shadow-color--mild);
	margin-bottom: var(--spacing--sm);
	@include mq("md") {
		margin-bottom: 0;
	}
}

.ThumbnailCard_textbox {
	color: var(--color-primary);
	/**
	* Show the card using flexbox rows
	* AKA the Flag Object or Media Object
	* https://philipwalton.github.io/solved-by-flexbox/demos/media-object/
	*/
	@include mq("sm") {
		.ThumbnailCard___row &,
		.ThumbnailCard___rowReverse & {
			width: 50%;
		}
		.ThumbnailCard___row & {
			padding-left: calc(var(--grid-gutter) * 2);
		}

		.ThumbnailCard___rowReverse & {
			padding-right: calc(var(--grid-gutter) * 2);
			text-align: right;
		}
	}
}

.ThumbnailCard_kicker {
	font-size: var(--font-size--sm);
	text-transform: uppercase;
	margin-bottom: 0.5em;
	color: var(--color-primary);
	font-weight: var(--font-weight--bold);
}

.ThumbnailCard_text p {
	margin-bottom: 1em;

	&:last-of-type {
		margin-bottom: 0;
	}
}

.ThumbnailCard_action {
	margin-top: 1em;
}

.ThumbnailCard_link {
	display: block;
	color: inherit;
	text-decoration: none;	
}

.ThumbnailCard_media {
	display: block;
	margin-bottom: 0.5em;

	/**
	* Show the card using flexbox rows
	* AKA the Flag Object or Media Object
	* https://philipwalton.github.io/solved-by-flexbox/demos/media-object/
	*/
	@include mq("sm") {
		.ThumbnailCard___row &,
		.ThumbnailCard___rowReverse & {
			width: 50%;
		}
	}	
}

.ThumbnailCard_mediabox {
	@include aspect-ratio(4, 3);
	border-radius: 0 var(--corner-size--md) 0 0;	
}

.ThumbnailCard_picture{
	@include transition {
		transition: transform var(--trans-time--standard) ease;
	}
	.ThumbnailCard___link:hover &,
	.ThumbnailCard___link:focus & {
		transform: scale(1.05);
	}
}
.ThumbnailCard_date {
	font-size: var(--font-size--sm);
	margin-bottom: 0.5em;
}

.ThumbnailCard_button {
	margin-top: var(--spacing--base);	
}


// theme-def custome style
:global(.theme-def) {
	.ThumbnailCard{
		padding: 0;
		max-width: 100%;
		width: 100%;		
	}
}
// theme-def custome style
:global(.theme-medlem) {
	.ThumbnailCard{
		padding: 0;
		max-width: 100%;
		width: 100%;		
	}
}
