@import "ui/styles/2-tools/tools.display-mixins.scss";
@import "ui/styles/2-tools/tools.media-query.scss";
@import "ui/styles/2-tools/tools.behavior.scss";
@import "ui/styles/2-tools/tools.space.scss";
@import "ui/styles/2-tools/tools.typography-mixins-tillidsportal.scss";

.ListVisitCard {
	animation: fadeInDown 500ms ease-in-out;
	background: var(--color-primary);	
	width: 100%;
	position: relative;
	@include module-seperator;		
}
@keyframes fadeInDown {
    0% {
        opacity: 0;
        transform: translateY(-5px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}
.ListVisitCard_wrapper{
	display: grid;
	grid-gap: var(--spacing--md);	   
	@include mq("md") {
		grid-gap: var(--spacing--xl) var(--spacing--md);
		grid-template-columns: 1fr 1fr;
	}
	@include mq("lg") {
		grid-template-columns: 1fr 1fr 1fr;
	}	
}
.ListVisitCard_pattern{
	display: none;
	@include mq("lg") {
		display: block;
		position: absolute;
		bottom: -3rem;
		width: 39.6rem;
		height: 25rem;
		right: 0rem;
		top: -2.5rem;
	}
}
.ListVisitCard_heading{
	@include t-h2;
	color: var(--color-light);
	margin-bottom: var(--spacing--md);
	@include mq("md-lg") {
		width: 50%;
		margin-bottom: var(--spacing--xl);
	}
}
.ListVisitCard_manchet{
	color: white;
    margin-bottom: 2rem;
}
.ListVisitCard_ContactCard {	
	background-color: var(--color-tertiary-lighter);
	border-radius: 5px;
	padding: 2.5rem;
	
	@include mq("md") {	
		display: flex;
		justify-content: space-between;
		gap: 2rem;
		height: 100%;
	}

}

.ListVisitCard_header{
	color: var(--text-secondary);
	padding: 0 0 var(--spacing--xs) 0;	
}
.ListVisitCard_body {
	@include display-flex;
	flex-direction: column;
	justify-content: space-between;
	margin-top: 1rem;
	padding-top: 1rem;
	border-top: 1px solid var(--color-tertiary);
	svg {
		fill: transparent;
	}
}

.ListVisitCard_row{
	display: flex;
	margin: var(--spacing--xs) 0;
}
.ListVisitCard_link{
	display: inline-block;
	position: relative;
	color: var(--text-secondary);
	font-size: var(--font-size--sm);	
	padding-right: var(--spacing--xs);	
	text-decoration: none;
	max-width: fit-content;
	cursor: pointer;
	@include transition {
		transition: color var(--trans-time--standard) ease;
	}		
	&:after {
		@include transition {
			transition: width var(--trans-time--standard) ease;
		}
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		margin: auto;
		width: 0%;
		content: ".";
		color: transparent;
		background: var(--color-primary);
		height: 1px;
	}
	&:hover:after {
		width: 100%;
	}
}
.ListVisitCard_icon{
	margin-right: var(--spacing--xs);
	min-width: 2rem;
}
.ListVisitCard_mediabox{
	display: block;
	width: 100%;
	height: auto;
    margin-bottom: 2rem;
	overflow: hidden;
	border-radius: 0 var(--corner-size--lg) 0 0;
	@include mq("md") {	
		margin-bottom: 0em;
	}
}
.ListVisitCard_details{
	@include mq("md") {	
		width: 100%;	
	}	
}



