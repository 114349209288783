@import "ui/styles/2-tools/tools.aspect-ratio.scss";
@import "ui/styles/2-tools/tools.media-query.scss";
@import "ui/styles/2-tools/tools.typography-mixins.scss";
@import "ui/styles/2-tools/tools.mixins.scss";
@import "ui/styles/2-tools/tools.space.scss";

.Video {	
    margin: auto;
	position: relative;
	height: 100%;	
	background-color: var(--color-light);
	width: 100%;
	@include module-seperator;
	/**
	* Show the card using flexbox rows
	* AKA the Flag Object or Media Object
	* https://philipwalton.github.io/solved-by-flexbox/demos/media-object/
	*/
	@include mq("sm") {		
		&___row {
			display: flex;
			flex-direction: row;
		}

		&___rowReverse {
			display: flex;
			flex-direction: row-reverse;
		}
	}

	&___bordered {
		border: var(--border--sm) solid var(--color-gradient--10);
	}

	&___padded {
		padding: var(--spacing--md);
	}

	&___padded-x2 {
		padding: var(--spacing--xl);
	}
}

.Video_textbox {
	text-align: center;
	margin-bottom: var(--spacing--xl);
	/**
	* Show the card using flexbox rows
	* AKA the Flag Object or Media Object
	* https://philipwalton.github.io/solved-by-flexbox/demos/media-object/
	*/
	@include mq("sm") {	
		width: 40%;	
    	margin: auto;
		.Video___row &,
		.Video___rowReverse & {
			width: 66.6666666666%;
		}
		.Video___row & {
			padding-left: calc(var(--grid-gutter) * 2);
		}

		.Video___rowReverse & {
			padding-right: calc(var(--grid-gutter) * 2);
		}
	}
}

.Video_kicker {
	font-size: var(--font-size--sm);
	text-transform: uppercase;
	margin-bottom: 0.5em;
	color: var(--color-primary);
	font-weight: var(--font-weight--bold);
}

.Video_heading {
	margin-bottom: 0.5em;
}
.Video_title{
	@include heading--lg();
	margin-bottom: var(--spacing--sm);
	color: var(--color-primary);
}
.Video_text{
	@include paragraph();
	margin-bottom: var(--spacing--lg);
	color: var(--color-primary);
}
.Video_text p {
	margin-bottom: 1em;

	&:last-of-type {
		margin-bottom: 0;
	}
}

.Video_action {
	margin-top: 1em;
}

.Video_link {
	display: block;
	color: inherit;
	text-decoration: none;
	margin: auto;
}

.Video_media {
	display: block;
	margin-bottom: 0.5em;

	/**
	* Show the card using flexbox rows
	* AKA the Flag Object or Media Object
	* https://philipwalton.github.io/solved-by-flexbox/demos/media-object/
	*/
	@include mq("sm") {
		width: 50%;  
		margin: auto;
		.Video___row &,
		.Video___rowReverse & {
			width: 33.3333333333%;
		}
	}
}

.Video_mediabox {
	@include aspect-ratio(16, 9);
}

.Video_date {
	font-size: var(--font-size--sm);
	margin-bottom: 0.5em;
}


// theme-tillidsportal custome style
:global(.theme-tillidsportal){
	.Video_textbox{
		width: 100%;
		margin: unset;
		text-align: left;
	}
	.Video_media{
		margin: unset;
    	width: 100%;
	}	
}