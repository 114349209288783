@import "ui/styles/2-tools/tools.typography-mixins.scss";
@import "ui/styles/2-tools/tools.behavior.scss";

.FilterWrapper {
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 1;
	background: var(--color-light);
	color: var(--color-primary);
	padding: 2rem;
	box-shadow: 0px 0px 22px 0px rgba(0, 0, 0, 0.38);
	color: var(--color-primary);
	z-index: 2;

	@include mq("md") {
		padding: 8rem;
		flex-direction: row;
	}

	&__narrow {
		padding: 6rem 3rem 0rem;
		@include mq("md") {
			right: 0;
			width: 100%;
			max-width: 40rem;
			padding: 8rem 4rem 2rem 6rem;
		}
	}
}

.FilterWrapper_overlay {
	@include mq("md") {
		// expanded nav
		&[aria-expanded="true"] {
			position: fixed;
			top: 0;
			left: 0;
			width: 100vw;
			height: 100vh;
			background: var(--color-tertiary);
			opacity: 0.25;
			z-index: 1;
		}
	}
}

.FilterWrapper_buttonText {
	display: none;
	@include mq("md-lg") {
		display: block;
	}
}
.FilterWrapper_button {
	display: flex;
	align-items: center;
	padding: 0;
	color: var(--color-primary);
	font-size: var(--font-size--md);
	font-weight: 600;
	position: absolute;
	// right: 18rem;
	background: none;
	border: 0;
	z-index: 1;
	right: 0;
	margin-right: 0;
	top: 1.5rem;
	@include mq("sm") {
		margin-right: var(--spacing--sm);
		right: 1rem;
	}
}

.FilterWrapper_iconOpen {
	width: 5.4rem;
	height: 5.4rem;
	cursor: pointer;
	background-color: var(--color-tertiary-lighter);
	border-radius: var(--corner-circle);
	margin-left: var(--spacing--sm);
	svg {
		fill: white;
		width: auto;
		height: auto;
	}
	@include transition {
		transition: transform var(--trans-time--standard) ease;
	}
	.FilterWrapper_button:hover & {
		transform: rotate(90deg); /* Equal to rotateZ(45deg) */
	}
}
.FilterWrapper_iconClose {
	width: 5.4rem;
	height: 5.4rem;
	cursor: pointer;
	background-color: var(--color-tertiary-lighter);
	border-radius: var(--corner-circle);
	margin-left: var(--spacing--sm);
	svg {
		width: auto;
		height: auto;
	}
	@include transition {
		transition: transform var(--trans-time--standard) ease;
	}
	.FilterWrapper_button:hover & {
		transform: rotate(90deg); /* Equal to rotateZ(45deg) */
	}
}
