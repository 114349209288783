@import "ui/styles/2-tools/tools.media-query.scss";
@import "ui/styles/2-tools/tools.typography-mixins.scss";
@import "ui/styles/2-tools/tools.grid-mixins.scss";
@import "ui/styles/2-tools/tools.space.scss";
@import "ui/styles/2-tools/tools.mixins.scss";

.NewsModule {
	width: 100%;
	color: var(--text-primary);
	background-color: var(--module-bg-primary);
	@include full-width-bg(#000064);
	@include module-seperator;
}

.NewsModule_inner {
	width: 100%;
	height: 100%;
}

.NewsModule_container {
	display: block;
	@include mq("md") {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}
}

.NewsModule_contentWrapper {
	margin-left: auto;
	margin-right: auto;
	max-width: none;
	text-align: start;
}

.NewsModule_media {
	display: block;
	margin-bottom: 0.5em;

	/**
	* Show the card using flexbox rows
	* AKA the Flag Object or Media Object
	* https://philipwalton.github.io/solved-by-flexbox/demos/media-object/
	*/
	@include mq("sm") {
		.ThumbnailCard___row &,
		.ThumbnailCard___rowReverse & {
			width: 50%;
		}
	}
}

.NewsModule_mediabox {
	@include aspect-ratio(16, 9);
	margin-bottom: 1rem;
}

.NewsModule_heading {
	@include heading--lg();
	width: 100%;
	margin-bottom: 2rem;
	@include mq("sm") {
		margin-bottom: 3rem;
	}
}
.NewsModule_manchet {
	font-size: var(--font-size--2xl);
	font-weight: var(--font-weight--bold);
}
.NewsModule_manchetDate {
	font-size: var(--font-size--sm);
	opacity: 0.75;
}

.NewsModule_listItems {
	list-style-type: none;
	border-bottom: 1px solid #423f3f;
	padding: 2rem 0;
	&:first-of-type {
		border-top: 1px solid #423f3f;
	}
}
.NewsModule_listManchet {
	font-size: var(--font-size--lg);
	font-weight: var(--font-weight--bold);
}
.NewsModule_listDate {
	font-size: var(--font-size--sm);
	opacity: 0.75;
	margin-bottom: 0.5rem;
	display: inline-block;
}
.NewsModule_text {
	color: rgba(255, 255, 255, 0.75);
	margin: var(--spacing--base) 0;
}

.NewsModule_imageWrapper {
	width: 100%;
	margin-bottom: 3rem;
	@include mq("md") {
		width: 45%;
	}
}

.NewsModule_buttonWrapper {
	margin: 0 auto;
	width: 100%;
	display: inline-block;
	margin-bottom: var(--spacing--base);
	margin-top: 7rem;
	display: flex;
	justify-content: center;
	.NewsModule_button {
		width: 100%;
	}

	@include mq("md") {
		width: auto;
	}
}
