@import "ui/styles/2-tools/tools.media-query.scss";

.Section {	

	&___centered {
		display: flex;
		justify-content: center;
		text-align: center;
	}
}
