@import "ui/styles/2-tools/tools.media-query.scss";
@import "ui/styles/2-tools/tools.typography-mixins.scss";
@import "ui/styles/2-tools/tools.mixins.scss";

.Quote {
	position: relative;
	width: 100%;
	color: var(--text-invert-color);
	background-color: var(--module-bg-primary);
	@include full-width-bg(#000064);
	padding: calc(var(--grid-gutter) * 5) 0;
	@include mq("md-lg") {
		padding: calc(var(--grid-gutter) * 10) 0;
	}
}
.Quote_pattern{
	display: none;
	@include mq("lg") {
		display: block;
		position: absolute;
		bottom: -3rem;
		width: 20rem;
		height: 40rem;
		right: -2.5rem;
		top: 22rem;
	}
}
.Quote_figure{
	@include mq("lg") {
		max-width: 75vw;
	}
}
.Quote_blockquote {
	font-size: var(--font-size--2xl);
	line-height: 30px;
	margin-bottom: var(--spacing--lg);
	@include mq("md") {
		font-size: var(--font-size--4xl);
		line-height: 46px;
	}
	&:before {
		content: open-quote;
	}
	&:after {
		content: close-quote;
	}
	&:before,
	&:after {
		display: inline-block;
		vertical-align: bottom;
		position: relative;
	}
}
.Quote_figcaption {
	font-size: var(--font-size--md);
	line-height: 26px;
	font-style: normal;
	@include mq("md") {
		font-size: var(--font-size--lg);
	}
}
.Quote_cite{
	font-style: normal;
}
.Quote_mediabox {
	display: block;
	width: 90px;
	height: 90px;
	overflow: hidden;
	margin-bottom: var(--spacing--lg);
	border-radius: var(--corner-circle);

	@include mq("md") {
		width: 205px;
		height: 205px;
	}
}
.Quote_link {
	margin-top: var(--spacing--lg);
}
