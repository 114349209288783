@import "ui/styles/2-tools/tools.typography-mixins.scss";
@import "ui/styles/2-tools/tools.display-mixins.scss";

.FormLabel {
	@include paragraph;
	display: flex;
	width: 100%;
	padding: var(--spacing--2xs) 0;	

	&.hasError {
		color: var(--color-error);
	}

	&.isDisabled {
		color: var(--color-gradient--50);
		cursor: auto;
	}

	&.isHidden {
		display: none;
	}

	&.isRequired {
		color: var(--color-error);
		margin-left: var(--spacing--2xs);
	}
}
