@import "ui/styles/2-tools/tools.media-query.scss";
@import "ui/styles/2-tools/tools.typography-mixins-tillidsportal.scss";

@import "ui/styles/2-tools/tools.behavior.scss";
.button {
	position: relative;

	padding: 13px 20px;
	border: 1px solid var(--color-primary);
	flex: 0 0 auto;

	background-color: white;

	display: inline-flex;

	color: var(--color-primary);

	@include t-body-s;
	font-weight: 600;

	@include mq("md") {
		width: 167px;
		height: 88px;
		padding: 0px;

		.contentWrapper {
			position: absolute;
			top: 16px;
			right: 16px;
			bottom: 16px;
			left: 16px;

			overflow: hidden;
		}

		// Figma design uses custom font style for desktop
		font-family: var(--font-primary);
		font-size: pxToRem(14);
		font-weight: 700;
		line-height: pxToRem(17);
		letter-spacing: 0;
	}
	@include transition {
		transition: background-color var(--trans-time--standard) ease;
	}
	&:hover {
		color: white;
		background-color: var(--color-primary);
	}

	&___links {
		position: absolute;
		left: -9999px;
		width: 1px;
		height: 1px;
		overflow: hidden;
	}
}

.button___selected {
	color: white;
	background-color: var(--color-primary);

	&:hover {
		color: white;
		background-color: var(--color-primary);
	}
}
