@import "ui/styles/2-tools/tools.media-query.scss";
@import "ui/styles/2-tools/tools.display-mixins.scss";
@import "ui/styles/2-tools/tools.space.scss";
@import "ui/styles/2-tools/tools.typography-mixins.scss";

.ThumbnailList{
	position: relative;
	background-color: var(--color-light);
	width: 100%;
	@include module-seperator;
}

.ThumbnailList_inner{
	width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.ThumbnailList_pattern{
	display: none;
	@include mq("md") {
		display: block;
		position: absolute;
		bottom: -3rem;
		width: 35rem;
		height: 12rem;
		left: 0;
		top: -2.5rem;
	}
	@include mq("xl") {
		height: 40rem;
	}
}
.ThumbnailList_list{
	margin-bottom: var(--spacing--lg)
}
.ThumbnailList_heading{
	@include heading--lg();
	color: var(--color-primary);
	padding-right: var(--grid-gutter);
    padding-left: var(--grid-gutter);
	margin-bottom: var(--spacing--sm);
}
.ThumbnailList_subheading{	
	@include paragraph();	
	color: var(--color-primary);
	padding-right: var(--grid-gutter);
    padding-left: var(--grid-gutter);
	margin-bottom: var(--spacing--lg);
	@include mq("md") {
		width: 50%; 
	}
}

.ThumbnailList_link{
	color: var(--color-primary);
	display: table;
	width: 100%;
	font-size: var(--font-size--sm);
	@include mq("md") {
		width: auto;
		margin: var(--spacing--2xl) auto;  
	}
}

// theme-def custome style
:global(.theme-def) {
	.ThumbnailList{
		padding: 0;
		@include mq("md") {
			padding: 3rem 0;
		}		
		&:last-of-type{
			padding-bottom: 12rem;
		}
	}
	.ThumbnailList_heading{
		padding-top: 12rem;
		padding-left: 0;
	}
	.ThumbnailList_inner{
		display: grid;
		grid-gap: 1rem;
		@include mq("md") {
			grid-gap: 1.5rem;
			grid-template-columns: 1fr 1fr 1fr;
		}
	}
	
	.ThumbnailList_subheading{
		padding: 0;
	}
	.ThumbnailList_pattern{
		display: none
	}
}
// theme-def custome style
:global(.theme-medlem) {
	.ThumbnailList{
		padding: 0;
		@include mq("md") {
			padding: 3rem 0;
		}		
		&:last-of-type{
			padding-bottom: 12rem;
		}
	}
	.ThumbnailList_heading{
		padding-top: 12rem;
		padding-left: 0;
	}
	.ThumbnailList_inner{
		display: grid;
		grid-gap: 1rem;
		@include mq("md") {
			grid-gap: 1.5rem;
			grid-template-columns: 1fr 1fr 1fr;
		}
	}
	
	.ThumbnailList_subheading{
		padding: 0;
	}
	.ThumbnailList_pattern{
		display: none
	}
}