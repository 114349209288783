@import "ui/styles/2-tools/tools.behavior.scss";
@import "ui/styles/2-tools/tools.media-query.scss";

.Modal {	
	z-index: var(--z-index--modal);
	position: fixed;
	width: 100vw;
	max-width: 100%;
	height: 100vh;
	align-items: center;
	justify-content: center;
	visibility: visible;
	display: flex;
	top: 0;
	left: 0;
	transition-delay: unset;
	padding:0;		
	
}

.Modal_overlay {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0,0,0, .8);
	opacity: 1;

	@include transition {
		transition: var(--trans-time--standard) opacity ease;
	}
}

.Modal_modal {
	animation: fadeInDown 500ms ease-in-out;
	width: 100%;
	height: 100vh;
	background-color: var(--color-primary);
	z-index: 1;
	transform: scale(1);
	opacity: 1;
	padding: 0 var(--spacing--sm) var(--spacing--sm);
	box-shadow: 0.1em 0.1em 0.2em var(--shadow-color--mild);
	overflow-y: auto;
	text-align: left;
	
	@include mq("md-lg") {	
		width: auto;
		height: auto;
		max-height: 100%;	
		max-width: 70vw;
		padding: var(--spacing--2xl);
	}	
	@include transition {
		transition: var(--trans-time--shorter) transform ease, var(--trans-time--shorter) opacity ease;
	}
}
@keyframes fadeInDown {
    0% {
        opacity: 0;
        transform: translateY(-10px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}
.Modal_accept {
	margin-top: var(--spacing--xl);
}
.Modal_close {
	position: absolute;
	background-color: transparent;
	outline: 0;
	right: 0;
	top: 2.5rem;
	cursor: pointer;
	padding: 0;
	border: 0;
	@include mq("md-lg") {		
		right: 3rem;
		top: 4rem;
	}	
}
.Modal_top{
    position: sticky;
    top: 0rem;
    padding: 1.2rem 0;
    z-index: 1;   
    background-color: var(--color-primary);
	@include mq("md-lg") {	
		position: static;
		padding: 0;
		height: auto;
	}
}
.Modal_iconClose{
	width: 4rem;
	height: 4rem;
	cursor: pointer;
	background-color: transparent;
	border-radius: var(--corner-circle);	
	svg {
		width: auto;
		height: auto;
	}
	path{
		stroke: var(--color-light)
	}
	@include transition {
		transition: transform var(--trans-time--standard) ease;
	}
	.Modal_close:hover & {
		transform: rotate(90deg); /* Equal to rotateZ(45deg) */
	}
}
.Modal_title{	
	font-size: 2.6rem;
	color: var(--color-light);
	text-align: center;
	margin-top: 5rem;
	@include mq("md-lg") {	
		margin-top: unset;
		font-size: 4rem;
		position: relative;
    	top: -3rem;
	}	
}
.Modal_logo{
	position: relative;
	top: 0.8rem;
    left: 0.5rem;
	outline: 0;
	@include mq("md-lg") {	
		display: none;
	}	
}
