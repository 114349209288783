@import "ui/styles/2-tools/tools.media-query.scss";

.container {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	gap: 10px;

	overflow-x: auto;

	@include mq("md") {
		flex-wrap: wrap;
		gap: 20px;

		overflow-x: unset;
	}
}
