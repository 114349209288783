@import "ui/styles/2-tools/tools.media-query.scss";
@import "ui/styles/2-tools/tools.display-mixins.scss";
@import "ui/styles/2-tools/tools.typography-mixins.scss";

.SuperHeroCalendarCard {
	position: relative;
	height: 100%;	
	color: var(--color-light);
	text-align: center;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 4em;
    padding-bottom: 0;
	@include mq("md") {
		margin-bottom: 5em;	
	}	
}
.SuperHeroCalendarCard_pattern {
	display: none;
	@include mq("md") {
		width: auto;
		height: auto;
		position: absolute;
		bottom: -2.4rem;		
	}	
}

.SuperHeroCalendarCard_heading {
	@include heading--xl();
	color: var(--color-light);
	margin-bottom: var(--spacing--xl);	
}

.SuperHeroCalendarCard_textbox {
	display: flex;
	justify-content: center;
	flex-direction: column;
	padding: var(--spacing--xl);	
	padding-top: var(--spacing--3xl);	
	margin: auto;
	margin-bottom: var(--spacing--3xl);    
	position: relative;
    top: 5rem;
	@include mq("md") {	
		max-width: 50%; 		
	}
}

.SuperHeroCalendarCard_bottomPadding{
	padding-bottom: 5rem;
    background: var(--color-light);
	@include mq("md") {
		padding-bottom: 25rem;	
	}
}

.SuperHeroCalendarCard_text{
	@include manchet();  
}

.SuperHeroCalendarCard_mediaboxTop_wrapper{
	position: fixed;
	z-index: -1;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: var(--transparent-color--primary)
}
.SuperHeroCalendarCard_mediaboxTop {
	opacity: .1;
}

.SuperHeroCalendarCard_mediabox {
	background-color: var(--color-light);	
	margin: auto;	
	max-width: 124rem;
	max-height: 74%;
	z-index: 2;
	@include mq("md") {
		margin-bottom: -25rem;	
	}
}

.SuperHeroCalendarCard_kicker {
	position: relative;
	top: unset;
	left: unset;
	text-transform: uppercase;
	font-size: var(--font-size--sm);
	font-weight: var(--font-weight--semibold);
	margin-bottom: 1em;
	color: var(--color-light);
}

.SuperHeroCalendarCard_link {
	display: block;
	color: inherit;
	text-decoration: inherit;
}
