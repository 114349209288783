@import "ui/styles/2-tools/tools.media-query.scss";
@import "ui/styles/2-tools/tools.display-mixins.scss";
@import "ui/styles/2-tools/tools.typography-mixins.scss";
@import "ui/styles/2-tools/_tools.typography-mixins-tillidsportal.scss";

.TopBanner {
	display: flex;
	width: 100%;
	height: 100vh;  
	position: relative;	
	color: var(--color-light);
	text-align: center;
	padding-bottom: var(--spacing--2xl);
	overflow: hidden;	
	@include mq("md") {
		height: 101vh; 
	}
}

.TopBanner_heading {
	@include t-h1();
	color: var(--color-light);
	margin-bottom: var(--spacing--lg);		
	@include text-shadow;
	@include mq("md") {
		text-align: center;
	}
}

.TopBanner_text{
	@include manchet();
	color: var(--color-light);
	margin-bottom: var(--spacing--lg);	
	@include text-shadow;
}
.TopBanner_cta{
	display: grid;
    grid-auto-columns: minmax(0, 1fr);
    grid-auto-flow: column;
    align-self: center;
    gap: 19px;
    justify-content: center;
    width: 100%;
	margin-bottom: 10rem;		
	@include mq("md") {
		display: flex;
		margin-bottom: 0;	
	}	
	&___stacked{
		display: grid;
		grid-auto-flow: row;		
	}

}
.TopBanner_content {
	display: flex;
	justify-content: center;
	flex-direction: column;
	text-align: left;	
	margin: 50vh auto;
	margin-bottom: 0;
	z-index: 1;
	@include mq("md") {
		max-width: 90%;	
		text-align: center;	
		margin: 65vh auto;
	}	
}
.TopBanner_linkPrimary{
	min-width: initial;
	&:hover{
		box-shadow: none;
		background-color: var(--color-secondary-darker);
	}
}
.TopBanner_linkSecondary{
	color: var(--color-light);    
    min-width: initial;
    background: transparent;
    border: 1px solid var(--color-light);
	&:hover{
		box-shadow: none;
		background-color: var(--color-secondary-darker);
	}
}
.TopBanner_mediabox_wrapper{
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;	
}
.TopBanner_mediabox{
	height: calc(100% + 20rem);   
    object-fit: cover;
	@include mq("md") {	
		img{
			object-position: initial!important;
		}
	}	
	
}
.TopBanner_corpBrand {
	width: 5.6rem;
	height: 5.6rem;	
    margin-bottom: 2rem;
	@include mq("md") {
		margin: 2rem auto;
	}	
}
.TopBanner_some{
	display: none;
	@include mq("md") {
		display: block;
		position: absolute;
		top: 35rem;
		left: 5rem;
	}	
}

.TopBanner_bgFilter{
	position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(rgba(0,0,100, .8), rgba(0,0,100, .6), rgba(0,0,100, .4), rgba(0,0,100, .2));
    opacity: 1;
    -webkit-transition: var(--trans-time--standard) opacity ease;
    -moz-transition: var(--trans-time--standard) opacity ease;
    transition: var(--trans-time--standard) opacity ease;
	z-index: 1;
}