@import "ui/styles/2-tools/tools.media-query.scss";
@import "ui/styles/2-tools/tools.display-mixins.scss";
@import "ui/styles/2-tools/tools.typography-mixins.scss";

.SuperHeroSecondaryCard {
	position: relative;
	min-height: 100vh;	
	padding-bottom: var(--spacing--xl);
	color: var(--color-light);
	text-align: center;
}

.SuperHeroSecondaryCard_heading {
	@include heading--xl();
	color: var(--color-light);
	margin-bottom: var(--spacing--sm);	
}

.SuperHeroSecondaryCard_text{
	@include manchet();
	color: var(--color-light);
}
.SuperHeroSecondaryCard_textbox {
	display: flex;
	justify-content: center;
	flex-direction: column;
	text-align: center;
	padding: var(--spacing--sm) var(--spacing--xl);				
	margin: auto;
	margin-bottom: var(--spacing--sm);   
	@include mq("md") {
		margin: 7% auto;
		margin-bottom: var(--spacing--3xl); 
		max-width: 50%; 		
		padding-top: var(--spacing--3xl);
	}
}
.SuperHeroSecondaryCard_mediabox_wrapper{
	position: fixed;
	z-index: -1;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: var(--transparent-color--primary)
}
.SuperHeroSecondaryCard_mediabox {
	opacity: .1;
}

.SuperHeroSecondaryCard_kicker {
	@include manchet();
	position: relative;
	top: unset;
	left: unset;	
	margin-bottom: 1em;
	color: var(--color-light);
}
.SuperHeroSecondaryCard_stickerboxheading{
	@include heading--lg();
	margin-bottom: var(--spacing--sm);
}
.SuperHeroSecondaryCard_stickerboxcontent{
	@include paragraph();
	color: var(--color-primary);
	background: var(--color-light);    
	padding: var(--spacing--lg);	 
	margin: 0 var(--spacing--base) var(--spacing--xl);    
	border-radius: 0 0 0 var(--corner-size--md);	
	text-align: left;
	@include mq("md") {
		max-width: 50rem; 
		margin: 0 auto var(--spacing--xl); 
		transform: translate(30%, 0%);
	}
}

.SuperHeroSecondaryCard_pattern{
	display: none;
	@include mq("md") {
		display: block;
		width: auto;
		height: auto;
		position: absolute;
		top: 8rem;
		right: 0;
	}
}
.SuperHeroSecondaryCard_Corp{	
	width: 17rem;
	height: 17rem;
	margin: auto;
	margin-bottom: 2rem;
	@include mq("md") {
		display: block;	
		position: absolute;		
		left: 6rem;
		top: 8rem;
		width: 17rem;
		height: 17rem;
	}
}

.SuperHeroSecondaryCard_some{
	display: none;
	@include mq("md") {
		display: block;
		position: absolute;
		top: 35rem;
		left: 5rem;
	}	
}

