@import "ui/styles/2-tools/tools.typography-mixins.scss";
@import "ui/styles/2-tools/tools.behavior.scss";
@import "ui/styles/2-tools/tools.typography-mixins-tillidsportal.scss";
@import "ui/styles/2-tools/tools.mixins.scss";
@import "ui/styles/2-tools/tools.space.scss";

.ListLinks {	
	color: var(--color-primary);
	@include module-seperator;
}
.ListLinks_top {
	@include mq("md-lg") {
		max-width: 75% !important;
	}
}
.ListLinks_heading {
	@include t-h2;
	margin-bottom: var(--spacing--sm);
	@include mq("md-lg") {
		margin-bottom: var(--spacing--lg);
	}
}
.ListLinks_text {
	@include t-body;
	margin-bottom: var(--spacing--md);
	@include mq("md-lg") {
		margin-bottom: var(--spacing--xl);
	}
}
.ListLinks_bottom {
	display: grid;
	animation: fadeInDown 500ms ease-in-out;

	.ListLinks___default & {
		grid-gap: 1rem;
		@include mq("md-lg") {
			grid-gap: 1.5rem;
			grid-template-columns: 1fr 1fr 1fr;
		}
	}
	.ListLinks___revert & {
		column-gap: calc(var(--grid-gutter) * 3);	
		
		@include mq("md-lg") {
			grid-template-columns: 1fr 1fr;
		}
	}
	.ListLinks___short & {
		grid-gap: 1rem;		
		@include mq("md-lg") {
			grid-gap: 1.5rem;
			max-width: 65%;
			grid-template-columns: 1fr;
		}
	}
}
.Links___default {
	display: inline-flex;
	justify-content: space-between;
	text-align: left;
	font-size: var(--font-size--md);
	font-weight: bold;
	padding: 2.2rem 2rem;
	&:hover {
		box-shadow: 0px 0px 5px 2px var(--button-bg-tertiary-hover);
	}
	@include mq("md-lg") {
		font-size: var(--font-size--lg);
	}
}
.Links___revert {
	background: none;
	border-bottom: 1px solid var(--color-tertiary-darker);
	padding: var(--spacing--md) var(--spacing--md);
	padding-left: 0;
	border-radius: 0;
	font-weight: normal;
	font-size: var(--font-size--md);
	transform: translate(0);

	@include transition {
		transition: transform var(--trans-time--standard) ease;
	}
	&:hover {
		box-shadow: none;
		background: non;
		transform: translate(2px);
	}
	@include mq("md-lg") {
		font-size: var(--font-size--lg);
	}
}
.Links___short {
	display: inline-flex;
	justify-content: space-between;
	text-align: left;
	background: var(--button-bg-tertiary);
	padding: 2.2rem 2rem;
	font-size: var(--font-size--sm);
	font-weight: normal;
	&:hover {
		box-shadow: 0px 0px 5px 2px var(--button-bg-tertiary-hover);
	}
	@include mq("md-lg") {
		font-size: var(--font-size--md);
	}
}

.Links_iconRight {
	min-width: 20px;
	min-height: 20px;
	transform: translate(0);
	@include transition {
		transition: transform var(--trans-time--standard) ease;
	}
	.Links:hover & {
		transform: translate(5px);
	}
	.Links___revert & {
		display: none;
	}
	.Links___short & {
		display: none;
	}
}
.Links_iconCircle {
	display: none;
	min-width: 26px;
	min-height: 26px;
	transform: translate(0);
	color: transparent;
	@include transition {
		transition: transform var(--trans-time--standard) ease;
	}
	.Links:hover & {
		transform: translate(5px);
	}
	.Links___revert & {
		display: block;
	}
}
.Links_iconExternal {
	display: none;
	min-width: 22px;
	min-height: 22px;
	transform: translate(0);
	color: transparent;
	@include transition {
		transition: transform var(--trans-time--standard) ease;
	}
	.Links:hover & {
		transform: translate(5px);
	}
	.ListLinks___short & {
		display: block;
	}
}

.ListLinks__useBackground {
	// background: var(--button-bg-tertiary);
	@include full-width-bg(#eaeef2);
}
