@import "ui/styles/2-tools/tools.mixins";
@import "ui/styles/2-tools/tools.behavior.scss";

.AccordionFaqItem_triggerWrapper:not(:first-child),
.AccordionFaqItem_panel:not([hidden]) {
	border-top: 1px solid var(--border-color);
}

.AccordionFaqItem_trigger {
	background: none;
	display: block;
	font-size: var(--font-size--xl);
	margin: 0;
	position: relative;
	text-align: left;
	width: 100%;
	outline: none;
	border-style: none;
	padding: 0;

	@include transition {
		transition: background var(--trans-time--standard) ease;
	}

	&:focus:not([aria-expanded="true"]),
	&:hover:not([aria-expanded="true"]) {
		background: var(--accordian-hover);
	}
}

.AccordionFaqItem_trigger:focus .AccordionFaqItem_icon,
.AccordionFaqItem_trigger:hover .AccordionFaqItem_icon {
	border-color: var(--color-primary);
	[aria-expanded="true"] &{
		fill: var(--color-light)
	}
}

.AccordionFaqItem button::-moz-focus-inner {
	border: 0;
}

.AccordionFaqItem_title {
	display: block;
	pointer-events: none;
	padding: calc(var(--spacing--lg) + 5px) 0;
	line-height: var(--line-height--md);
	color: currentColor;
	font-size: var(--font-size--md);
	font-weight: var(--font-weight--bold);
	max-width: 85%;
	@include mq('md') {
		font-size: var(--font-size--xl);
		padding: calc(var(--spacing--xl) + 5px);
	}
}

#accordianIconHorizontal{
	fill: green;
}
.AccordionFaqItem_icon {
	pointer-events: none;
	position: absolute;
	right: 0;
	top: 50%;
	transform: translateY(-60%);
	@include mq('md') {
		right: 2em;
	}
}

.AccordionFaqItem_panel {
	margin: 0;
	padding: calc(var(--spacing--lg) + 5px) 0;
	color: currentColor;
	text-align: left;
	@include mq('md') {
		padding: calc(var(--spacing--xl) + 5px);
	}
}

.AccordionFaqItem_content {
	h4, h5, h6 {
		padding-bottom: var(--spacing--base);
		font-size: var(--font-size--sm);
		font-weight: var(--font-weight--semibold);
		@include mq('md') {
			font-size: var(--font-size--lg);
		}
	}
	p {
		font-size: var(--font-size--xs);
		font-weight: var(--font-weight--normal);
		line-height: var(--line-height--2xl);
		@include mq('md') {
			font-size: var(--font-size--lg);
		}
	}
}

/* For Edge bug https://developer.microsoft.com/en-us/microsoft-edge/platform/issues/4806035/ */
.AccordionFaqItem_panel[hidden] {
	display: none;
}
