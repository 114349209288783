@import "ui/styles/2-tools/tools.media-query.scss";

.Main {
	position: relative;
	padding-top: 7rem;	
	@include mq("md") {
	}
}

.Main_article {
	position: relative;
	background-color: var(--color-light);
	overflow: hidden;
}

// theme-tillidsportal custome style
:global(.theme-tillidsportal) {
	.Main 
	{
	padding-top: 1rem;
	}
	.Main_article {
		@include mq("md") {
			// If padding is removed remember to substract 9rem from max-width on .Container___standard on:
			// Container.module.scss
			padding-left: 9rem;
			padding-top: 8rem;
		}
	}
}
:global(.theme-kongres) {
	.Main 
	{
	padding-top: 1rem;
	}
		
}
// theme-medlem custome style
:global(.theme-medlem) {
	.Main {
		padding-top: 0;
		@include mq("lg") {
			padding-left: 18rem;			
		}		
	}
}


// theme-def custome style
:global(.theme-def) {
	.Main {
		padding-top: 0;
	}
}