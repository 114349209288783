@import "ui/styles/2-tools/tools.media-query.scss";
@import "ui/styles/2-tools/tools.display-mixins.scss";
@import "ui/styles/2-tools/tools.typography-mixins-tillidsportal.scss";
@import "ui/styles/2-tools/tools.behavior.scss";
@import "ui/styles/2-tools/tools.space.scss";

.WelcomeCardProfile{
	color: var(--color-primary);	
	animation: fadeInDown 500ms ease-in-out;
	width: 100%;
	@include module-seperator;
}
@keyframes fadeInDown {
    0% {
        opacity: 0;
        transform: translateY(-10px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}
.WelcomeCard_heading {
	@include  t-h3;
	text-align: center;	
	margin-bottom: var(--spacing--xs);
	
	@include mq("lg") {	
		text-align: left;		
	}
}
.WelcomeCard_greeting{
	margin-right: .8rem;;
}

.WelcomeCard_top{
    display: flex;
	flex-direction: column;
    align-items: center;    
	margin-bottom: 4rem;
	@include mq("lg") {			
		flex-direction: row;
		justify-content: space-between;
	}
}

.WelcomeCardProfile_memberNumberTitle{
	font-size: var(--font-size--sm);
	font-weight: bold;
	margin-right: 1rem;
	@include mq("lg") {	
		font-size: var(--font-size--xl);
	}
}
.WelcomeCardProfile_memberNumberValue{
	font-size: var(--font-size--sm);	
	@include mq("lg") {	
		font-size: var(--font-size--xl);
	}
}

.WelcomeCardProfile_sectionWrapper{	
	min-height: 30rem;
	@include mq("lg") {	
		display: flex;
    	justify-content: space-between;
	}
}

.WelcomeCardProfile_section{
	display: flex;
    flex-flow: column;
	align-items: center;
	background-color: var(--color-tertiary-lighter);	
	padding: 6rem 4.5rem;
	width: 100%;
	border-bottom: var(--border--sm) solid var(--color-tertiary);	
	&:last-of-type{
		border-bottom: 0
	}
	@include mq("lg") {			
		border-bottom: 0;
		border-right: var(--border--sm) solid var(--color-tertiary);
		padding: 7rem 4.5rem;
		&:last-of-type{
			border-right: 0
		}
	}
}
.WelcomeCardProfile_linkWrapper{
	margin-top: auto;
}
.WelcomeCardProfile_link{	
	@include t-body;	
	display: block;	
	color: var(--color-primary);		
	text-decoration: none;
	position: relative;
	border-bottom: 1px solid var(--color-tertiary-darker);
	margin-top: 3rem;   
	 
	@include mq("lg") {	
		margin-top: 6rem;   
	}
	&:after{
		@include transition {
			transition: width var(--trans-time--standard) ease;
		}
		position: absolute;
		bottom: -1px;
		left: 0;
		right: 0;
		margin: auto;
		width: 0%;
		content: '.';
		color: transparent;
		background: var(--color-primary);
		height: 1px;
	}
	&:hover:after{
		width: 100%;		
	}
}
.WelcomeCardProfile_content{
	display: flex;
	flex-direction: column;
	height: 100%;
	align-items: center;
	margin-bottom: 3rem;	
	&___workPrimary{
		height: auto;
		margin-bottom: 2rem;		
	}
	&___workSecondary{
		p{
			font-size: var(--font-size--lg);
			font-weight: normal;
		}
	}
	&___subscriptionPrimary,
	&___subscriptionSecondary
	{
		height: auto;
    	margin-bottom: 4rem;
		p{
			font-size: var(--font-size--md);
			font-weight: bold;
		}
		span{
			font-size: var(--font-size--md);
		}
	}
}

.WelcomeCardProfile_contentTitle{
	margin-bottom: .5rem;
	font-size: var(--font-size--sm);
	font-weight: var(--font-weight--bold);
}
.WelcomeCardProfile_contentValue{
	display: inline-block;	
	font-size: var(--font-size--lg);
	margin-bottom: 2rem;
	&___details{
		margin-bottom: 0.5rem;
	}
}
.WelcomeCardProfile_contentValueDate{
	display: flex;
}
.WelcomeCardProfile_weekdays{
	display: inline-block;
    width: 25%;
}
.WelcomeCardProfile_sectionTitle{	
	@include t-body;
	text-transform: uppercase;
	font-weight: var(--font-weight--bold);
	margin-bottom: var(--spacing--md);
	@include mq("lg") {	
		margin-bottom: var(--spacing--xl);
	}
}

.WelcomeCardProfile_phoneWrapper{
	display: flex;
	align-items: center;
	margin-bottom: var(--spacing--md);
}
.WelcomeCardProfile_emailWrapper{
	display: flex;
	align-items: center;
	margin-bottom: var(--spacing--sm);
}
.WelcomeCardProfile_email,
.WelcomeCardProfile_phone
{
	@include t-body;
	display: inline-block;		
	position: relative;
	text-decoration: none;
	border-bottom: 1px solid var(--color-primary);
	align-items: center;	
	max-width: fit-content;
	cursor: pointer;
	&:after {
		@include transition {
			transition: width var(--trans-time--standard) ease;
		}
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		margin: auto;
		width: 0%;
		content: ".";
		color: transparent;
		background: var(--color-primary);
		height: 1px;
	}
	&:hover:after {
		width: 100%;
	}		
}
.WelcomeCardProfile_addressWrapper{
	display: flex;	
	margin-bottom: var(--spacing--xs);
}
.WelcomeCardProfile_addressDetail{
	margin-top: 5px;
}
.WelcomeCardProfile_iconEmail{
	width: 20px;
	height: 15px;
	margin-right: 10px;
	circle, path{
		fill: transparent;
	}
}
.WelcomeCardProfile_iconPhone{
	width: 20px;
	height: 20px;
	margin-right: 10px;
	circle, path{
		fill: transparent;
	}
}
.WelcomeCardProfile_iconAddress{
	width: 20px;
	height: 30px;
	margin-right: 10px;
	circle, path{
		fill: transparent;
	}
}
.WelcomeCardProfile_cityTitle{
	font-weight: bold;
	margin-right: 10px;
}

.WelcomeCardProfile_weekdaysItems{
	margin-bottom: .6rem;
}

