@import "ui/styles/2-tools/tools.behavior.scss";

.button {
	border: none;
	border-left: 2px solid transparent;
	padding: 0;
	padding-left: 13px;

	background: none;

	color: var(--color-primary);

	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;

	font-family: var(--font-family-primary);
	font-size: pxToRem(15);
	font-style: normal;
	font-weight: 400;
	line-height: pxToRem(22);
	letter-spacing: 0.02em;
	text-align: left;
	width: 350px;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;

	@include transition {
		transition: all var(--trans-time--standard) ease;
		transition-property: padding-left, border-left;
	}

	&:hover {
		border-left: 2px solid var(--color-secondary);
		padding-left: 16px;
	}

	&___links {
		position: absolute;
		left: -9999px;
		width: 1px;
		height: 1px;
		overflow: hidden;
	}
}

.button_selected {
	border-left: 2px solid var(--color-secondary);
	font-weight: 700;
	pointer-events: none;
}