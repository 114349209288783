@import "ui/styles/2-tools/tools.typography-mixins.scss";
@import "ui/styles/2-tools/tools.behavior.scss";
@import "ui/styles/2-tools/tools.space.scss";
@import "ui/styles/2-tools/tools.typography-mixins-tillidsportal.scss";
@import "ui/styles/2-tools/tools.mixins.scss";

.ListTimeline {
	color: var(--color-primary);
	@include full-width-bg(#eaeef2);
	@include module-seperator;
}
.ListTimeline_grid{
	@include mq("md-lg") {
		max-width: 75rem;
		margin: auto;
	}
}
.ListTimeline_heading {
	@include t-h1;
	margin-bottom: var(--spacing--md);
	@include mq("md-lg") {
		margin-bottom: var(--spacing--xl);
	}
}
.ListTimeline_text {
	@include t-body;
	font-size: 1.6rem;
	margin-bottom: var(--spacing--2xl);
	@include mq("md-lg") {
		font-size: 1.8rem;
	}
}
.ListTimeline_items {
	display: flex;
	flex-direction: column;
	gap: 6rem;

	@include mq("md-lg") {
		gap: 8rem;
	}
}

.ListTimeline_item{
	animation: fadeInDown 500ms ease-in-out;
}
@keyframes fadeInDown {
    0% {
        opacity: 0;
        transform: translateY(-10px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}
.ListTimeline_link{
	font-weight: var(--font-weight--semibold);
    cursor: pointer;
    display: inline-block;
    padding: var(--spacing--sm) var(--spacing--md);
    text-decoration: none;
    line-height: var(--line-height--lg);
    border-radius: var(--corner-size--base);
    border: var(--border--md) solid transparent;
    text-align: center;
    min-width: 10em;
    font-size: var(--font-size--sm);
    -webkit-transition: all var(--trans-time--standard);
    -moz-transition: all var(--trans-time--standard);
    transition: all var(--trans-time--standard);
    color: var(--button-text-primary);
    background: transparent;
    border: var(--border--md) solid var(--color-primary);
	&:hover{
		background: var(--button-bg-tertiary-hover);
	}
	
}
.ListTimeline_linkIconPlus {
	width: auto;
	height: auto;
	margin-left: 1rem;
	@include transition {
		transition: transform var(--trans-time--standard) ease;
	}
	.ListTimeline_link:hover & {
		transform: rotate(90deg); /* Equal to rotateZ(45deg) */
	}
}
.ListTimeline_linkIconMinus {
	width: auto;
	height: auto;
	margin-left: 1rem;
	@include transition {
		transition: transform var(--trans-time--standard) ease;
	}
	.ListTimeline_link:hover & {
		transform: rotate(180deg); /* Equal to rotateZ(45deg) */
	}
}
.ListTimeline_linkWrapper {
	text-align: center;
	margin-top: 7rem;
	font-weight: var(--font-weight--semibold);
}
