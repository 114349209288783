/* ------------------------------------ *\
		settings.spacing
\* ------------------------------------ */

:root {
	--spacing--base: 2rem;

	--spacing--2xs: calc(var(--spacing--base) / 4);
	--spacing--xs: calc(var(--spacing--base) / 2);
	--spacing--sm: calc(var(--spacing--base) * 0.75);
	--spacing--md: calc(var(--spacing--base) * 1.25);
	--spacing--lg: calc(var(--spacing--base) * 1.5);
	--spacing--xl: calc(var(--spacing--base) * 2);
	--spacing--2xl: calc(var(--spacing--base) * 3);
	--spacing--3xl: calc(var(--spacing--base) * 4);
	--spacing--4xl: calc(var(--spacing--base) * 5);
}
