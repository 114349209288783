/* ------------------------------------ *\
		elements.page
\* ------------------------------------ */

/**
 *  Elements – unclassed HTML elements,
 *  redefine browsers' default styling
*/

$defaultBrowserFontSizeInPx: 16;
$defaultFontSizeWantedInPx: 10;

html {
	font-family: var(--font-primary);
	font-size: $defaultFontSizeWantedInPx / $defaultBrowserFontSizeInPx + rem;
}

body {
	font-size: var(--font-size--md);
	line-height: var(--line-height--xl);
	hyphens: auto;
	-webkit-hyphens: auto;
}
