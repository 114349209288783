@import "ui/styles/2-tools/tools.typography-mixins.scss";

.RichText {
	h1 {
		@include heading--xl;
	}

	h2 {
		@include heading--lg;
	}

	h3 {
		@include heading--md;
	}

	h4 {
		@include heading--sm;
	}

	p {
		@include paragraph;
		margin-bottom: var(--spacing--sm);
	}
	ul {
		padding-left: var(--spacing--md);
	}

	footer & {
		// no margin bottom on footer RichText
		margin-bottom: 0;
	}
}
