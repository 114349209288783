@import "ui/styles/2-tools/tools.media-query.scss";
@import "ui/styles/2-tools/tools.typography-mixins-tillidsportal.scss";
@import "ui/styles/2-tools/tools.action.scss";



.RichText {
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		margin-top: var(--spacing--xs);
		margin-bottom: 1rem;
	}
	h1 {
		@include t-h1;
	}
	h2 {
		@include t-h2;
	}
	h3 {
		@include t-h3;
	}
	h4 {
		@include t-h4;
	}
	.rte-cta{
		display: inline-block;
		a{
			@include button; 
			color: var(--button-text-primary);
			background: var(--button-bg-primary);
			border: none;

			&:hover,
			&:focus {
				box-shadow: inset 0 -4.5em 0 0 var(--button-bg-primary-hover);
			}
		}
	}
	/**
	* In this molecule we style html elements directly
	* since they come from the RTE of a CMS or another editor
	*/
	color: var(--color-primary);

	p {
		font-size: var(--font-size--md);
		margin-bottom: var(--spacing--md);

		&:last-of-type {
			margin-bottom: 0;
		}
	}

	blockquote {
		margin-top: var(--spacing--xl);
		margin-bottom: var(--spacing--xl);
		margin-left: auto;
		margin-right: auto;
		font-size: var(--font-size--xl);
		font-weight: var(--font-weight--bold);
		line-height: var(--line-height--3xl);
		text-align: center;
		width: 75%;

		&:before,
		&:after {
			content: "";
			display: block;
			width: 3em;
			height: 0;
			margin: 0.5em auto 0.5em auto;
			border-bottom: 1px solid currentColor;
		}
	}

	table {
		margin-bottom: var(--spacing--sm);
	}

	th {
		text-align: left;
		padding-top: var(--spacing--2xs);
	}
	th,
	td {
		vertical-align: top;
	}
	td {
		font-size: var(--font-size--sm);
	}
	ul{
		margin: var(--spacing--base) 0;
		padding: var(--spacing--xs) 1.7rem;
		line-height: var(--line-height--2xl);
	}
	li {
		@include t-body;
	}
	ol {
		list-style: none;
		counter-reset: my-awesome-counter;	
		margin: var(--spacing--base) 0;
		padding: 0;
		line-height: var(--line-height--2xl);
		@include mq("lg") {
			padding: var(--spacing--xs) 1.7rem;
		}	
		li {
			counter-increment: my-awesome-counter;
			display: flex;
			width: 100%;	
			margin-bottom: 4rem;			
		  }
		  li::before {
			content: counter(my-awesome-counter) ".";
			font-weight: bold;
			font-size: 3.2rem;
			margin-right: 1rem;	
			line-height: 1;
			@include mq("lg") {
				font-size: 5.4rem;
				margin-right: 4rem;	
			}	
		  }
	  }
	  
	img {
		@media only screen and (max-width: 768px) {
			width: 100%;
			height: auto;
		}
	}
}
.RichText___negative {
	color: var(--color-white);
	p {
		margin: 0;
	}
}


