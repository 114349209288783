/* ------------------------------------ *\
		settings.colors
\* ------------------------------------ */

:root {
	// Base colors
	--color-black: #000000;
	--color-white: #ffffff;

	// Variations of gray named with the alpha percentage
	--color-gradient--5: #{darken(#f2f2f2, 5%)};
	--color-gradient--8: #{darken(#f2f2f2, 8%)};
	--color-gradient--10: #{darken(#f2f2f2, 10%)};
	--color-gradient--20: #{darken(#f2f2f2, 20%)};
	--color-gradient--30: #{darken(#f2f2f2, 30%)};
	--color-gradient--40: #{darken(#f2f2f2, 40%)};
	--color-gradient--50: #{darken(#f2f2f2, 50%)};
	--color-gradient--60: #{darken(#f2f2f2, 60%)};
	--color-gradient--70: #{darken(#f2f2f2, 70%)};
	--color-gradient--80: #{darken(#f2f2f2, 80%)};
	--color-gradient--90: #{darken(#f2f2f2, 90%)};
	--color-gradient--100: #{darken(#f2f2f2, 100%)};

	// Theme colors
	--color-primary: #000064;
	--color-secondary: #f87c56;
	--color-tertiary: #dadada;

	// Theme Nuances / Support
	--color-primary-lighter: #151533;
	--color-secondary-darker: #d36958;
	--color-tertiary-darker: #a5a5a5;
	--color-tertiary-lighter: #f6f6f7;
	--color-tertiary-blue: #eaeef2;

	// Utilities colors
	--color-error: #c80000;
	--color-dark: #2c2c2c;
	--color-light: #ffffff;
	--color-tag-green: #0b8265;
	--color-tag-blue: #061ce4;
	--color-tag-red: #ff0303;
	--color-tag-orange: #ff0303;

	// Border colors
	--color-border--gray: var(--color-tertiary-dark);
	--color-border--red: var(--color-secondary-dark);
	--color-border--light: var(--color-light);

	// Transparent	
	--transparent-color--secondary: rgba(248,124,86,0.9);
	--transparent-color--primary: rgba(0, 0, 100, 0.9);

	// Shadows
	--shadow-color--milder: rgba(#2c2c2c, 0.1);
	--shadow-color--mild: rgba(#2c2c2c, 0.2);
	--shadow-color--base: rgba(#2c2c2c, 0.5);

	// Module Background
	--module-bg-primary: var(--color-primary);
	--module-bg-secondary: var(--color-light);
	--module-bg-tertiary: var(--color-tertiary);
	--module-bg-footer: var(--color-tertiary-lighter);

	// Border color
	--border-color: var(--color-tertiary-darker);

	// Header colors
	--color-header-primary: var(--color-white);
	--color-header-secondary: var(--color-primary);

	// Text colors
	--text-primary: var(--color-white);
	--text-secondary: var(--color-primary);
	--text-tertiary: var(--color-primary-lighter);
	--text-invert-color: var(--color-tertiary-blue);

	// Accordian colors
	--accordian-bg: var(--color-light);
	--accordian-text: var(--color-primary);
	--accordian-hover: var(--color-tertiary-blue);

	// Email colors
	--Email-primary: var(--color-secondary);

	// Button Colors
	--button-bg-primary: var(--color-secondary);
	--button-bg-primary-hover: var(--color-secondary-darker);
	--button-bg-secondary: var(--color-primary);
	--button-bg-secondary-hover: var(--color-tertiary-blue);
	--button-bg-tertiary: var(--color-tertiary-lighter);
	--button-bg-tertiary-hover: var(--color-tertiary);
	--button-text-primary: var(--color-primary);
	--button-text-secondary: var(--color-light);

	// Link colors
	--link-primary: var(--color-secondary);

	// SoMe Colors
	--some-primary: var(--color-secondary);
	--some-secondary: var(--color-primary);
	--some-text-color: var(--color-light);

	// Navigation
	--navigation-chevron: var(--color-secondary);
}
